import { gql } from "@apollo/client";
import { carePathwayFields } from "../CarePath/types";
import { DataFields } from "../DataPoint/types";
import { memberFields } from "./types";

export const CREATE_MEMBER = gql`
  mutation CreateMember($input: MemberInput!) {
    createMember(input: $input) {
      success
      message
      data {
        member {
          ${memberFields}
        }
        carePathways {
          ${carePathwayFields}
        }
        dataPoints {
          ${DataFields}
        }
      }
    }
  }
`;

export const UPDATE_MEMBER = gql`
  mutation UpdateMember($input: UpdateMemberInput!) {
    updateMember(input: $input) {
      success
      message
      data {
        member {
          ${memberFields}
        }
        carePathways {
          ${carePathwayFields}
        }
        dataPoints {
          ${DataFields}
        }
      }
    }
  }
`;

export const UPDATE_MEMBER_OVERRIDDEN_PROCEDURE_CODE_LIMITS = gql`
  mutation UpdateMemberOverriddenProcedureCodeLimits(
    $memberId: ID!
    $overriddenProcedureCodeLimits: [String!]!
    $organizationId: ID!
  ) {
    updateMemberOverriddenProcedureCodeLimits(
      memberId: $memberId
      overriddenProcedureCodeLimits: $overriddenProcedureCodeLimits
      organizationId: $organizationId
    ) {
      success
      message
      data {
        ${memberFields}
      }
    }
  }
`;

export const CLOSE_MEMBER = gql`
  mutation CloseMember($input: CloseMemberInput!) {
    closeMember(input: $input) {
      success
      message
      data {
        ${memberFields}
      }
    }
  }
`;

export const REACTIVATE_MEMBER = gql`
  mutation ReactivateMember($memberId: ID!, $organizationId: ID!) {
    reactivateMember(memberId: $memberId, organizationId: $organizationId) {
      success
      message
      data {
        ${memberFields}
      }
    }
  }
`;

export const UPDATE_COVERAGE_DETAILS = gql`
  mutation UpdateCoverageDetails($organizationId: ID!, $memberId: ID!) {
    updateCoverageDetails(organizationId: $organizationId, memberId: $memberId) {
      success
      message
      data {
        ${memberFields}
      }
    }
  }
`;
