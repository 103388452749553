/* eslint-disable */
export type Maybe<T> = T;
export type InputMaybe<T> = T;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  Upload: { input: any; output: any; }
};

export type AclObject = {
  __typename?: 'ACLObject';
  accessType: AccessType;
  name: Scalars['String']['output'];
  objectId: Scalars['String']['output'];
  subOrgs: Array<AclSubOrgObject>;
};

export type AclSubOrgObject = {
  __typename?: 'ACLSubOrgObject';
  accessType?: Maybe<AccessType>;
  name: Scalars['String']['output'];
  orgId: Scalars['String']['output'];
};

export type ApiKey = {
  __typename?: 'APIKey';
  _id?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['String']['output']>;
  key?: Maybe<Scalars['String']['output']>;
  label?: Maybe<Scalars['String']['output']>;
  permissions?: Maybe<Array<ApiKeyPermission>>;
  updatedAt?: Maybe<Scalars['String']['output']>;
};

export type ApiKeyInput = {
  _id?: InputMaybe<Scalars['String']['input']>;
  label: Scalars['String']['input'];
  organizationId: Scalars['String']['input'];
  permissions: Array<ApiKeyPermission>;
};

export const ApiKeyPermission = {
  ActivitiesRead: 'ActivitiesRead',
  DataPointsRead: 'DataPointsRead',
  MembersRead: 'MembersRead'
} as const;

export type ApiKeyPermission = typeof ApiKeyPermission[keyof typeof ApiKeyPermission];
export const AccessType = {
  Read: 'Read',
  Write: 'Write'
} as const;

export type AccessType = typeof AccessType[keyof typeof AccessType];
export type Action = {
  __typename?: 'Action';
  action?: Maybe<Array<ActionData>>;
  response: Scalars['String']['output'];
};

export type ActionActivities = {
  __typename?: 'ActionActivities';
  actionId: Scalars['ID']['output'];
  activities: Array<ActionActivity>;
};

export type ActionActivity = {
  __typename?: 'ActionActivity';
  activityId: Scalars['ID']['output'];
  date: Scalars['String']['output'];
};

export const ActionAssignedUserType = {
  UserAssignedToMember: 'UserAssignedToMember',
  UserGroup: 'UserGroup'
} as const;

export type ActionAssignedUserType = typeof ActionAssignedUserType[keyof typeof ActionAssignedUserType];
export type ActionData = {
  __typename?: 'ActionData';
  addMemberToGroup?: Maybe<AddOrRemoveFromGroupData>;
  createConsent?: Maybe<CreateConsentData>;
  createGoal?: Maybe<CreateGoalData>;
  createReferral?: Maybe<CreateReferralData>;
  removeMemberFromGroup?: Maybe<AddOrRemoveFromGroupData>;
  scheduleActivity?: Maybe<ScheduleActivityData>;
  startCarePathway?: Maybe<StartCarePathwayData>;
  updateCarePathwayStatus?: Maybe<UpdateCarePathwayStatusData>;
  updateGoal?: Maybe<UpdateGoalData>;
  updateMember?: Maybe<UpdateMemberData>;
};

export type ActionDataInput =
  { addMemberToGroup: AddOrRemoveFromGroupInput; createConsent?: never; createGoal?: never; createReferral?: never; removeMemberFromGroup?: never; scheduleActivity?: never; startCarePathway?: never; updateCarePathwayStatus?: never; updateGoal?: never; updateMember?: never; }
  |  { addMemberToGroup?: never; createConsent: CreateConsentInput; createGoal?: never; createReferral?: never; removeMemberFromGroup?: never; scheduleActivity?: never; startCarePathway?: never; updateCarePathwayStatus?: never; updateGoal?: never; updateMember?: never; }
  |  { addMemberToGroup?: never; createConsent?: never; createGoal: CreateGoalActionInput; createReferral?: never; removeMemberFromGroup?: never; scheduleActivity?: never; startCarePathway?: never; updateCarePathwayStatus?: never; updateGoal?: never; updateMember?: never; }
  |  { addMemberToGroup?: never; createConsent?: never; createGoal?: never; createReferral: CreateReferralActionInput; removeMemberFromGroup?: never; scheduleActivity?: never; startCarePathway?: never; updateCarePathwayStatus?: never; updateGoal?: never; updateMember?: never; }
  |  { addMemberToGroup?: never; createConsent?: never; createGoal?: never; createReferral?: never; removeMemberFromGroup: AddOrRemoveFromGroupInput; scheduleActivity?: never; startCarePathway?: never; updateCarePathwayStatus?: never; updateGoal?: never; updateMember?: never; }
  |  { addMemberToGroup?: never; createConsent?: never; createGoal?: never; createReferral?: never; removeMemberFromGroup?: never; scheduleActivity: ScheduleActivityActionInput; startCarePathway?: never; updateCarePathwayStatus?: never; updateGoal?: never; updateMember?: never; }
  |  { addMemberToGroup?: never; createConsent?: never; createGoal?: never; createReferral?: never; removeMemberFromGroup?: never; scheduleActivity?: never; startCarePathway: StartCarePathwayActionInput; updateCarePathwayStatus?: never; updateGoal?: never; updateMember?: never; }
  |  { addMemberToGroup?: never; createConsent?: never; createGoal?: never; createReferral?: never; removeMemberFromGroup?: never; scheduleActivity?: never; startCarePathway?: never; updateCarePathwayStatus: UpdateCarePathwayStatusActionInput; updateGoal?: never; updateMember?: never; }
  |  { addMemberToGroup?: never; createConsent?: never; createGoal?: never; createReferral?: never; removeMemberFromGroup?: never; scheduleActivity?: never; startCarePathway?: never; updateCarePathwayStatus?: never; updateGoal: UpdateGoalActionInput; updateMember?: never; }
  |  { addMemberToGroup?: never; createConsent?: never; createGoal?: never; createReferral?: never; removeMemberFromGroup?: never; scheduleActivity?: never; startCarePathway?: never; updateCarePathwayStatus?: never; updateGoal?: never; updateMember: UpdateMemberActionInput; };

export type ActionInput = {
  action?: InputMaybe<Array<ActionDataInput>>;
  response: Scalars['String']['input'];
};

export type ActionItem = {
  __typename?: 'ActionItem';
  completed: Scalars['Boolean']['output'];
  createdAt: Scalars['String']['output'];
  description: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  orderIndex: Scalars['Int']['output'];
};

export type ActionItemInput = {
  completed: Scalars['Boolean']['input'];
  description: Scalars['String']['input'];
  id: Scalars['ID']['input'];
  orderIndex?: InputMaybe<Scalars['Int']['input']>;
};

export const ActionType = {
  AddMemberToGroup: 'AddMemberToGroup',
  CreateConsent: 'CreateConsent',
  CreateGoalData: 'CreateGoalData',
  CreateReferral: 'CreateReferral',
  RemoveMemberFromGroup: 'RemoveMemberFromGroup',
  ScheduleActivityData: 'ScheduleActivityData',
  StartCarePathway: 'StartCarePathway',
  UpdateCarePathwayStatus: 'UpdateCarePathwayStatus',
  UpdateGoalData: 'UpdateGoalData',
  UpdateMemberData: 'UpdateMemberData'
} as const;

export type ActionType = typeof ActionType[keyof typeof ActionType];
export type ActiveMembersByMonth = {
  __typename?: 'ActiveMembersByMonth';
  activeMemberCount: Scalars['Int']['output'];
  month: Scalars['String']['output'];
  organizationId: Scalars['String']['output'];
};

export type ActiveMembersThisMonth = {
  __typename?: 'ActiveMembersThisMonth';
  activeMemberCount: Scalars['Int']['output'];
};

export type ActivitiesFilterInput = {
  status?: InputMaybe<Array<ActivityStatus>>;
};

export type ActivitiesStats = {
  __typename?: 'ActivitiesStats';
  data: Array<ResourceGranularData>;
  groupByTimeUnit: GroupTimeUnit;
};

export type ActivitiesStatsResponse = {
  __typename?: 'ActivitiesStatsResponse';
  message?: Maybe<Scalars['String']['output']>;
  stats?: Maybe<ActivitiesStats>;
  success: Scalars['Boolean']['output'];
};

export type Activity = {
  __typename?: 'Activity';
  _id: Scalars['ID']['output'];
  activityTemplateId?: Maybe<Scalars['ID']['output']>;
  appointmentDetails?: Maybe<ActivityAppointmentDetails>;
  billable: Scalars['Boolean']['output'];
  billingDetails: Array<ActivityBillingDetails>;
  carePathwayId?: Maybe<Scalars['ID']['output']>;
  createdAt: Scalars['String']['output'];
  date: Scalars['String']['output'];
  description?: Maybe<Scalars['String']['output']>;
  duration: Scalars['Int']['output'];
  hasMissingMembers: Scalars['Boolean']['output'];
  lastPing?: Maybe<Scalars['String']['output']>;
  lastStartedAt?: Maybe<Scalars['String']['output']>;
  members: Array<Maybe<ActivityMemberMeta>>;
  organizationId: Scalars['String']['output'];
  scheduledEndAt?: Maybe<Scalars['String']['output']>;
  status: ActivityStatus;
  sync: Scalars['Boolean']['output'];
  syncAppointment: Scalars['Boolean']['output'];
  tasks: Array<ActivityTask>;
  title: Scalars['String']['output'];
  updatedAt: Scalars['String']['output'];
  userId?: Maybe<Scalars['ID']['output']>;
  userName?: Maybe<Scalars['String']['output']>;
};

export type ActivityAppointmentDetails = {
  __typename?: 'ActivityAppointmentDetails';
  locationDepartment: Scalars['String']['output'];
  locationFacility?: Maybe<Scalars['String']['output']>;
  locationRoom?: Maybe<Scalars['String']['output']>;
  locationType?: Maybe<Scalars['String']['output']>;
};

export type ActivityAppointmentDetailsInput = {
  locationDepartment: Scalars['String']['input'];
  locationFacility?: InputMaybe<Scalars['String']['input']>;
  locationRoom?: InputMaybe<Scalars['String']['input']>;
  locationType?: InputMaybe<Scalars['String']['input']>;
};

export type ActivityBillingDetails = {
  __typename?: 'ActivityBillingDetails';
  cost: ActivityCostConfigurationMeta;
  diagnosisCodes: Array<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  memberId: Scalars['ID']['output'];
  placeOfService: Scalars['Int']['output'];
};

export type ActivityCostConfigurationMeta = {
  __typename?: 'ActivityCostConfigurationMeta';
  _id: Scalars['ID']['output'];
  cost: Scalars['Float']['output'];
  description?: Maybe<Scalars['String']['output']>;
  insurance: InsuranceBillingConfigurationSummary;
  modifiers: Array<Scalars['String']['output']>;
  procedure: Scalars['String']['output'];
  recommendingProviderRequired: Scalars['Boolean']['output'];
  unitPeriod: Scalars['Int']['output'];
  userProviderRequired: Scalars['Boolean']['output'];
};

export type ActivityInput = {
  activityTemplateId?: InputMaybe<Scalars['ID']['input']>;
  appointmentDetails?: InputMaybe<ActivityAppointmentDetailsInput>;
  billable?: InputMaybe<Scalars['Boolean']['input']>;
  carePathwayId?: InputMaybe<Scalars['String']['input']>;
  carePathwayTemplateActionId?: InputMaybe<Scalars['String']['input']>;
  carePathwayTemplateId?: InputMaybe<Scalars['String']['input']>;
  carePathwayTemplateStepId?: InputMaybe<Scalars['String']['input']>;
  date: Scalars['String']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  episodeOfCareId?: InputMaybe<Scalars['String']['input']>;
  lastStartedAt?: InputMaybe<Scalars['String']['input']>;
  memberIds: Array<InputMaybe<Scalars['ID']['input']>>;
  organizationId: Scalars['ID']['input'];
  scheduledEndAt: Scalars['String']['input'];
  status?: InputMaybe<ActivityStatus>;
  sync?: InputMaybe<Scalars['Boolean']['input']>;
  syncAppointment?: InputMaybe<Scalars['Boolean']['input']>;
  tasks?: InputMaybe<Array<ActivityTaskInput>>;
  title?: InputMaybe<Scalars['String']['input']>;
  userId?: InputMaybe<Scalars['ID']['input']>;
};

export type ActivityMemberInput = {
  activityId: Scalars['ID']['input'];
  memberId: Scalars['ID']['input'];
  organizationId: Scalars['ID']['input'];
};

export type ActivityMemberMeta = {
  __typename?: 'ActivityMemberMeta';
  defaultDiagnosisCodes: Array<DiagnosisCode>;
  memberDisplayName: Scalars['String']['output'];
  memberId: Scalars['ID']['output'];
  primaryInsuranceCompanyId?: Maybe<Scalars['String']['output']>;
  secondaryInsuranceCompanyId?: Maybe<Scalars['String']['output']>;
};

export type ActivityOnGoal = {
  __typename?: 'ActivityOnGoal';
  activity?: Maybe<Activity>;
  activityId: Scalars['ID']['output'];
  goalId: Scalars['ID']['output'];
};

export type ActivityPingInput = {
  activityId: Scalars['ID']['input'];
  organizationId: Scalars['ID']['input'];
};

export const ActivityStatus = {
  Complete: 'Complete',
  Failed: 'Failed',
  InProgress: 'InProgress',
  Scheduled: 'Scheduled'
} as const;

export type ActivityStatus = typeof ActivityStatus[keyof typeof ActivityStatus];
export type ActivityTask = {
  __typename?: 'ActivityTask';
  description?: Maybe<Scalars['String']['output']>;
  event?: Maybe<Event>;
  flowId?: Maybe<Scalars['String']['output']>;
  flowTemplateTitle?: Maybe<Scalars['String']['output']>;
  formId?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  status: ActivityStatus;
  statusMessage?: Maybe<Scalars['String']['output']>;
  type: ActivityType;
};

export type ActivityTaskInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  event?: InputMaybe<EventTaskInput>;
  formId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['Int']['input'];
  type: ActivityType;
};

export type ActivityTaskTemplate = {
  __typename?: 'ActivityTaskTemplate';
  description?: Maybe<Scalars['String']['output']>;
  formId?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  type: ActivityType;
};

export type ActivityTaskTemplateInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  formId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['Int']['input'];
  type: ActivityType;
};

export type ActivityTemplate = {
  __typename?: 'ActivityTemplate';
  _id: Scalars['ID']['output'];
  appointmentDetails?: Maybe<ActivityTemplateAppointmentDetails>;
  billable: Scalars['Boolean']['output'];
  createdAt: Scalars['String']['output'];
  defaultBillingDetails?: Maybe<DefaultBillingDetails>;
  description?: Maybe<Scalars['String']['output']>;
  lockingReferences: Array<LockingReference>;
  organization: Scalars['ID']['output'];
  syncAppointment: Scalars['Boolean']['output'];
  tasks: Array<ActivityTaskTemplate>;
  title: Scalars['String']['output'];
  updatedAt: Scalars['String']['output'];
};

export type ActivityTemplateAppointmentDetails = {
  __typename?: 'ActivityTemplateAppointmentDetails';
  locationDepartment: Scalars['String']['output'];
  locationFacility?: Maybe<Scalars['String']['output']>;
  locationRoom?: Maybe<Scalars['String']['output']>;
  locationType?: Maybe<Scalars['String']['output']>;
};

export type ActivityTemplateAppointmentDetailsInput = {
  locationDepartment: Scalars['String']['input'];
  locationFacility?: InputMaybe<Scalars['String']['input']>;
  locationRoom?: InputMaybe<Scalars['String']['input']>;
  locationType?: InputMaybe<Scalars['String']['input']>;
};

export type ActivityTemplateIdAndOrgInput = {
  organizationId: Scalars['String']['input'];
  templateId: Scalars['String']['input'];
};

export type ActivityTemplateInput = {
  appointmentDetails?: InputMaybe<ActivityTemplateAppointmentDetailsInput>;
  billable: Scalars['Boolean']['input'];
  defaultBillingDetails?: InputMaybe<DefaultBillingDetailsInput>;
  description?: InputMaybe<Scalars['String']['input']>;
  organization: Scalars['ID']['input'];
  syncAppointment?: InputMaybe<Scalars['Boolean']['input']>;
  tasks: Array<ActivityTaskTemplateInput>;
  title: Scalars['String']['input'];
};

export type ActivityTemplateWithAccess = {
  __typename?: 'ActivityTemplateWithAccess';
  accessType: AccessType;
  activityTemplate: ActivityTemplate;
};

export const ActivityType = {
  Assessment: 'Assessment',
  Email: 'Email',
  Event: 'Event',
  Journey: 'Journey',
  Sms: 'SMS',
  Script: 'Script',
  SendEhrNote: 'SendEhrNote',
  SyncEhrMember: 'SyncEhrMember'
} as const;

export type ActivityType = typeof ActivityType[keyof typeof ActivityType];
export type AddOrRemoveFromGroupData = {
  __typename?: 'AddOrRemoveFromGroupData';
  groupId: Scalars['String']['output'];
  id?: Maybe<Scalars['ID']['output']>;
};

export type AddOrRemoveFromGroupInput = {
  groupId: Scalars['String']['input'];
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type AddParticipantToConferenceCallInput = {
  conferenceFriendlyName: Scalars['String']['input'];
  externalPhoneNumber?: InputMaybe<Scalars['String']['input']>;
  internalUserId?: InputMaybe<Scalars['String']['input']>;
  organizationId: Scalars['ID']['input'];
};

export type AdditionalFields = {
  __typename?: 'AdditionalFields';
  key: Scalars['String']['output'];
  value?: Maybe<Scalars['String']['output']>;
};

export type AdditionalFieldsInput = {
  key: Scalars['String']['input'];
  value?: InputMaybe<Scalars['String']['input']>;
};

export type AllowedCustomMetricsResponse = {
  __typename?: 'AllowedCustomMetricsResponse';
  data?: Maybe<Array<Scalars['String']['output']>>;
  message?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

export const AnalyticsActivities = {
  ActivitiesBreakdown: 'ActivitiesBreakdown',
  ActivitiesPerPeriod: 'ActivitiesPerPeriod'
} as const;

export type AnalyticsActivities = typeof AnalyticsActivities[keyof typeof AnalyticsActivities];
export const AnalyticsCarePathways = {
  CarePathwaysPerPeriod: 'CarePathwaysPerPeriod',
  CarePathwaysStatuses: 'CarePathwaysStatuses',
  CarePathwaysTotal: 'CarePathwaysTotal'
} as const;

export type AnalyticsCarePathways = typeof AnalyticsCarePathways[keyof typeof AnalyticsCarePathways];
export const AnalyticsChartTypes = {
  Area: 'AREA',
  Bar: 'BAR',
  Line: 'LINE',
  Pie: 'PIE'
} as const;

export type AnalyticsChartTypes = typeof AnalyticsChartTypes[keyof typeof AnalyticsChartTypes];
export const AnalyticsConfigCallCenterMetrics = {
  AverageCallDuration: 'AverageCallDuration',
  AverageCallsPerHour: 'AverageCallsPerHour',
  CallsCompleted: 'CallsCompleted',
  CallsMade: 'CallsMade',
  ConnectionRate: 'ConnectionRate',
  FailedTextsSent: 'FailedTextsSent',
  IncomingCalls: 'IncomingCalls',
  MissedCalls: 'MissedCalls',
  ReadTexts: 'ReadTexts',
  SuccessfulTextsSent: 'SuccessfulTextsSent',
  TotalIncomingTexts: 'TotalIncomingTexts',
  TotalInteractions: 'TotalInteractions',
  TotalTextsSent: 'TotalTextsSent',
  VoicemailsLeft: 'VoicemailsLeft'
} as const;

export type AnalyticsConfigCallCenterMetrics = typeof AnalyticsConfigCallCenterMetrics[keyof typeof AnalyticsConfigCallCenterMetrics];
export const AnalyticsConfigSummaryMetrics = {
  ActivationRate: 'ActivationRate',
  ActiveMembers: 'ActiveMembers',
  ActiveUsers: 'ActiveUsers',
  AvgAgeOfMembers: 'AvgAgeOfMembers',
  AvgMembersPerUser: 'AvgMembersPerUser',
  EngagementRate: 'EngagementRate',
  InactiveMembers: 'InactiveMembers',
  InactiveUsers: 'InactiveUsers',
  LivesImpacted: 'LivesImpacted',
  NewMembers: 'NewMembers',
  TotalMembers: 'TotalMembers',
  TotalUsers: 'TotalUsers'
} as const;

export type AnalyticsConfigSummaryMetrics = typeof AnalyticsConfigSummaryMetrics[keyof typeof AnalyticsConfigSummaryMetrics];
export type AnalyticsConfiguration = {
  __typename?: 'AnalyticsConfiguration';
  analyticsConfigActivities: Array<AnalyticsActivities>;
  analyticsConfigCallCenterMetrics: Array<AnalyticsConfigCallCenterMetrics>;
  analyticsConfigCarePathways: Array<AnalyticsCarePathways>;
  analyticsConfigDemographics: Array<AnalyticsDemographics>;
  analyticsConfigExternalResources: Array<AnalyticsExternalResources>;
  analyticsConfigGoals: Array<AnalyticsGoals>;
  analyticsConfigSummaryMetrics: Array<AnalyticsConfigSummaryMetrics>;
  customMetrics: Array<AnalyticsCustomMetricConfiguration>;
};

export type AnalyticsConfigurationInput = {
  analyticsConfigActivities: Array<AnalyticsActivities>;
  analyticsConfigCallCenterMetrics: Array<AnalyticsConfigCallCenterMetrics>;
  analyticsConfigCarePathways: Array<AnalyticsCarePathways>;
  analyticsConfigDemographics: Array<AnalyticsDemographics>;
  analyticsConfigExternalResources: Array<AnalyticsExternalResources>;
  analyticsConfigGoals: Array<AnalyticsGoals>;
  analyticsConfigSummaryMetrics: Array<AnalyticsConfigSummaryMetrics>;
  customMetrics: Array<AnalyticsCustomMetricConfigurationInput>;
};

export type AnalyticsCustomMetricConfiguration = {
  __typename?: 'AnalyticsCustomMetricConfiguration';
  chart: AnalyticsChartTypes;
  dataId: Scalars['String']['output'];
  groupTimeUnit?: Maybe<GroupTimeUnit>;
  title: Scalars['String']['output'];
  valueType?: Maybe<MetricValueType>;
};

export type AnalyticsCustomMetricConfigurationInput = {
  chart: AnalyticsChartTypes;
  dataId: Scalars['String']['input'];
  groupTimeUnit?: InputMaybe<GroupTimeUnit>;
  title: Scalars['String']['input'];
  valueType?: InputMaybe<MetricValueType>;
};

export const AnalyticsDemographics = {
  AgeGroups: 'AgeGroups',
  Ethinicity: 'Ethinicity',
  Gender: 'Gender',
  Medicaid: 'Medicaid',
  Medicare: 'Medicare',
  Pronouns: 'Pronouns',
  Race: 'Race',
  Sex: 'Sex',
  SexualOrientation: 'SexualOrientation',
  SpokenLanguages: 'SpokenLanguages'
} as const;

export type AnalyticsDemographics = typeof AnalyticsDemographics[keyof typeof AnalyticsDemographics];
export const AnalyticsExternalResources = {
  ExternalResourcesPerPeriod: 'ExternalResourcesPerPeriod',
  ExternalResourcesStatuses: 'ExternalResourcesStatuses',
  ExternalResourcesTotal: 'ExternalResourcesTotal'
} as const;

export type AnalyticsExternalResources = typeof AnalyticsExternalResources[keyof typeof AnalyticsExternalResources];
export const AnalyticsFilter = {
  Age: 'Age',
  City: 'City',
  Country: 'Country',
  Ethnicity: 'Ethnicity',
  Gender: 'Gender',
  InsuranceProvider: 'InsuranceProvider',
  InsuranceType: 'InsuranceType',
  Language: 'Language',
  Race: 'Race',
  SexualOrientation: 'SexualOrientation',
  State: 'State',
  ZipCode: 'ZipCode'
} as const;

export type AnalyticsFilter = typeof AnalyticsFilter[keyof typeof AnalyticsFilter];
export type AnalyticsFilterInput = {
  filter?: InputMaybe<AnalyticsFilter>;
  operator?: InputMaybe<AnalyticsFilterOperator>;
  values: Array<Scalars['String']['input']>;
};

export const AnalyticsFilterOperator = {
  DoNotInclude: 'DoNotInclude',
  Equal: 'Equal',
  GreaterThan: 'GreaterThan',
  GreaterThanOrEqual: 'GreaterThanOrEqual',
  Include: 'Include',
  LessThan: 'LessThan',
  LessThanOrEqual: 'LessThanOrEqual',
  NotEqual: 'NotEqual'
} as const;

export type AnalyticsFilterOperator = typeof AnalyticsFilterOperator[keyof typeof AnalyticsFilterOperator];
export type AnalyticsFiltersMetadata = {
  __typename?: 'AnalyticsFiltersMetadata';
  filter: AnalyticsFilter;
  options: Array<Maybe<Scalars['String']['output']>>;
};

export type AnalyticsFiltersMetadataResponse = {
  __typename?: 'AnalyticsFiltersMetadataResponse';
  data?: Maybe<Array<AnalyticsFiltersMetadata>>;
  message?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

export const AnalyticsGoals = {
  GoalsBreakdown: 'GoalsBreakdown',
  GoalsPerPeriod: 'GoalsPerPeriod'
} as const;

export type AnalyticsGoals = typeof AnalyticsGoals[keyof typeof AnalyticsGoals];
export type AnalyticsInput = {
  additionalFilters: Array<AnalyticsFilterInput>;
  dataPointId?: InputMaybe<Scalars['String']['input']>;
  forceGroupTimeUnit?: InputMaybe<GroupTimeUnit>;
  fromDate?: InputMaybe<Scalars['String']['input']>;
  groupIds: Array<Scalars['String']['input']>;
  memberId?: InputMaybe<Scalars['String']['input']>;
  organizationId: Scalars['ID']['input'];
  organizationIds: Array<Scalars['String']['input']>;
  toDate?: InputMaybe<Scalars['String']['input']>;
};

export type Answer = {
  __typename?: 'Answer';
  answer: Scalars['String']['output'];
  flowAnswerIndex: Scalars['Int']['output'];
  questionId: Scalars['ID']['output'];
  stepId: Scalars['String']['output'];
  submittedAt: Scalars['String']['output'];
};

export type AnswerInput = {
  answer: Scalars['String']['input'];
  flowAnswerIndex: Scalars['Int']['input'];
  questionId: Scalars['ID']['input'];
  stepId: Scalars['String']['input'];
  submittedAt: Scalars['String']['input'];
};

export const AnswerType = {
  Boolean: 'BOOLEAN',
  Date: 'DATE',
  Multi: 'MULTI',
  MultiChoice: 'MULTI_CHOICE',
  Number: 'NUMBER',
  ReadOnlyText: 'READ_ONLY_TEXT',
  Text: 'TEXT'
} as const;

export type AnswerType = typeof AnswerType[keyof typeof AnswerType];
export type Assessment = {
  __typename?: 'Assessment';
  completed: Scalars['Boolean']['output'];
  firstStepId: Scalars['String']['output'];
  questions?: Maybe<Array<AssessmentQuestion>>;
};

export type AssessmentAnswer = {
  answer: Scalars['String']['input'];
  stepId: Scalars['String']['input'];
};

export type AssessmentQuestion = {
  __typename?: 'AssessmentQuestion';
  answerOptions?: Maybe<Array<Scalars['String']['output']>>;
  answerType: AnswerType;
  dataId: Scalars['String']['output'];
  id: Scalars['String']['output'];
  isActive: Scalars['Boolean']['output'];
  next?: Maybe<Array<NextAssessmentQuestion>>;
  questionText: Scalars['String']['output'];
  questionType: Scalars['String']['output'];
  stepId: Scalars['String']['output'];
};

export type AssignedMemberInfoInput = {
  activityTemplateId?: InputMaybe<Scalars['ID']['input']>;
  includeMembersWithActivityTemplate: Scalars['Boolean']['input'];
  organizationId: Scalars['ID']['input'];
};

export type Bill = {
  __typename?: 'Bill';
  _id: Scalars['ID']['output'];
  approvedAt?: Maybe<Scalars['String']['output']>;
  approvedBy?: Maybe<User>;
  costConfiguration?: Maybe<BillingCostConfiguration>;
  deliveredAt?: Maybe<Scalars['String']['output']>;
  filePath?: Maybe<Scalars['String']['output']>;
  generatedAt: Scalars['String']['output'];
  generatedId: Scalars['String']['output'];
  insurance: InsuranceBillingConfigurationSummary;
  invoicedAmount?: Maybe<Scalars['Float']['output']>;
  member: Member;
  organization: BillOrganizationMeta;
  originalRefId?: Maybe<Scalars['String']['output']>;
  paidAmount?: Maybe<Scalars['Float']['output']>;
  prevGeneratedIds: Array<Scalars['String']['output']>;
  reIssue?: Maybe<Scalars['Boolean']['output']>;
  referenceId: Scalars['String']['output'];
  resolvedTransaction?: Maybe<InsuranceAccountTransaction>;
  status: BillStatus;
  statusMessages: Array<Scalars['String']['output']>;
  trace?: Maybe<Scalars['String']['output']>;
  transferredAt?: Maybe<Scalars['String']['output']>;
};

export type BillAggregate = {
  __typename?: 'BillAggregate';
  amount: Scalars['Float']['output'];
  count: Scalars['Int']['output'];
  status: BillStatus;
};

export type BillBatch = {
  __typename?: 'BillBatch';
  _id: Scalars['ID']['output'];
  availityId?: Maybe<Scalars['String']['output']>;
  bills: Array<BillSummary>;
  createdAt: Scalars['String']['output'];
  deliveryKind: BillDeliveryMethod;
  insuranceCompanies: Array<Scalars['String']['output']>;
  status: BillBatchStatus;
  updatedAt: Scalars['String']['output'];
};

export const BillBatchStatus = {
  Accepted: 'Accepted',
  FailedDelivery: 'FailedDelivery',
  Ready: 'Ready',
  Sending: 'Sending',
  Sent: 'Sent'
} as const;

export type BillBatchStatus = typeof BillBatchStatus[keyof typeof BillBatchStatus];
export const BillDeliveryMethod = {
  Availity: 'Availity',
  BulkEmail: 'BulkEmail',
  Email: 'Email'
} as const;

export type BillDeliveryMethod = typeof BillDeliveryMethod[keyof typeof BillDeliveryMethod];
export const BillGenerator = {
  Ascx12837: 'ASCX12837',
  Cms1500: 'CMS1500',
  CalCentene: 'CalCentene',
  Invoice: 'Invoice'
} as const;

export type BillGenerator = typeof BillGenerator[keyof typeof BillGenerator];
export type BillOrganizationMeta = {
  __typename?: 'BillOrganizationMeta';
  id: Scalars['ID']['output'];
  title: Scalars['String']['output'];
};

export const BillStatus = {
  Accepted: 'Accepted',
  Approved: 'Approved',
  Denied: 'Denied',
  Error: 'Error',
  ErrorWhileDelivery: 'ErrorWhileDelivery',
  Generated: 'Generated',
  Paid: 'Paid',
  ReadyForScreening: 'ReadyForScreening',
  Rejected: 'Rejected',
  Sent: 'Sent'
} as const;

export type BillStatus = typeof BillStatus[keyof typeof BillStatus];
export type BillSummary = {
  __typename?: 'BillSummary';
  _id: Scalars['ID']['output'];
  status: BillStatus;
};

export type BillTransactionInput =
  { connect: Scalars['ID']['input']; disconnect?: never; }
  |  { connect?: never; disconnect: Scalars['Boolean']['input']; };

export type BillingConfigurationInput = {
  billingConsentTemplateId?: InputMaybe<Scalars['ID']['input']>;
  billingEnabled: Scalars['Boolean']['input'];
  billingRecommendingProviderEnabled: Scalars['Boolean']['input'];
  billingRenderingProvider: BillingProviderInput;
  organizationId: Scalars['ID']['input'];
};

export type BillingCostConfiguration = {
  __typename?: 'BillingCostConfiguration';
  _id: Scalars['ID']['output'];
  cost: Scalars['Float']['output'];
  description?: Maybe<Scalars['String']['output']>;
  enableAutoGeneration: Scalars['Boolean']['output'];
  generationDiagnosisCodes: Array<Scalars['String']['output']>;
  generationFrequency: Scalars['Int']['output'];
  generationFrequencyUnit: GenerationFrequencyUnit;
  generationGroup?: Maybe<BillingCostConfigurationGroup>;
  generationPlaceOfService?: Maybe<Scalars['Int']['output']>;
  limits: Array<CostConfigurationLimit>;
  modifiers: Array<Scalars['String']['output']>;
  multiplier: Scalars['Int']['output'];
  procedure: Scalars['String']['output'];
  recommendingProviderRequired: Scalars['Boolean']['output'];
  unitPeriod: Scalars['Int']['output'];
  useDefaultUnitCost: Scalars['Boolean']['output'];
  userProviderRequired: Scalars['Boolean']['output'];
};

export type BillingCostConfigurationGroup = {
  __typename?: 'BillingCostConfigurationGroup';
  _id: Scalars['ID']['output'];
  title: Scalars['String']['output'];
};

export type BillingProvider = {
  __typename?: 'BillingProvider';
  _id: Scalars['ID']['output'];
  address: Scalars['String']['output'];
  city: Scalars['String']['output'];
  isDefault: Scalars['Boolean']['output'];
  label: Scalars['String']['output'];
  nationalProviderIdentifier?: Maybe<Scalars['String']['output']>;
  organizationId?: Maybe<Scalars['ID']['output']>;
  organizationNameORProviderLastName: Scalars['String']['output'];
  phone: Scalars['String']['output'];
  providerFirstName?: Maybe<Scalars['String']['output']>;
  state: Scalars['String']['output'];
  taxIdentificationNumber: Scalars['String']['output'];
  taxIdentificationNumberType: TaxIdentificationNumberType;
  type?: Maybe<ProviderType>;
  zip: Scalars['String']['output'];
};

export type BillingProviderInput = {
  address: Scalars['String']['input'];
  city: Scalars['String']['input'];
  isDefault?: InputMaybe<Scalars['Boolean']['input']>;
  label: Scalars['String']['input'];
  nationalProviderIdentifier?: InputMaybe<Scalars['String']['input']>;
  organizationId?: InputMaybe<Scalars['ID']['input']>;
  organizationNameORProviderLastName: Scalars['String']['input'];
  phone: Scalars['String']['input'];
  providerFirstName?: InputMaybe<Scalars['String']['input']>;
  state: Scalars['String']['input'];
  taxIdentificationNumber: Scalars['String']['input'];
  taxIdentificationNumberType?: InputMaybe<TaxIdentificationNumberType>;
  type?: InputMaybe<ProviderType>;
  userId?: InputMaybe<Scalars['ID']['input']>;
  zip: Scalars['String']['input'];
};

export type BuilderAccessDeniedNode = {
  __typename?: 'BuilderAccessDeniedNode';
  type: BuilderNodeType;
};

export type BuilderBasicNode = {
  __typename?: 'BuilderBasicNode';
  children: Array<BuilderNode>;
  id: Scalars['ID']['output'];
  title: Scalars['String']['output'];
  type: BuilderNodeType;
};

export type BuilderCircularNode = {
  __typename?: 'BuilderCircularNode';
  id: Scalars['ID']['output'];
};

export type BuilderNode = BuilderAccessDeniedNode | BuilderBasicNode | BuilderCircularNode;

export const BuilderNodeType = {
  Activity: 'Activity',
  Carepathway: 'Carepathway',
  EhrNote: 'EHRNote',
  Email: 'Email',
  Event: 'Event',
  Flow: 'Flow',
  Goal: 'Goal'
} as const;

export type BuilderNodeType = typeof BuilderNodeType[keyof typeof BuilderNodeType];
export type BulkCreateDataInput = {
  data?: InputMaybe<Array<CreateDataInput>>;
  organizationId: Scalars['ID']['input'];
};

export type BulkDataResponse = {
  __typename?: 'BulkDataResponse';
  data?: Maybe<Array<DataPoint>>;
  message?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

export type BulkUserAssignmentInput = {
  groupId?: InputMaybe<Scalars['ID']['input']>;
  organizationId: Scalars['ID']['input'];
  updateAssignedMembers: Scalars['Boolean']['input'];
  userIds?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type Calculation = {
  __typename?: 'Calculation';
  _id: Scalars['String']['output'];
  accessType?: Maybe<AccessType>;
  createdAt: Scalars['String']['output'];
  dataIdType: AnswerType;
  dataPointTemplate: DataPointTemplate;
  dataPointTemplateId: Scalars['String']['output'];
  expression: Scalars['String']['output'];
  lockingReferences?: Maybe<Array<Maybe<LockingReference>>>;
  name: Scalars['String']['output'];
  organizationId: Scalars['String']['output'];
  resultLabels?: Maybe<Array<Maybe<CalculationResultLabel>>>;
  tags?: Maybe<Array<Maybe<TaggedEntity>>>;
  updatedAt: Scalars['String']['output'];
  variables: Array<CalculationVariable>;
};

export type CalculationFilter = {
  condition: FilterCondition;
  filter: CalculationFilterProp;
  filterValue: Scalars['String']['input'];
};

export const CalculationFilterProp = {
  Tags: 'tags'
} as const;

export type CalculationFilterProp = typeof CalculationFilterProp[keyof typeof CalculationFilterProp];
export type CalculationResultLabel = {
  __typename?: 'CalculationResultLabel';
  label: Scalars['String']['output'];
  rangeEnd?: Maybe<Scalars['Int']['output']>;
  rangeStart?: Maybe<Scalars['Int']['output']>;
};

export type CalculationResultLabelInput = {
  label: Scalars['String']['input'];
  rangeEnd?: InputMaybe<Scalars['Int']['input']>;
  rangeStart?: InputMaybe<Scalars['Int']['input']>;
};

export type CalculationVariable = {
  __typename?: 'CalculationVariable';
  _id: Scalars['String']['output'];
  calculation?: Maybe<Calculation>;
  calculationId: Scalars['String']['output'];
  createdAt: Scalars['String']['output'];
  dataPointTemplate?: Maybe<DataPointTemplate>;
  dataPointTemplateId: Scalars['String']['output'];
  optionValueMappings?: Maybe<Array<Maybe<CalculationVariableOptionValueMapping>>>;
  updatedAt: Scalars['String']['output'];
};

export type CalculationVariableInput = {
  _id?: InputMaybe<Scalars['String']['input']>;
  dataId: Scalars['String']['input'];
  dataPointTemplateId: Scalars['String']['input'];
  optionValueMapping?: InputMaybe<Array<InputMaybe<CalculationVariableOptionValueMappingInput>>>;
};

export type CalculationVariableOptionValueMapping = {
  __typename?: 'CalculationVariableOptionValueMapping';
  option: Scalars['String']['output'];
  value: Scalars['Int']['output'];
};

export type CalculationVariableOptionValueMappingInput = {
  option: Scalars['String']['input'];
  value: Scalars['Int']['input'];
};

export type CalendarEvent = {
  __typename?: 'CalendarEvent';
  createdAt: Scalars['String']['output'];
  description: Scalars['String']['output'];
  endingAt: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  scheduledAt: Scalars['String']['output'];
  title: Scalars['String']['output'];
  updatedAt: Scalars['String']['output'];
};

export type CallCenterStats = {
  __typename?: 'CallCenterStats';
  connectionRate: Scalars['Float']['output'];
  totalAvgCallDuration: Scalars['Float']['output'];
  totalAvgCallsPerHour: Scalars['Float']['output'];
  totalCallsCompleted: Scalars['Float']['output'];
  totalCallsMade: Scalars['Float']['output'];
  totalFailedTexts: Scalars['Float']['output'];
  totalIncomingCalls: Scalars['Float']['output'];
  totalIncomingText: Scalars['Float']['output'];
  totalInteractions: Scalars['Float']['output'];
  totalMissedCalls: Scalars['Float']['output'];
  totalReadTexts: Scalars['Float']['output'];
  totalSuccessfulTexts: Scalars['Float']['output'];
  totalTextSent: Scalars['Float']['output'];
  totalVoiceMailLeft: Scalars['Float']['output'];
};

export type CallCenterStatsResponse = {
  __typename?: 'CallCenterStatsResponse';
  message?: Maybe<Scalars['String']['output']>;
  stats?: Maybe<CallCenterStats>;
  success: Scalars['Boolean']['output'];
};

export type CallRoutingConfiguration = {
  __typename?: 'CallRoutingConfiguration';
  _id: Scalars['ID']['output'];
  entryNodeId: Scalars['ID']['output'];
  nodes: Array<CallRoutingNode>;
  organizationId: Scalars['ID']['output'];
  startNodePosition: XyCoord;
};

export type CallRoutingConfigurationInput = {
  edges: Array<CallRoutingEdgeInput>;
  entryNodeId: Scalars['String']['input'];
  nodes: Array<CallRoutingNodeInput>;
  organizationId: Scalars['String']['input'];
  startNodePosition: XyCoordInput;
};

export type CallRoutingEdge = {
  __typename?: 'CallRoutingEdge';
  _id: Scalars['ID']['output'];
  condition?: Maybe<Scalars['Int']['output']>;
  conditionIndex?: Maybe<Scalars['Int']['output']>;
  sourceNodeId: Scalars['ID']['output'];
  targetNodeId: Scalars['ID']['output'];
};

export type CallRoutingEdgeInput = {
  _id: Scalars['ID']['input'];
  condition?: InputMaybe<Scalars['Int']['input']>;
  conditionIndex?: InputMaybe<Scalars['Int']['input']>;
  sourceNodeId: Scalars['ID']['input'];
  targetNodeId: Scalars['ID']['input'];
};

export type CallRoutingNode = {
  __typename?: 'CallRoutingNode';
  _id: Scalars['ID']['output'];
  position: XyCoord;
  sourceEdges: Array<CallRoutingEdge>;
  targetEdges: Array<CallRoutingEdge>;
  type: CallRoutingNodeType;
  userGroupId?: Maybe<Scalars['ID']['output']>;
  voicePromptContent?: Maybe<Scalars['String']['output']>;
};

export type CallRoutingNodeInput = {
  _id: Scalars['ID']['input'];
  position: XyCoordInput;
  sourceEdgeIds: Array<Scalars['ID']['input']>;
  targetEdgeIds: Array<Scalars['ID']['input']>;
  type: CallRoutingNodeType;
  userGroupId?: InputMaybe<Scalars['String']['input']>;
  voicePromptContent?: InputMaybe<Scalars['String']['input']>;
};

export const CallRoutingNodeType = {
  Branching: 'Branching',
  NextAssignedUser: 'NextAssignedUser',
  NextChw: 'NextCHW',
  Say: 'Say',
  UserGroup: 'UserGroup',
  Voicemail: 'Voicemail'
} as const;

export type CallRoutingNodeType = typeof CallRoutingNodeType[keyof typeof CallRoutingNodeType];
export const CallRoutingStatus = {
  Claimed: 'Claimed',
  Dialing: 'Dialing',
  Initializing: 'Initializing',
  Resolved: 'Resolved'
} as const;

export type CallRoutingStatus = typeof CallRoutingStatus[keyof typeof CallRoutingStatus];
export type CarePathway = {
  __typename?: 'CarePathway';
  _id: Scalars['ID']['output'];
  actionActivities: Array<ActionActivities>;
  carePathwayTemplate?: Maybe<CarePathwayTemplate>;
  carePathwayTemplateId: Scalars['ID']['output'];
  completedActionIds: Array<Scalars['ID']['output']>;
  completedAt?: Maybe<Scalars['String']['output']>;
  completedPercentage?: Maybe<Scalars['Int']['output']>;
  completedStepIds: Array<Scalars['ID']['output']>;
  createdAt: Scalars['String']['output'];
  inProgressStepIds: Array<Scalars['ID']['output']>;
  isDefault: Scalars['Boolean']['output'];
  memberId: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
  parentCarePathwayTemplateActionId?: Maybe<Scalars['String']['output']>;
  parentCarePathwayTemplateId?: Maybe<Scalars['String']['output']>;
  scheduledDate?: Maybe<Scalars['String']['output']>;
  status?: Maybe<CarePathwayStatus>;
  updatedAt: Scalars['String']['output'];
};

export type CarePathwayOnGoal = {
  __typename?: 'CarePathwayOnGoal';
  carePathway?: Maybe<CarePathway>;
  carePathwayId: Scalars['ID']['output'];
  goalId: Scalars['ID']['output'];
};

export type CarePathwayResponse = {
  __typename?: 'CarePathwayResponse';
  data?: Maybe<CarePathway>;
  message?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

export const CarePathwayStatus = {
  CompletedSuccessful: 'CompletedSuccessful',
  CompletedUnsuccessful: 'CompletedUnsuccessful',
  Enrolled: 'Enrolled'
} as const;

export type CarePathwayStatus = typeof CarePathwayStatus[keyof typeof CarePathwayStatus];
export type CarePathwayTemplate = {
  __typename?: 'CarePathwayTemplate';
  _id: Scalars['ID']['output'];
  accessType?: Maybe<AccessType>;
  additionalEdges?: Maybe<Array<Maybe<NodeEdge>>>;
  additionalSteps?: Maybe<Array<Maybe<CarePathwayTemplateStep>>>;
  completedPercentage?: Maybe<Scalars['Int']['output']>;
  createdAt: Scalars['String']['output'];
  dataPointTemplateEnrolled?: Maybe<DataPointTemplate>;
  dataPointTemplateEnrolledId?: Maybe<Scalars['ID']['output']>;
  dataPointTemplateSuccessful?: Maybe<DataPointTemplate>;
  dataPointTemplateSuccessfulId?: Maybe<Scalars['ID']['output']>;
  dataPointTemplateUnsuccessful?: Maybe<DataPointTemplate>;
  dataPointTemplateUnsuccessfulId?: Maybe<Scalars['ID']['output']>;
  defaultAssignedUserGroup?: Maybe<UserGroup>;
  defaultAssignedUserGroupId?: Maybe<Scalars['ID']['output']>;
  firstStepId: Scalars['ID']['output'];
  isDefault: Scalars['Boolean']['output'];
  lockedByUse: Scalars['Boolean']['output'];
  lockingReferences: Array<LockingReference>;
  name: Scalars['String']['output'];
  organizationId: Scalars['ID']['output'];
  relatedMemberCarePathwayCreatedAt?: Maybe<Scalars['String']['output']>;
  relatedMemberCarePathwayId?: Maybe<Scalars['ID']['output']>;
  status?: Maybe<CarePathwayStatus>;
  steps: Array<CarePathwayTemplateStep>;
  updatedAt: Scalars['String']['output'];
  userId: Scalars['String']['output'];
};

export type CarePathwayTemplateIdAndOrgInput = {
  carePathwayTemplateId: Scalars['String']['input'];
  organizationId: Scalars['String']['input'];
};

export type CarePathwayTemplateInput = {
  _id?: InputMaybe<Scalars['ID']['input']>;
  dataPointTemplateEnrolledId?: InputMaybe<Scalars['ID']['input']>;
  dataPointTemplateSuccessfulId?: InputMaybe<Scalars['ID']['input']>;
  dataPointTemplateUnsuccessfulId?: InputMaybe<Scalars['ID']['input']>;
  defaultAssignedUserGroupId?: InputMaybe<Scalars['ID']['input']>;
  firstStepId: Scalars['ID']['input'];
  isDefault: Scalars['Boolean']['input'];
  name: Scalars['String']['input'];
  organizationId: Scalars['ID']['input'];
  steps: Array<CarePathwayTemplateStepInput>;
  userId: Scalars['String']['input'];
};

export type CarePathwayTemplateResponse = {
  __typename?: 'CarePathwayTemplateResponse';
  data?: Maybe<CarePathwayTemplate>;
  message?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

export type CarePathwayTemplateStatusCount = {
  __typename?: 'CarePathwayTemplateStatusCount';
  carePathwayTemplate: Scalars['String']['output'];
  completedSuccessfulCount: Scalars['Int']['output'];
  completedUnsuccessfulCount: Scalars['Int']['output'];
  createdCount: Scalars['Int']['output'];
  enrolledCount: Scalars['Int']['output'];
};

export type CarePathwayTemplateStep = {
  __typename?: 'CarePathwayTemplateStep';
  _id: Scalars['ID']['output'];
  actions: Array<CarePathwayTemplateStepAction>;
  activityTemplateDetails?: Maybe<CarePathwayTemplateStepActivityTemplateDetails>;
  isMultiOutput: Scalars['Boolean']['output'];
  nextStepsConditions?: Maybe<Array<CarePathwayTemplateStepConditionsGroupItem>>;
  position: XyCoord;
  targetSteps: Array<CarePathwayTemplateTargetStep>;
};

export type CarePathwayTemplateStepAction = {
  __typename?: 'CarePathwayTemplateStepAction';
  _id: Scalars['ID']['output'];
  assignedUserType: ActionAssignedUserType;
  assignedUserTypeId?: Maybe<Scalars['String']['output']>;
  date?: Maybe<Scalars['String']['output']>;
  interval?: Maybe<Scalars['String']['output']>;
  resourceId: Scalars['String']['output'];
  resourceType: CarePathwayTemplateStepResourceType;
  scheduleType: ScheduleTypeEnum;
  type: CarePathwayTemplateStepActionType;
};

export type CarePathwayTemplateStepActionInput = {
  _id: Scalars['ID']['input'];
  assignedUserType: ActionAssignedUserType;
  assignedUserTypeId?: InputMaybe<Scalars['String']['input']>;
  date?: InputMaybe<Scalars['String']['input']>;
  interval?: InputMaybe<Scalars['String']['input']>;
  resourceId: Scalars['String']['input'];
  resourceType: CarePathwayTemplateStepResourceType;
  scheduleType: ScheduleTypeEnum;
  type: CarePathwayTemplateStepActionType;
};

export const CarePathwayTemplateStepActionType = {
  Create: 'CREATE'
} as const;

export type CarePathwayTemplateStepActionType = typeof CarePathwayTemplateStepActionType[keyof typeof CarePathwayTemplateStepActionType];
export type CarePathwayTemplateStepActivityTemplateDetails = {
  __typename?: 'CarePathwayTemplateStepActivityTemplateDetails';
  activities?: Maybe<Array<Maybe<Activity>>>;
  activityTemplateId?: Maybe<Scalars['ID']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

export type CarePathwayTemplateStepConditionsGroupItem = {
  __typename?: 'CarePathwayTemplateStepConditionsGroupItem';
  _id: Scalars['ID']['output'];
  conditionsGroup: Array<ConditionsGroupItem>;
};

export type CarePathwayTemplateStepConditionsGroupItemInput = {
  _id: Scalars['ID']['input'];
  conditionsGroup?: InputMaybe<Array<ConditionsGroupItemInput>>;
};

export type CarePathwayTemplateStepInput = {
  _id: Scalars['ID']['input'];
  actions: Array<CarePathwayTemplateStepActionInput>;
  isMultiOutput: Scalars['Boolean']['input'];
  nextStepsConditions?: InputMaybe<Array<CarePathwayTemplateStepConditionsGroupItemInput>>;
  position: XyCoordInput;
  targetSteps: Array<CarePathwayTemplateTargetStepInput>;
};

export const CarePathwayTemplateStepResourceType = {
  ActivityTemplate: 'ACTIVITY_TEMPLATE'
} as const;

export type CarePathwayTemplateStepResourceType = typeof CarePathwayTemplateStepResourceType[keyof typeof CarePathwayTemplateStepResourceType];
export type CarePathwayTemplateTargetStep = {
  __typename?: 'CarePathwayTemplateTargetStep';
  _id: Scalars['ID']['output'];
  conditionsGroupItemId?: Maybe<Scalars['ID']['output']>;
  sourceHandle: Scalars['String']['output'];
  targetHandle: Scalars['String']['output'];
  targetStepId: Scalars['ID']['output'];
};

export type CarePathwayTemplateTargetStepInput = {
  _id: Scalars['ID']['input'];
  conditionsGroupItemId?: InputMaybe<Scalars['ID']['input']>;
  sourceHandle: Scalars['String']['input'];
  targetHandle: Scalars['String']['input'];
  targetStepId: Scalars['ID']['input'];
};

export type CarePathwayTemplateUpdateDefaultAssignedUserGroupInput = {
  carePathwayTemplateId: Scalars['ID']['input'];
  organizationId: Scalars['ID']['input'];
  userGroupId: Scalars['ID']['input'];
};

export type CarePathwayTemplatesResponse = {
  __typename?: 'CarePathwayTemplatesResponse';
  data?: Maybe<Array<CarePathwayTemplate>>;
  message?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

export type CarePathwaysResponse = {
  __typename?: 'CarePathwaysResponse';
  data?: Maybe<Array<Maybe<CarePathway>>>;
  message?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

export type CarePathwaysStats = {
  __typename?: 'CarePathwaysStats';
  carePathwayTemplateStatuses: Array<CarePathwayTemplateStatusCount>;
  data: Array<ResourceGranularData>;
  groupByTimeUnit: GroupTimeUnit;
};

export type CarePathwaysStatsResponse = {
  __typename?: 'CarePathwaysStatsResponse';
  message?: Maybe<Scalars['String']['output']>;
  stats?: Maybe<CarePathwaysStats>;
  success: Scalars['Boolean']['output'];
};

export type CarePlanAdditionalSectionDataId = {
  __typename?: 'CarePlanAdditionalSectionDataId';
  dataId?: Maybe<Scalars['String']['output']>;
  label?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

export type CarePlanSection = {
  __typename?: 'CarePlanSection';
  dataIds?: Maybe<Array<Maybe<CarePlanSectionDataId>>>;
  title?: Maybe<Scalars['String']['output']>;
};

export type CarePlanSectionDataId = {
  __typename?: 'CarePlanSectionDataId';
  answerType?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  label?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

export type CarePlanSectionDataIdInput = {
  answerType?: InputMaybe<Scalars['String']['input']>;
  label: Scalars['String']['input'];
  value: Scalars['String']['input'];
};

export type CarePlanSnapshot = {
  __typename?: 'CarePlanSnapshot';
  _id: Scalars['ID']['output'];
  createdAt: Scalars['String']['output'];
  file?: Maybe<PsFile>;
  updatedAt: Scalars['String']['output'];
};

export type CarePlanTemplate = {
  __typename?: 'CarePlanTemplate';
  _id?: Maybe<Scalars['ID']['output']>;
  additionalSections?: Maybe<Array<Maybe<CarePlanSection>>>;
  createdAt?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  domains?: Maybe<Array<Maybe<Domain>>>;
  organizationId?: Maybe<Scalars['ID']['output']>;
  selectedMemberDataIds?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  selectedProviderDetails?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  title?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['String']['output']>;
};

export type CarePlanTemplateInput = {
  _id?: InputMaybe<Scalars['ID']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  domainIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  organizationId: Scalars['ID']['input'];
  title: Scalars['String']['input'];
};

export type CarePlanTemplateResponse = {
  __typename?: 'CarePlanTemplateResponse';
  data?: Maybe<CarePlanTemplate>;
  message?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

export type Certification = {
  __typename?: 'Certification';
  _id: Scalars['ID']['output'];
  certificationTemplateId?: Maybe<Scalars['ID']['output']>;
  createdAt: Scalars['String']['output'];
  description?: Maybe<Scalars['String']['output']>;
  expirationDate?: Maybe<Scalars['String']['output']>;
  fileId?: Maybe<Scalars['ID']['output']>;
  fileName?: Maybe<Scalars['String']['output']>;
  isActive: Scalars['Boolean']['output'];
  organizationId: Scalars['ID']['output'];
  retiredAt?: Maybe<Scalars['String']['output']>;
  revokedAt?: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
  updatedAt: Scalars['String']['output'];
  userId: Scalars['ID']['output'];
};

export type CertificationInput = {
  _id?: InputMaybe<Scalars['ID']['input']>;
  certificationTemplateId?: InputMaybe<Scalars['ID']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  fileId?: InputMaybe<Scalars['ID']['input']>;
  isActive: Scalars['Boolean']['input'];
  organizationId: Scalars['ID']['input'];
  title: Scalars['String']['input'];
  userId: Scalars['ID']['input'];
};

export type CertificationResponse = {
  __typename?: 'CertificationResponse';
  data?: Maybe<Certification>;
  message?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

export type CertificationTemplate = {
  __typename?: 'CertificationTemplate';
  _id: Scalars['ID']['output'];
  certifications?: Maybe<Array<Certification>>;
  createdAt: Scalars['String']['output'];
  deletedAt?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  organizationId: Scalars['ID']['output'];
  updatedAt: Scalars['String']['output'];
  userId: Scalars['ID']['output'];
};

export type CertificationTemplateInput = {
  _id?: InputMaybe<Scalars['ID']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  organizationId: Scalars['ID']['input'];
};

export type CertificationTemplateResponse = {
  __typename?: 'CertificationTemplateResponse';
  data?: Maybe<CertificationTemplate>;
  message?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

export type CertificationTemplatesResponse = {
  __typename?: 'CertificationTemplatesResponse';
  data?: Maybe<Array<CertificationTemplate>>;
  message?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

export type CertificationsResponse = {
  __typename?: 'CertificationsResponse';
  data?: Maybe<Array<Certification>>;
  message?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

export type CheckFileNameInput = {
  fileName: Scalars['String']['input'];
  memberId: Scalars['ID']['input'];
  organizationId: Scalars['ID']['input'];
};

export type CheckFileNameResponse = {
  __typename?: 'CheckFileNameResponse';
  data?: Maybe<Scalars['ID']['output']>;
  message?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

export type ClaimIncomingPhoneCallInput = {
  phoneCallId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
};

export type ClaimIncomingPhoneCallResponse = {
  __typename?: 'ClaimIncomingPhoneCallResponse';
  data?: Maybe<ClaimSuccess>;
  message?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

export type ClaimSuccess = {
  __typename?: 'ClaimSuccess';
  didClaim: Scalars['Boolean']['output'];
};

export type CloseMemberInput = {
  memberId: Scalars['ID']['input'];
  organizationId: Scalars['ID']['input'];
  reason?: InputMaybe<Scalars['String']['input']>;
  status: MemberStatus;
};

export type CompleteActionData = {
  __typename?: 'CompleteActionData';
  addMemberToGroup?: Maybe<Group>;
  createdCarePathway?: Maybe<CarePathway>;
  createdConsent?: Maybe<CreatedConsentResponse>;
  createdFlow?: Maybe<Flow>;
  createdGoal?: Maybe<Goal>;
  createdReferral?: Maybe<Referral>;
  removeMemberFromGroup?: Maybe<Group>;
  scheduledActivity?: Maybe<Activity>;
};

export type CompleteActionResponse = {
  __typename?: 'CompleteActionResponse';
  createdDataPoint?: Maybe<DataPoint>;
  data?: Maybe<Array<CompleteActionData>>;
  message?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

export type CompletedActivitiesByTypeByMonth = {
  __typename?: 'CompletedActivitiesByTypeByMonth';
  activityId: Scalars['String']['output'];
  activityName: Scalars['String']['output'];
  count: Scalars['Int']['output'];
  month: Scalars['String']['output'];
};

export type CompletedGoalsByTypeByMonth = {
  __typename?: 'CompletedGoalsByTypeByMonth';
  count: Scalars['Int']['output'];
  month: Scalars['String']['output'];
  templateId: Scalars['String']['output'];
  templateName: Scalars['String']['output'];
};

export type ConditionItem = {
  __typename?: 'ConditionItem';
  _id: Scalars['ID']['output'];
  conditionOperator: ConditionOperator;
  conditionsGroupItemId?: Maybe<Scalars['ID']['output']>;
  dataId: Scalars['String']['output'];
  dataPointTemplateId?: Maybe<Scalars['ID']['output']>;
  operator: Operator;
  value: Array<Scalars['String']['output']>;
};

export type ConditionItemInput = {
  _id: Scalars['ID']['input'];
  conditionOperator: ConditionOperator;
  conditionsGroupItemId?: InputMaybe<Scalars['ID']['input']>;
  dataId: Scalars['String']['input'];
  dataPointTemplateId?: InputMaybe<Scalars['String']['input']>;
  operator: Operator;
  value: Array<Scalars['String']['input']>;
};

export const ConditionOperator = {
  And: 'AND',
  Or: 'OR'
} as const;

export type ConditionOperator = typeof ConditionOperator[keyof typeof ConditionOperator];
export type ConditionsGroupItem = {
  __typename?: 'ConditionsGroupItem';
  _id: Scalars['ID']['output'];
  conditionOperator: ConditionOperator;
  conditions: Array<ConditionItem>;
  recommendationTemplateId?: Maybe<Scalars['ID']['output']>;
};

export type ConditionsGroupItemInput = {
  _id: Scalars['ID']['input'];
  conditionOperator: ConditionOperator;
  conditions: Array<ConditionItemInput>;
};

export type Consent = {
  __typename?: 'Consent';
  _id: Scalars['ID']['output'];
  consentMethod: ConsentMethod;
  consentTemplateId?: Maybe<Scalars['ID']['output']>;
  createdAt: Scalars['String']['output'];
  description?: Maybe<Scalars['String']['output']>;
  fileId?: Maybe<Scalars['ID']['output']>;
  fileName?: Maybe<Scalars['String']['output']>;
  isActive: Scalars['Boolean']['output'];
  memberId: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  organizationId: Scalars['ID']['output'];
  phoneCallId?: Maybe<Scalars['ID']['output']>;
  phoneCallSid?: Maybe<Scalars['String']['output']>;
  retiredAt?: Maybe<Scalars['String']['output']>;
  revokedAt?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['String']['output'];
  userId: Scalars['ID']['output'];
};

export type ConsentInput = {
  _id?: InputMaybe<Scalars['ID']['input']>;
  consentMethod: ConsentMethod;
  consentTemplateId?: InputMaybe<Scalars['ID']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  fileId?: InputMaybe<Scalars['ID']['input']>;
  isActive: Scalars['Boolean']['input'];
  memberId: Scalars['ID']['input'];
  name: Scalars['String']['input'];
  organizationId: Scalars['ID']['input'];
  phoneCallId?: InputMaybe<Scalars['ID']['input']>;
  retiredAt?: InputMaybe<Scalars['String']['input']>;
};

export const ConsentMethod = {
  ChwAttestation: 'CHWAttestation',
  CallRecordingRequired: 'CallRecordingRequired',
  DocumentRequired: 'DocumentRequired'
} as const;

export type ConsentMethod = typeof ConsentMethod[keyof typeof ConsentMethod];
export type ConsentResponse = {
  __typename?: 'ConsentResponse';
  data?: Maybe<Consent>;
  message?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

export type ConsentTemplate = {
  __typename?: 'ConsentTemplate';
  _id: Scalars['ID']['output'];
  accessType?: Maybe<AccessType>;
  consentMethods: Array<ConsentMethod>;
  createdAt: Scalars['String']['output'];
  dataId: Scalars['String']['output'];
  dataPointTemplate?: Maybe<DataPointTemplate>;
  deletedAt?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  organizationId: Scalars['ID']['output'];
  updatedAt: Scalars['String']['output'];
  userId: Scalars['ID']['output'];
};

export type ConsentTemplateInput = {
  _id?: InputMaybe<Scalars['ID']['input']>;
  consentMethods: Array<ConsentMethod>;
  dataId: Scalars['String']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  organizationId: Scalars['ID']['input'];
};

export type ConsentTemplateResponse = {
  __typename?: 'ConsentTemplateResponse';
  data?: Maybe<ConsentTemplate>;
  message?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

export type ConsentTemplatesResponse = {
  __typename?: 'ConsentTemplatesResponse';
  data?: Maybe<Array<ConsentTemplate>>;
  message?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

export type ConsentsResponse = {
  __typename?: 'ConsentsResponse';
  data?: Maybe<Array<Consent>>;
  message?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

export type ContactInfo = {
  __typename?: 'ContactInfo';
  address?: Maybe<Scalars['String']['output']>;
  address2?: Maybe<Scalars['String']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  doNotCall?: Maybe<Scalars['Boolean']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  groups?: Maybe<Array<MemberGroup>>;
  organizations: Array<Scalars['String']['output']>;
  phone: Scalars['String']['output'];
  state?: Maybe<Scalars['String']['output']>;
  zip?: Maybe<Scalars['String']['output']>;
};

export type ContactInfoInput = {
  address?: InputMaybe<Scalars['String']['input']>;
  address2?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  doNotCall?: InputMaybe<Scalars['Boolean']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  groups?: InputMaybe<Array<Scalars['ID']['input']>>;
  organization: Scalars['ID']['input'];
  phone: Scalars['String']['input'];
  state?: InputMaybe<Scalars['String']['input']>;
  zip?: InputMaybe<Scalars['String']['input']>;
};

export type CopyCarePathwayTemplateInput = {
  carePathwayTemplateId: Scalars['ID']['input'];
  organizationId: Scalars['ID']['input'];
};

export type CopyEmailTemplateInput = {
  newTemplateSubject: Scalars['String']['input'];
  organizationId: Scalars['ID']['input'];
  templateId: Scalars['ID']['input'];
};

export type CopyFlowTemplateInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
  newTemplateTitle: Scalars['String']['input'];
  organizationId: Scalars['ID']['input'];
  templateId: Scalars['ID']['input'];
};

export type CostConfigurationLimit = {
  __typename?: 'CostConfigurationLimit';
  _id: Scalars['ID']['output'];
  label: Scalars['String']['output'];
  period: Scalars['Int']['output'];
  periodUnit: CostConfigurationLimitPeriodUnit;
  units: Scalars['Int']['output'];
};

export type CostConfigurationLimitInput = {
  label: Scalars['String']['input'];
  period: Scalars['Int']['input'];
  periodUnit: CostConfigurationLimitPeriodUnit;
  units: Scalars['Int']['input'];
};

export const CostConfigurationLimitPeriodUnit = {
  Day: 'Day',
  Month: 'Month',
  Year: 'Year'
} as const;

export type CostConfigurationLimitPeriodUnit = typeof CostConfigurationLimitPeriodUnit[keyof typeof CostConfigurationLimitPeriodUnit];
export type CreateActivityResponse = {
  __typename?: 'CreateActivityResponse';
  data?: Maybe<Activity>;
  message?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

export type CreateBillingCostConfiguration = {
  cost: Scalars['Float']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  enableAutoGeneration?: InputMaybe<Scalars['Boolean']['input']>;
  generationDiagnosisCodes?: InputMaybe<Array<Scalars['String']['input']>>;
  generationFrequency?: InputMaybe<Scalars['Int']['input']>;
  generationFrequencyUnit?: InputMaybe<GenerationFrequencyUnit>;
  generationGroup?: InputMaybe<Scalars['String']['input']>;
  generationPlaceOfService?: InputMaybe<Scalars['Int']['input']>;
  limits?: InputMaybe<Array<CostConfigurationLimitInput>>;
  modifiers?: InputMaybe<Array<Scalars['String']['input']>>;
  multiplier?: InputMaybe<Scalars['Int']['input']>;
  procedure: Scalars['String']['input'];
  recommendingProviderRequired: Scalars['Boolean']['input'];
  unitPeriod: Scalars['Int']['input'];
  useDefaultUnitCost?: InputMaybe<Scalars['Boolean']['input']>;
  userProviderRequired: Scalars['Boolean']['input'];
};

export type CreateCalculationInput = {
  calculationDataId: Scalars['String']['input'];
  dataIdType: AnswerType;
  expression: Scalars['String']['input'];
  name: Scalars['String']['input'];
  organizationId: Scalars['ID']['input'];
  resultLabels?: InputMaybe<Array<InputMaybe<CalculationResultLabelInput>>>;
  tagNames?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  variables: Array<CalculationVariableInput>;
};

export type CreateCalendarEventInput = {
  description: Scalars['String']['input'];
  endingAt: Scalars['String']['input'];
  organizationId: Scalars['ID']['input'];
  scheduledAt: Scalars['String']['input'];
  title: Scalars['String']['input'];
  userId: Scalars['ID']['input'];
};

export type CreateCalendarEventResponse = {
  __typename?: 'CreateCalendarEventResponse';
  data?: Maybe<CalendarEvent>;
  message: Scalars['String']['output'];
  success: Scalars['Boolean']['output'];
};

export type CreateCarePlanTemplateInput = {
  _id?: InputMaybe<Scalars['ID']['input']>;
  additionalSections?: InputMaybe<Array<InputMaybe<SectionInput>>>;
  description?: InputMaybe<Scalars['String']['input']>;
  domainIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  organizationId: Scalars['ID']['input'];
  selectedMemberDataIds?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  selectedProviderDetails?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title: Scalars['String']['input'];
};

export type CreateConsentActionInput = {
  consentTemplateId: Scalars['ID']['input'];
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type CreateConsentData = {
  __typename?: 'CreateConsentData';
  consentTemplateId: Scalars['ID']['output'];
  id?: Maybe<Scalars['ID']['output']>;
};

export type CreateConsentInput = {
  consentTemplateId: Scalars['ID']['input'];
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type CreateDataInput = {
  activityId?: InputMaybe<Scalars['ID']['input']>;
  answer: Scalars['String']['input'];
  dataId: Scalars['String']['input'];
  dataPointTemplateId: Scalars['ID']['input'];
  memberId: Scalars['ID']['input'];
  organizationId: Scalars['ID']['input'];
  questionText?: InputMaybe<Scalars['String']['input']>;
};

export type CreateEhrNoteTemplateInput = {
  contentType: EhrNoteTemplateContentType;
  document?: InputMaybe<Scalars['String']['input']>;
  documentType: Scalars['String']['input'];
  name: Scalars['String']['input'];
  organizationId: Scalars['ID']['input'];
};

export type CreateFlowInput = {
  activityId?: InputMaybe<Scalars['ID']['input']>;
  flowTemplateFamilyId: Scalars['ID']['input'];
  memberId: Scalars['ID']['input'];
  organizationId: Scalars['ID']['input'];
};

export type CreateFlowResponse = {
  __typename?: 'CreateFlowResponse';
  data?: Maybe<Flow>;
  message?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

export type CreateFlowTemplateInput = {
  _id?: InputMaybe<Scalars['ID']['input']>;
  chapters?: InputMaybe<Array<Scalars['String']['input']>>;
  entryStepId: Scalars['String']['input'];
  familyId?: InputMaybe<Scalars['ID']['input']>;
  flowType: FlowType;
  organizationId: Scalars['ID']['input'];
  stagePositions: JourneyStagePositionsInput;
  startNodePosition: XyCoordInput;
  steps?: InputMaybe<Array<FlowStepInput>>;
  title: Scalars['String']['input'];
};

export type CreateGoalActionInput = {
  goalTemplateId: Scalars['ID']['input'];
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type CreateGoalData = {
  __typename?: 'CreateGoalData';
  goalTemplateId: Scalars['ID']['output'];
  id?: Maybe<Scalars['ID']['output']>;
};

export type CreateGoalInput = {
  activityId?: InputMaybe<Scalars['ID']['input']>;
  activityIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  baseTemplateId?: InputMaybe<Scalars['String']['input']>;
  carePathwayIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  createForAllMembersInActivity?: InputMaybe<Scalars['Boolean']['input']>;
  domainId?: InputMaybe<Scalars['String']['input']>;
  dueDate?: InputMaybe<Scalars['String']['input']>;
  episodeOfCareId?: InputMaybe<Scalars['String']['input']>;
  memberId: Scalars['ID']['input'];
  organizationId: Scalars['ID']['input'];
  referralIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  template: GoalTemplateInput;
};

export type CreateGoalResponse = {
  __typename?: 'CreateGoalResponse';
  data?: Maybe<Array<Maybe<Goal>>>;
  message?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

export type CreateGroupActivityResponse = {
  __typename?: 'CreateGroupActivityResponse';
  data?: Maybe<Array<CreatedActivity>>;
  message?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

export type CreateGroupInput = {
  _id?: InputMaybe<Scalars['String']['input']>;
  endAt?: InputMaybe<Scalars['String']['input']>;
  endInDays?: InputMaybe<Scalars['Int']['input']>;
  endInMonths?: InputMaybe<Scalars['Int']['input']>;
  endInWeeks?: InputMaybe<Scalars['Int']['input']>;
  organizationId: Scalars['ID']['input'];
  title: Scalars['String']['input'];
};

export type CreateNoteInput = {
  activityId?: InputMaybe<Scalars['ID']['input']>;
  authorId: Scalars['ID']['input'];
  content: Scalars['String']['input'];
  createForAllMembersInActivity?: InputMaybe<Scalars['Boolean']['input']>;
  domainIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  externalResourceContactId?: InputMaybe<Scalars['ID']['input']>;
  externalResourceId?: InputMaybe<Scalars['ID']['input']>;
  familyId?: InputMaybe<Scalars['ID']['input']>;
  memberId?: InputMaybe<Scalars['ID']['input']>;
  noteTemplateId?: InputMaybe<Scalars['ID']['input']>;
  organizationId: Scalars['ID']['input'];
  title?: InputMaybe<Scalars['String']['input']>;
};

export type CreateNoteTemplateInput = {
  additionalFields?: InputMaybe<Array<AdditionalFieldsInput>>;
  content?: InputMaybe<Scalars['String']['input']>;
  domainIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  organizationId: Scalars['ID']['input'];
  title: Scalars['String']['input'];
};

export type CreateOrUpdateBillingCostConfigurationInput = {
  costs: Array<CreateBillingCostConfiguration>;
  insuranceIds: Array<Scalars['ID']['input']>;
  organizationId?: InputMaybe<Scalars['ID']['input']>;
};

export type CreateOrUpdateMemberData = {
  __typename?: 'CreateOrUpdateMemberData';
  carePathways: Array<CarePathway>;
  dataPoints?: Maybe<Array<DataPoint>>;
  member: Member;
};

export type CreateOrUpdateMemberResponse = {
  __typename?: 'CreateOrUpdateMemberResponse';
  data?: Maybe<CreateOrUpdateMemberData>;
  message?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

export type CreateOrUpdateOrganizationBillingConfigurationInsuranceInput =
  { custom: InsuranceBillingConfigurationInput; inNetwork?: never; }
  |  { custom?: never; inNetwork: Scalars['String']['input']; };

export type CreateOrUpdateRoleInput = {
  _id?: InputMaybe<Scalars['ID']['input']>;
  name: Scalars['String']['input'];
  organizationId: Scalars['ID']['input'];
  permissions: Array<Permission>;
};

export type CreateProviderRecommendationInput = {
  memberId: Scalars['ID']['input'];
  organizationId: Scalars['ID']['input'];
};

export type CreateProviderRecommendationResponse = {
  __typename?: 'CreateProviderRecommendationResponse';
  data?: Maybe<ProviderRecommendation>;
  message?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

export type CreateRecommendationInput = {
  _id?: InputMaybe<Scalars['ID']['input']>;
  actions?: InputMaybe<Array<ActionDataInput>>;
  conditionsGroups: Array<InputMaybe<ConditionsGroupItemInput>>;
  name: Scalars['String']['input'];
  organizationId: Scalars['ID']['input'];
  recommendationText: Scalars['String']['input'];
};

export type CreateReferralActionInput = {
  externalResourceId: Scalars['ID']['input'];
  id?: InputMaybe<Scalars['ID']['input']>;
  referralTemplateId: Scalars['ID']['input'];
  type: ReferralType;
};

export type CreateReferralData = {
  __typename?: 'CreateReferralData';
  externalResourceId: Scalars['ID']['output'];
  id?: Maybe<Scalars['ID']['output']>;
  referralTemplateId: Scalars['ID']['output'];
  type: ReferralType;
};

export type CreateReferralInput = {
  activityDate?: InputMaybe<Scalars['String']['input']>;
  activityId?: InputMaybe<Scalars['String']['input']>;
  createForAllMembersInActivity?: InputMaybe<Scalars['Boolean']['input']>;
  memberId: Scalars['ID']['input'];
  newAnswers?: InputMaybe<Array<NewAnswerInput>>;
  organizationId: Scalars['ID']['input'];
  referralTemplateId: Scalars['ID']['input'];
  updatedEmailBody?: InputMaybe<Scalars['String']['input']>;
  updatedSmsBody?: InputMaybe<Scalars['String']['input']>;
  userId?: InputMaybe<Scalars['ID']['input']>;
};

export type CreateReferralResponse = {
  __typename?: 'CreateReferralResponse';
  data?: Maybe<Array<Maybe<Referral>>>;
  message?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

export type CreateSignatureRequestResponse = {
  __typename?: 'CreateSignatureRequestResponse';
  data?: Maybe<SignatureRequest>;
  message?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

export type CreateTagInput = {
  dataId?: InputMaybe<Scalars['String']['input']>;
  label: Scalars['String']['input'];
  organizationId: Scalars['ID']['input'];
};

export type CreateUserGroupInput = {
  organizationId: Scalars['ID']['input'];
  title: Scalars['String']['input'];
};

export type CreatedActivity = {
  __typename?: 'CreatedActivity';
  activityId: Scalars['ID']['output'];
  memberId: Scalars['ID']['output'];
};

export type CreatedConsentResponse = {
  __typename?: 'CreatedConsentResponse';
  consentTemplateId: Scalars['ID']['output'];
  data?: Maybe<Consent>;
  message?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

export type CsvUploadProviderRecommendationForMembersInput = {
  csvFile: Scalars['Upload']['input'];
  organizationId: Scalars['ID']['input'];
  recommendationFile: Scalars['Upload']['input'];
  recommendingProviderId?: InputMaybe<Scalars['ID']['input']>;
};

export type DataExport = {
  __typename?: 'DataExport';
  _id?: Maybe<Scalars['ID']['output']>;
  createdAt?: Maybe<Scalars['String']['output']>;
  dataExportTemplate?: Maybe<DataExportTemplate>;
  dataExportTemplateId?: Maybe<Scalars['ID']['output']>;
  dateRangeFrom?: Maybe<Scalars['String']['output']>;
  dateRangeTo?: Maybe<Scalars['String']['output']>;
  errorMessage?: Maybe<Scalars['String']['output']>;
  file?: Maybe<PsFile>;
  fileId?: Maybe<Scalars['ID']['output']>;
  status?: Maybe<DataExportStatus>;
  updatedAt?: Maybe<Scalars['String']['output']>;
};

export type DataExportInput = {
  dataExportTemplateId: Scalars['ID']['input'];
  dateRangeFrom?: InputMaybe<Scalars['String']['input']>;
  dateRangeTo?: InputMaybe<Scalars['String']['input']>;
  organizationId: Scalars['ID']['input'];
};

export type DataExportResponse = {
  __typename?: 'DataExportResponse';
  data?: Maybe<DataExport>;
  message?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

export const DataExportStatus = {
  Completed: 'Completed',
  Error: 'Error',
  Pending: 'Pending'
} as const;

export type DataExportStatus = typeof DataExportStatus[keyof typeof DataExportStatus];
export type DataExportTemplate = {
  __typename?: 'DataExportTemplate';
  _id: Scalars['ID']['output'];
  accessType?: Maybe<AccessType>;
  activityTemplateId?: Maybe<Scalars['ID']['output']>;
  columnMappings?: Maybe<Array<DataImportExportColumnMapping>>;
  createdAt?: Maybe<Scalars['String']['output']>;
  exportType?: Maybe<DataExportType>;
  exports?: Maybe<Array<Maybe<DataExport>>>;
  filterConditions?: Maybe<Array<Maybe<ConditionItem>>>;
  historical?: Maybe<Scalars['Boolean']['output']>;
  memberGroupIdFilter?: Maybe<Array<Scalars['ID']['output']>>;
  name?: Maybe<Scalars['String']['output']>;
  organizationId?: Maybe<Scalars['ID']['output']>;
  updatedAt?: Maybe<Scalars['String']['output']>;
};

export type DataExportTemplateInput = {
  activityTemplateId?: InputMaybe<Scalars['ID']['input']>;
  columnMappings: Array<DataImportExportColumnMappingInput>;
  exportType: DataExportType;
  filterConditions?: InputMaybe<Array<InputMaybe<ConditionItemInput>>>;
  historical?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  memberGroupIdFilter?: InputMaybe<Array<Scalars['ID']['input']>>;
  name: Scalars['String']['input'];
  organizationId: Scalars['ID']['input'];
};

export type DataExportTemplateResponse = {
  __typename?: 'DataExportTemplateResponse';
  data?: Maybe<DataExportTemplate>;
  message?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

export type DataExportTemplatesResponse = {
  __typename?: 'DataExportTemplatesResponse';
  data?: Maybe<PaginatedDataExportTemplates>;
  message?: Maybe<Scalars['String']['output']>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

export const DataExportType = {
  Activity: 'Activity',
  Member: 'Member'
} as const;

export type DataExportType = typeof DataExportType[keyof typeof DataExportType];
export type DataIdWithAnswerType = {
  __typename?: 'DataIdWithAnswerType';
  answerOptions: Array<Scalars['String']['output']>;
  answerType: AnswerType;
  dataId: Scalars['String']['output'];
  dataPointTemplateId: Scalars['ID']['output'];
  defaultOptions: Array<Scalars['String']['output']>;
  historicalOptions?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  questionTitle: Scalars['String']['output'];
};

export type DataIdWithAnswerTypeResponse = {
  __typename?: 'DataIdWithAnswerTypeResponse';
  data?: Maybe<Array<DataIdWithAnswerType>>;
  message?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

export type DataImport = {
  __typename?: 'DataImport';
  _id?: Maybe<Scalars['ID']['output']>;
  activitiesCreated?: Maybe<Scalars['Int']['output']>;
  createdAt?: Maybe<Scalars['String']['output']>;
  dataImportTemplate?: Maybe<DataImportTemplate>;
  dataImportTemplateId?: Maybe<Scalars['ID']['output']>;
  dataPointsCreated?: Maybe<Scalars['Int']['output']>;
  errorFile?: Maybe<PsFile>;
  errorFileId?: Maybe<Scalars['ID']['output']>;
  errorMessage?: Maybe<Scalars['String']['output']>;
  file?: Maybe<PsFile>;
  fileId?: Maybe<Scalars['ID']['output']>;
  membersCreated?: Maybe<Scalars['Int']['output']>;
  membersUpdated?: Maybe<Scalars['Int']['output']>;
  status?: Maybe<DataImportStatus>;
  updatedAt?: Maybe<Scalars['String']['output']>;
};

export type DataImportExportColumnMapping = {
  activityColumnType?: InputMaybe<DataImportExportColumnMappingActivityColumnType>;
  activityQuestionId?: InputMaybe<Scalars['String']['input']>;
  columnName: Scalars['String']['input'];
  columnType?: InputMaybe<DataImportExportColumnMappingColumnType>;
  consentTemplateId?: InputMaybe<Scalars['String']['input']>;
  constantValue?: InputMaybe<Scalars['String']['input']>;
  dataIdColumnValueType?: InputMaybe<DataImportExportColumnMappingDataIdColumnValueType>;
  dataPointTemplate?: InputMaybe<DataPointTemplate>;
  dataPointTemplateId?: InputMaybe<Scalars['ID']['input']>;
  disabled?: InputMaybe<Scalars['Boolean']['input']>;
  exportDetailsColumnType?: InputMaybe<DataImportExportColumnMappingExportDetailsColumnType>;
  memberDetailsColumnType?: InputMaybe<DataImportExportColumnMappingMemberDetailsColumnType>;
  organizationalColumnType?: InputMaybe<DataImportExportColumnMappingOrganizationalColumnType>;
  question?: InputMaybe<Question>;
  questionId?: InputMaybe<Scalars['ID']['input']>;
};

export const DataImportExportColumnMappingActivityColumnType = {
  ActivityId: 'ActivityID',
  AssignedUser: 'AssignedUser',
  AssignedUserName: 'AssignedUserName',
  Billable: 'Billable',
  Date: 'Date',
  DiagnosisCodes: 'DiagnosisCodes',
  Duration: 'Duration',
  EndDate: 'EndDate',
  InsuranceName: 'InsuranceName',
  Modifiers: 'Modifiers',
  PlaceOfService: 'PlaceOfService',
  ProcedureCode: 'ProcedureCode',
  Question: 'Question',
  Status: 'Status'
} as const;

export type DataImportExportColumnMappingActivityColumnType = typeof DataImportExportColumnMappingActivityColumnType[keyof typeof DataImportExportColumnMappingActivityColumnType];
export const DataImportExportColumnMappingColumnType = {
  Activity: 'Activity',
  Consent: 'Consent',
  Constant: 'Constant',
  DataId: 'DataID',
  ExportDetails: 'ExportDetails',
  MemberDetails: 'MemberDetails',
  MemberId: 'MemberID',
  Note: 'Note',
  Organizational: 'Organizational',
  Question: 'Question',
  RandomId: 'RandomID'
} as const;

export type DataImportExportColumnMappingColumnType = typeof DataImportExportColumnMappingColumnType[keyof typeof DataImportExportColumnMappingColumnType];
export const DataImportExportColumnMappingDataIdColumnValueType = {
  Average: 'Average',
  Sum: 'Sum',
  Value: 'Value'
} as const;

export type DataImportExportColumnMappingDataIdColumnValueType = typeof DataImportExportColumnMappingDataIdColumnValueType[keyof typeof DataImportExportColumnMappingDataIdColumnValueType];
export const DataImportExportColumnMappingExportDetailsColumnType = {
  ExportTime: 'ExportTime',
  RangeFrom: 'RangeFrom',
  RangeTo: 'RangeTo'
} as const;

export type DataImportExportColumnMappingExportDetailsColumnType = typeof DataImportExportColumnMappingExportDetailsColumnType[keyof typeof DataImportExportColumnMappingExportDetailsColumnType];
export type DataImportExportColumnMappingInput = {
  activityColumnType?: InputMaybe<DataImportExportColumnMappingActivityColumnType>;
  activityQuestionId?: InputMaybe<Scalars['String']['input']>;
  columnName: Scalars['String']['input'];
  columnType?: InputMaybe<DataImportExportColumnMappingColumnType>;
  consentTemplateId?: InputMaybe<Scalars['String']['input']>;
  constantValue?: InputMaybe<Scalars['String']['input']>;
  dataIdColumnValueType?: InputMaybe<DataImportExportColumnMappingDataIdColumnValueType>;
  dataPointTemplateId?: InputMaybe<Scalars['ID']['input']>;
  disabled?: InputMaybe<Scalars['Boolean']['input']>;
  exportDetailsColumnType?: InputMaybe<DataImportExportColumnMappingExportDetailsColumnType>;
  memberDetailsColumnType?: InputMaybe<DataImportExportColumnMappingMemberDetailsColumnType>;
  organizationalColumnType?: InputMaybe<DataImportExportColumnMappingOrganizationalColumnType>;
  questionId?: InputMaybe<Scalars['ID']['input']>;
};

export const DataImportExportColumnMappingMemberDetailsColumnType = {
  AssignedUserEmail: 'AssignedUserEmail',
  AssignedUserName: 'AssignedUserName'
} as const;

export type DataImportExportColumnMappingMemberDetailsColumnType = typeof DataImportExportColumnMappingMemberDetailsColumnType[keyof typeof DataImportExportColumnMappingMemberDetailsColumnType];
export const DataImportExportColumnMappingOrganizationalColumnType = {
  Name: 'Name',
  RenderingProviderNpi: 'RenderingProviderNPI',
  RenderingProviderName: 'RenderingProviderName',
  RenderingProviderTin: 'RenderingProviderTIN'
} as const;

export type DataImportExportColumnMappingOrganizationalColumnType = typeof DataImportExportColumnMappingOrganizationalColumnType[keyof typeof DataImportExportColumnMappingOrganizationalColumnType];
export type DataImportInput = {
  dataImportTemplateId: Scalars['ID']['input'];
  fileId: Scalars['ID']['input'];
  organizationId: Scalars['ID']['input'];
};

export type DataImportResponse = {
  __typename?: 'DataImportResponse';
  data?: Maybe<DataImport>;
  message?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

export const DataImportStatus = {
  Completed: 'Completed',
  Error: 'Error',
  Pending: 'Pending'
} as const;

export type DataImportStatus = typeof DataImportStatus[keyof typeof DataImportStatus];
export type DataImportTemplate = {
  __typename?: 'DataImportTemplate';
  _id: Scalars['ID']['output'];
  accessType?: Maybe<AccessType>;
  activityTemplateId?: Maybe<Scalars['ID']['output']>;
  columnMappings?: Maybe<Array<DataImportExportColumnMapping>>;
  createdAt?: Maybe<Scalars['String']['output']>;
  dataImportType?: Maybe<DataImportType>;
  imports?: Maybe<Array<Maybe<DataImport>>>;
  name?: Maybe<Scalars['String']['output']>;
  organizationId?: Maybe<Scalars['ID']['output']>;
  uniqueMemberIdentifier?: Maybe<DataImportUniqueMemberIdentifier>;
  updatedAt?: Maybe<Scalars['String']['output']>;
};

export type DataImportTemplateInput = {
  activityTemplateId?: InputMaybe<Scalars['ID']['input']>;
  columnMappings: Array<DataImportExportColumnMappingInput>;
  dataImportType?: InputMaybe<DataImportType>;
  id?: InputMaybe<Scalars['ID']['input']>;
  name: Scalars['String']['input'];
  organizationId: Scalars['ID']['input'];
  uniqueMemberIdentifier: DataImportUniqueMemberIdentifier;
};

export type DataImportTemplateResponse = {
  __typename?: 'DataImportTemplateResponse';
  data?: Maybe<DataImportTemplate>;
  message?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

export type DataImportTemplatesResponse = {
  __typename?: 'DataImportTemplatesResponse';
  data?: Maybe<PaginatedDataImportTemplates>;
  message?: Maybe<Scalars['String']['output']>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

export const DataImportType = {
  Activity: 'Activity',
  Member: 'Member'
} as const;

export type DataImportType = typeof DataImportType[keyof typeof DataImportType];
export const DataImportUniqueMemberIdentifier = {
  NameCin: 'NameCin',
  NameDob: 'NameDob',
  NamePhone: 'NamePhone'
} as const;

export type DataImportUniqueMemberIdentifier = typeof DataImportUniqueMemberIdentifier[keyof typeof DataImportUniqueMemberIdentifier];
export type DataPoint = {
  __typename?: 'DataPoint';
  _id: Scalars['ID']['output'];
  activityId?: Maybe<Scalars['ID']['output']>;
  activityTemplateTitle?: Maybe<Scalars['String']['output']>;
  answer: Scalars['String']['output'];
  createdAt: Scalars['String']['output'];
  dataId: Scalars['String']['output'];
  dataPointTemplateId: Scalars['ID']['output'];
  dataPointTemplateTitle?: Maybe<Scalars['String']['output']>;
  episodeOfCareId?: Maybe<Scalars['String']['output']>;
  memberId: Scalars['ID']['output'];
  organizationId: Scalars['ID']['output'];
  questionId?: Maybe<Scalars['ID']['output']>;
  questionText?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['String']['output'];
};

export type DataPointStats = {
  __typename?: 'DataPointStats';
  data: Array<GranularData>;
  dataIdType: Scalars['String']['output'];
  groupByTimeUnit: GroupTimeUnit;
};

export type DataPointStatsResponse = {
  __typename?: 'DataPointStatsResponse';
  message?: Maybe<Scalars['String']['output']>;
  stats?: Maybe<DataPointStats>;
  success: Scalars['Boolean']['output'];
};

export type DataPointTemplate = {
  __typename?: 'DataPointTemplate';
  _count?: Maybe<DataPointTemplateRelationCount>;
  _id: Scalars['ID']['output'];
  accessType?: Maybe<AccessType>;
  conditionItems?: Maybe<Array<Maybe<ConditionItem>>>;
  consentTemplates?: Maybe<Array<Maybe<ConsentTemplate>>>;
  createdAt: Scalars['String']['output'];
  dataId: Scalars['String']['output'];
  dataPoints?: Maybe<Array<Maybe<DataPoint>>>;
  dataType: AnswerType;
  defaultOptions?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  description?: Maybe<Scalars['String']['output']>;
  enrolledCarePathwayTemplateIds?: Maybe<Array<Maybe<Scalars['ID']['output']>>>;
  historicalOptions?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  isActive?: Maybe<Scalars['Boolean']['output']>;
  lockingReferences?: Maybe<Array<Maybe<LockingReference>>>;
  organization?: Maybe<Organization>;
  organizationId: Scalars['ID']['output'];
  questions?: Maybe<Array<Maybe<Question>>>;
  successfulCarePathwayTemplateIds?: Maybe<Array<Maybe<Scalars['ID']['output']>>>;
  tags?: Maybe<Array<Maybe<TaggedEntity>>>;
  title: Scalars['String']['output'];
  unsuccessfulCarePathwayTemplateIds?: Maybe<Array<Maybe<Scalars['ID']['output']>>>;
  updatedAt: Scalars['String']['output'];
};

export type DataPointTemplateFilter = {
  condition: FilterCondition;
  filter: DataPointTemplateFilterProp;
  filterValue: Scalars['String']['input'];
};

export const DataPointTemplateFilterProp = {
  Tags: 'tags'
} as const;

export type DataPointTemplateFilterProp = typeof DataPointTemplateFilterProp[keyof typeof DataPointTemplateFilterProp];
export type DataPointTemplateInput = {
  _id?: InputMaybe<Scalars['ID']['input']>;
  dataId: Scalars['String']['input'];
  dataType: AnswerType;
  defaultOptions?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  description?: InputMaybe<Scalars['String']['input']>;
  historicalOptions?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  organizationId: Scalars['ID']['input'];
  tagNames?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title: Scalars['String']['input'];
};

export type DataPointTemplateRelationCount = {
  __typename?: 'DataPointTemplateRelationCount';
  dataPoints?: Maybe<Scalars['Int']['output']>;
  questions?: Maybe<Scalars['Int']['output']>;
};

export type DataResponse = {
  __typename?: 'DataResponse';
  data?: Maybe<DataPoint>;
  message?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

export type DefaultBillingDetails = {
  __typename?: 'DefaultBillingDetails';
  diagnosisCodes?: Maybe<Array<Scalars['String']['output']>>;
  modifier?: Maybe<Scalars['String']['output']>;
  placeOfService?: Maybe<Scalars['String']['output']>;
  procedure?: Maybe<Scalars['String']['output']>;
};

export type DefaultBillingDetailsInput = {
  diagnosisCodes?: InputMaybe<Array<Scalars['String']['input']>>;
  modifier?: InputMaybe<Scalars['String']['input']>;
  placeOfService?: InputMaybe<Scalars['String']['input']>;
  procedure?: InputMaybe<Scalars['String']['input']>;
};

export type DefaultResponse = {
  __typename?: 'DefaultResponse';
  message?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

export type DeleteBillInput = {
  id: Scalars['ID']['input'];
  organizationId: Scalars['ID']['input'];
};

export type DeleteDataPointTemplateInput = {
  _id: Scalars['ID']['input'];
  organizationId: Scalars['String']['input'];
};

export type DeleteEhrNoteTemplateInput = {
  id: Scalars['ID']['input'];
  organizationId: Scalars['ID']['input'];
};

export type DeleteEmailTemplateInput = {
  _id: Scalars['ID']['input'];
  organizationId: Scalars['ID']['input'];
};

export type DeleteGoalInput = {
  goalId: Scalars['ID']['input'];
  memberId: Scalars['ID']['input'];
  organizationId: Scalars['ID']['input'];
};

export type DeleteGoalTemplateInput = {
  goalTemplateId: Scalars['ID']['input'];
  organizationId: Scalars['String']['input'];
};

export type DeleteGroupInput = {
  forceDelete?: InputMaybe<Scalars['Boolean']['input']>;
  groupId: Scalars['ID']['input'];
  organizationId: Scalars['ID']['input'];
};

export type DeleteQuestionInput = {
  _id: Scalars['ID']['input'];
  organizationId: Scalars['String']['input'];
};

export type DeleteRoleInput = {
  organizationId: Scalars['ID']['input'];
  roleId: Scalars['ID']['input'];
};

export type DeleteUserGroupInput = {
  _id: Scalars['ID']['input'];
  organizationId: Scalars['ID']['input'];
};

export type DemographicsCategory = {
  __typename?: 'DemographicsCategory';
  AgeGroup: Array<Maybe<DemographicsCategoryData>>;
  Ethnicity: Array<Maybe<DemographicsCategoryData>>;
  Gender: Array<Maybe<DemographicsCategoryData>>;
  MedicaidStatus: Array<Maybe<DemographicsCategoryData>>;
  MedicareStatus: Array<Maybe<DemographicsCategoryData>>;
  Pronouns: Array<Maybe<DemographicsCategoryData>>;
  Race: Array<Maybe<DemographicsCategoryData>>;
  Sex: Array<Maybe<DemographicsCategoryData>>;
  SexualOrientation: Array<Maybe<DemographicsCategoryData>>;
  SpokenLanguage: Array<Maybe<DemographicsCategoryData>>;
};

export type DemographicsCategoryData = {
  __typename?: 'DemographicsCategoryData';
  count: Scalars['Int']['output'];
  value: Scalars['String']['output'];
};

export type DemographicsStats = {
  __typename?: 'DemographicsStats';
  data: DemographicsCategory;
};

export type DemographicsStatsResponse = {
  __typename?: 'DemographicsStatsResponse';
  message?: Maybe<Scalars['String']['output']>;
  stats?: Maybe<DemographicsStats>;
  success: Scalars['Boolean']['output'];
};

export type DiagnosisCode = {
  __typename?: 'DiagnosisCode';
  code: Scalars['String']['output'];
  description?: Maybe<Scalars['String']['output']>;
};

export type DiagnosisCodeInput = {
  code: Scalars['String']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
};

export const Disposition = {
  BusyLine: 'BusyLine',
  CallCompleted: 'CallCompleted',
  CallDropped: 'CallDropped',
  DoNotCall: 'DoNotCall',
  IncorrectOrDisconnectedNumber: 'IncorrectOrDisconnectedNumber',
  LeftVoicemail: 'LeftVoicemail',
  NoAnswer: 'NoAnswer',
  NotInterested: 'NotInterested',
  RequestedCallBack: 'RequestedCallBack',
  UnableToLeaveVoicemail: 'UnableToLeaveVoicemail'
} as const;

export type Disposition = typeof Disposition[keyof typeof Disposition];
export type Domain = {
  __typename?: 'Domain';
  _id: Scalars['ID']['output'];
  accessType?: Maybe<AccessType>;
  createdAt?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  goals?: Maybe<Array<Maybe<Goal>>>;
  memberDomainDescriptions?: Maybe<Array<Maybe<MemberDomainDescription>>>;
  organizationId?: Maybe<Scalars['ID']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['String']['output']>;
};

export type DomainDescriptionInput = {
  description: Scalars['String']['input'];
  domainId: Scalars['ID']['input'];
  memberId: Scalars['ID']['input'];
  organizationId: Scalars['ID']['input'];
};

export type DomainInput = {
  _id?: InputMaybe<Scalars['ID']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  organizationId: Scalars['ID']['input'];
  title?: InputMaybe<Scalars['String']['input']>;
};

export type DomainResponse = {
  __typename?: 'DomainResponse';
  data?: Maybe<Domain>;
  message?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

export type DomainsResponse = {
  __typename?: 'DomainsResponse';
  data?: Maybe<Array<Maybe<Domain>>>;
  message?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

export type DroppedMember = {
  __typename?: 'DroppedMember';
  _id: Scalars['ID']['output'];
  firstName: Scalars['String']['output'];
  groups?: Maybe<Scalars['String']['output']>;
  lastActivity?: Maybe<Scalars['String']['output']>;
  lastName: Scalars['String']['output'];
  nickName?: Maybe<Scalars['String']['output']>;
  phonetic?: Maybe<Scalars['String']['output']>;
  status: MemberStatus;
  users?: Maybe<Scalars['String']['output']>;
};

export type DuplicateActivityTemplateInput = {
  activityTemplateId: Scalars['ID']['input'];
  forceDuplicate?: InputMaybe<Scalars['Boolean']['input']>;
  srcOrganizationId: Scalars['ID']['input'];
  targetOrganizationId: Scalars['ID']['input'];
};

export type DuplicateCarePathwayInput = {
  carePathwayTemplateId: Scalars['ID']['input'];
  forceDuplicate?: InputMaybe<Scalars['Boolean']['input']>;
  srcOrganizationId: Scalars['ID']['input'];
  targetOrganizationId: Scalars['ID']['input'];
};

export type DuplicateDomainInput = {
  domainId: Scalars['ID']['input'];
  forceDuplicate?: InputMaybe<Scalars['Boolean']['input']>;
  targetOrganizationId: Scalars['ID']['input'];
};

export type DuplicateEmailTemplateInput = {
  emailTemplateId: Scalars['ID']['input'];
  forceDuplicate?: InputMaybe<Scalars['Boolean']['input']>;
  srcOrganizationId: Scalars['ID']['input'];
  targetOrganizationId: Scalars['ID']['input'];
};

export type DuplicateFlowTemplateInput = {
  flowTemplateFamilyId: Scalars['ID']['input'];
  forceDuplicate?: InputMaybe<Scalars['Boolean']['input']>;
  srcOrganizationId: Scalars['ID']['input'];
  targetOrganizationId: Scalars['ID']['input'];
};

export type DuplicateGoalInput = {
  forceDuplicate?: InputMaybe<Scalars['Boolean']['input']>;
  goalTemplateId: Scalars['ID']['input'];
  srcOrganizationId: Scalars['ID']['input'];
  targetOrganizationId: Scalars['ID']['input'];
};

export type DuplicateQuestionsInput = {
  forceDuplicate?: InputMaybe<Scalars['Boolean']['input']>;
  questionIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  srcOrganizationId: Scalars['ID']['input'];
  targetOrganizationId: Scalars['ID']['input'];
};

export type DuplicateRecommendationInput = {
  forceDuplicate?: InputMaybe<Scalars['Boolean']['input']>;
  recommendationId: Scalars['ID']['input'];
  srcOrganizationId: Scalars['ID']['input'];
  targetOrganizationId: Scalars['ID']['input'];
};

export type EhrNoteTemplate = {
  __typename?: 'EHRNoteTemplate';
  contentType: EhrNoteTemplateContentType;
  createdAt: Scalars['String']['output'];
  document?: Maybe<Scalars['String']['output']>;
  documentType: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  lockingReferences: Array<LockingReference>;
  name: Scalars['String']['output'];
  updatedAt: Scalars['String']['output'];
};

export const EhrNoteTemplateContentType = {
  SummaryPdf: 'SummaryPDF',
  Text: 'Text'
} as const;

export type EhrNoteTemplateContentType = typeof EhrNoteTemplateContentType[keyof typeof EhrNoteTemplateContentType];
export const EducationLevel = {
  AssociateDegree: 'AssociateDegree',
  BachelorDegree: 'BachelorDegree',
  DoctoralDegree: 'DoctoralDegree',
  HighSchoolEquivalent: 'HighSchoolEquivalent',
  MasterDegree: 'MasterDegree',
  NoEducation: 'NoEducation',
  SomeCollegeNoDegree: 'SomeCollegeNoDegree'
} as const;

export type EducationLevel = typeof EducationLevel[keyof typeof EducationLevel];
export type EmailTemplate = {
  __typename?: 'EmailTemplate';
  _id: Scalars['ID']['output'];
  body: Scalars['String']['output'];
  createdAt?: Maybe<Scalars['String']['output']>;
  lockingReferences: Array<LockingReference>;
  organizationId: Scalars['ID']['output'];
  recipientAddress?: Maybe<Scalars['String']['output']>;
  subject: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['String']['output']>;
};

export type EmailTemplateIdAndOrgInput = {
  _id: Scalars['ID']['input'];
  organizationId: Scalars['ID']['input'];
};

export type EmailTemplateInput = {
  body: Scalars['String']['input'];
  organizationId: Scalars['ID']['input'];
  recipientAddress?: InputMaybe<Scalars['String']['input']>;
  sendTo: SendEmailTemplateTo;
  subject: Scalars['String']['input'];
};

export type EmailTemplateMeta = {
  __typename?: 'EmailTemplateMeta';
  _id: Scalars['ID']['output'];
  createdAt?: Maybe<Scalars['String']['output']>;
  lockingReferences: Array<LockingReference>;
  organizationId: Scalars['ID']['output'];
  recipientAddress?: Maybe<Scalars['String']['output']>;
  subject: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['String']['output']>;
};

export type EmailTemplateMetaWithAccess = {
  __typename?: 'EmailTemplateMetaWithAccess';
  accessType: AccessType;
  emailTemplate: EmailTemplateMeta;
};

export type EmailTemplateWithAccess = {
  __typename?: 'EmailTemplateWithAccess';
  accessType: AccessType;
  emailTemplate: EmailTemplate;
};

export const EmploymentStatus = {
  Disabled: 'Disabled',
  EmployedFullTime: 'EmployedFullTime',
  EmployedPartTime: 'EmployedPartTime',
  Retired: 'Retired',
  Unemployed: 'Unemployed'
} as const;

export type EmploymentStatus = typeof EmploymentStatus[keyof typeof EmploymentStatus];
export const EnrollmentStatus = {
  ApplicationPending: 'ApplicationPending',
  Enrolled: 'Enrolled',
  NotEnrolled: 'NotEnrolled',
  Unknown: 'Unknown'
} as const;

export type EnrollmentStatus = typeof EnrollmentStatus[keyof typeof EnrollmentStatus];
export const EntityType = {
  Calculation: 'Calculation',
  DataPointTemplate: 'DataPointTemplate',
  PsFile: 'PSFile',
  Question: 'Question'
} as const;

export type EntityType = typeof EntityType[keyof typeof EntityType];
export type EpisodeOfCare = {
  __typename?: 'EpisodeOfCare';
  _id: Scalars['ID']['output'];
  activities?: Maybe<Array<Maybe<Activity>>>;
  carePathways?: Maybe<Array<Maybe<CarePathway>>>;
  consents?: Maybe<Array<Maybe<Consent>>>;
  dataId?: Maybe<Scalars['String']['output']>;
  endDate?: Maybe<Scalars['String']['output']>;
  files?: Maybe<Array<Maybe<PsFile>>>;
  goals?: Maybe<Array<Maybe<Goal>>>;
  member?: Maybe<Member>;
  memberId: Scalars['ID']['output'];
  organizationId?: Maybe<Scalars['ID']['output']>;
  reasonForClosure?: Maybe<Scalars['String']['output']>;
  recommendations?: Maybe<Array<Maybe<Recommendation>>>;
  referrals?: Maybe<Array<Maybe<Referral>>>;
  startDate?: Maybe<Scalars['String']['output']>;
};

export type EpisodeOfCareInput = {
  dataId?: InputMaybe<Scalars['String']['input']>;
  endDate?: InputMaybe<Scalars['String']['input']>;
  memberId: Scalars['ID']['input'];
  organizationId: Scalars['ID']['input'];
  reasonForClosure?: InputMaybe<Scalars['String']['input']>;
  startDate?: InputMaybe<Scalars['String']['input']>;
};

export type EpisodeOfCareResponse = {
  __typename?: 'EpisodeOfCareResponse';
  data?: Maybe<EpisodeOfCare>;
  message?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

export type EpisodesOfCareResponse = {
  __typename?: 'EpisodesOfCareResponse';
  data?: Maybe<Array<EpisodeOfCare>>;
  message?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
  total?: Maybe<Scalars['Int']['output']>;
};

export const Ethnicity = {
  Hispanic: 'Hispanic',
  NonHispanic: 'NonHispanic',
  NotListed: 'NotListed'
} as const;

export type Ethnicity = typeof Ethnicity[keyof typeof Ethnicity];
export type Event = {
  __typename?: 'Event';
  actionData?: Maybe<ActionData>;
  activityId: Scalars['ID']['output'];
  description?: Maybe<Scalars['String']['output']>;
  endsAt: Scalars['String']['output'];
  eventTemplateId?: Maybe<Scalars['ID']['output']>;
  id: Scalars['ID']['output'];
  members: Array<Maybe<EventMember>>;
  organizationId: Scalars['ID']['output'];
  questions: Array<Question>;
  startsAt: Scalars['String']['output'];
  taskId: Scalars['Int']['output'];
  title: Scalars['String']['output'];
};

export type EventMember = {
  __typename?: 'EventMember';
  attended: Scalars['Boolean']['output'];
  memberDataPoints?: Maybe<Array<Maybe<DataPoint>>>;
  memberDisplayName: Scalars['String']['output'];
  memberId: Scalars['ID']['output'];
};

export type EventTaskInput = {
  endsAt: Scalars['String']['input'];
  startsAt: Scalars['String']['input'];
};

export type EventTemplate = {
  __typename?: 'EventTemplate';
  _id: Scalars['ID']['output'];
  accessType?: Maybe<AccessType>;
  actionData: ActionData;
  createdAt?: Maybe<Scalars['String']['output']>;
  description: Scalars['String']['output'];
  lockingReferences: Array<LockingReference>;
  organizationId: Scalars['ID']['output'];
  questions: Array<Question>;
  title: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['String']['output']>;
};

export type EventTemplateInput = {
  actionData?: InputMaybe<ActionDataInput>;
  description: Scalars['String']['input'];
  organizationId: Scalars['ID']['input'];
  questionIds: Array<Scalars['ID']['input']>;
  title: Scalars['String']['input'];
};

export type ExecuteActionInput = {
  activities?: InputMaybe<Array<InputMaybe<ScheduleActivityInput>>>;
  activityTemplateId?: InputMaybe<Scalars['ID']['input']>;
  activityTime?: InputMaybe<Scalars['String']['input']>;
  carePathwayTemplateId?: InputMaybe<Scalars['ID']['input']>;
  userId?: InputMaybe<Scalars['ID']['input']>;
};

export const ExecuteActionInputType = {
  ScheduleActivityInput: 'ScheduleActivityInput'
} as const;

export type ExecuteActionInputType = typeof ExecuteActionInputType[keyof typeof ExecuteActionInputType];
export type ExecuteActionInputUnion = CreateConsentData | CreateGoalData | CreateReferralData | ScheduleActivityData | StartCarePathwayData | UpdateCarePathwayStatusData | UpdateGoalData;

export type ExportDataPointTemplateDataInput = {
  conditions: Array<ConditionItemInput>;
  dataPointTemplateIds: Array<Scalars['String']['input']>;
  memberGroupIds: Array<Scalars['String']['input']>;
  organizationId: Scalars['String']['input'];
  userId: Scalars['String']['input'];
};

export type ExportDataPointTemplateDataRequest = {
  __typename?: 'ExportDataPointTemplateDataRequest';
  conditions: Array<ConditionItem>;
  dataPointTemplateIds: Array<Scalars['String']['output']>;
  memberGroupIds: Array<Scalars['String']['output']>;
  organizationId: Scalars['String']['output'];
  userId: Scalars['String']['output'];
};

export type ExportFlowTemplateInput = {
  familyId: Scalars['ID']['input'];
  organizationId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
};

export type ExternalContact = {
  __typename?: 'ExternalContact';
  _id: Scalars['ID']['output'];
  email?: Maybe<Scalars['String']['output']>;
  externalResourceId: Scalars['ID']['output'];
  externalResourceName?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
};

export type ExternalResource = {
  __typename?: 'ExternalResource';
  _id: Scalars['ID']['output'];
  accessType?: Maybe<AccessType>;
  address?: Maybe<Scalars['String']['output']>;
  address2?: Maybe<Scalars['String']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  contacts: Array<ExternalResourceContact>;
  country?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['String']['output'];
  isActive: Scalars['Boolean']['output'];
  lockingReferences: Array<LockingReference>;
  longDescription?: Maybe<Scalars['String']['output']>;
  notes: Array<Note>;
  organizationId: Scalars['ID']['output'];
  shortDescription?: Maybe<Scalars['String']['output']>;
  state?: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
  updatedAt: Scalars['String']['output'];
  zip?: Maybe<Scalars['String']['output']>;
};

export type ExternalResourceAndReferralTemplate = {
  __typename?: 'ExternalResourceAndReferralTemplate';
  externalResource: Scalars['String']['output'];
  referralTemplates: Array<ReferralTemplateStatusCount>;
};

export type ExternalResourceContact = {
  __typename?: 'ExternalResourceContact';
  _id: Scalars['ID']['output'];
  accessType?: Maybe<AccessType>;
  createdAt: Scalars['String']['output'];
  description?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  externalResourceId: Scalars['ID']['output'];
  isActive: Scalars['Boolean']['output'];
  lockingReferences?: Maybe<Array<LockingReference>>;
  name: Scalars['String']['output'];
  notes: Array<Note>;
  phone?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['String']['output'];
};

export type ExternalResourceContactInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  externalResourceId: Scalars['ID']['input'];
  name: Scalars['String']['input'];
  organizationId: Scalars['ID']['input'];
  phone?: InputMaybe<Scalars['String']['input']>;
};

export type ExternalResourceIdAndOrgInput = {
  _id: Scalars['ID']['input'];
  organizationId: Scalars['ID']['input'];
};

export type ExternalResourceInput = {
  address?: InputMaybe<Scalars['String']['input']>;
  address2?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  longDescription?: InputMaybe<Scalars['String']['input']>;
  organizationId: Scalars['ID']['input'];
  shortDescription?: InputMaybe<Scalars['String']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
  title: Scalars['String']['input'];
  zip?: InputMaybe<Scalars['String']['input']>;
};

export type ExternalResourcesStats = {
  __typename?: 'ExternalResourcesStats';
  data: Array<ResourceGranularData>;
  externalResourcesAndReferralTemplates: Array<ExternalResourceAndReferralTemplate>;
  groupByTimeUnit: GroupTimeUnit;
};

export type ExternalResourcesStatsResponse = {
  __typename?: 'ExternalResourcesStatsResponse';
  message?: Maybe<Scalars['String']['output']>;
  stats?: Maybe<ExternalResourcesStats>;
  success: Scalars['Boolean']['output'];
};

export const FamilyLinkType = {
  Child: 'Child',
  Cousin: 'Cousin',
  GrandChild: 'GrandChild',
  GrandParent: 'GrandParent',
  NieceNephew: 'NieceNephew',
  Other: 'Other',
  Parent: 'Parent',
  Sibling: 'Sibling',
  Spouse: 'Spouse',
  UncleAunt: 'UncleAunt'
} as const;

export type FamilyLinkType = typeof FamilyLinkType[keyof typeof FamilyLinkType];
export type FamilyMember = {
  __typename?: 'FamilyMember';
  familyLinkType: FamilyLinkType;
  familyMemberName: Scalars['String']['output'];
  memberId: Scalars['String']['output'];
};

export type FamilyMemberInput = {
  familyLinkType: FamilyLinkType;
  memberId: Scalars['String']['input'];
};

export const FilterCondition = {
  Equals: 'Equals',
  Gt: 'Gt',
  Gte: 'Gte',
  Lt: 'Lt',
  Lte: 'Lte'
} as const;

export type FilterCondition = typeof FilterCondition[keyof typeof FilterCondition];
export type Flow = {
  __typename?: 'Flow';
  _id: Scalars['ID']['output'];
  activityId?: Maybe<Scalars['ID']['output']>;
  answers?: Maybe<Array<Answer>>;
  completed: Scalars['Boolean']['output'];
  createdAt: Scalars['String']['output'];
  currentStage?: Maybe<JourneyStage>;
  currentStep?: Maybe<Scalars['String']['output']>;
  flowTemplateFamilyId: Scalars['ID']['output'];
  flowTemplateId: Scalars['ID']['output'];
  flowType: FlowType;
  memberId: Scalars['ID']['output'];
  updatedAt: Scalars['String']['output'];
};

export type FlowStep = {
  __typename?: 'FlowStep';
  actions?: Maybe<Array<Action>>;
  chapter?: Maybe<Scalars['String']['output']>;
  next?: Maybe<Array<NextQuestion>>;
  nodePosition: XyCoord;
  question: FlowStepQuestionMeta;
  stepId: Scalars['String']['output'];
};

export type FlowStepInput = {
  actions?: InputMaybe<Array<ActionInput>>;
  chapter?: InputMaybe<Scalars['String']['input']>;
  next?: InputMaybe<Array<NextQuestionInput>>;
  nodePosition: XyCoordInput;
  questionId: Scalars['ID']['input'];
  stepId: Scalars['String']['input'];
};

export type FlowStepQuestionMeta = {
  __typename?: 'FlowStepQuestionMeta';
  _id: Scalars['ID']['output'];
  answerOptions?: Maybe<Array<Scalars['String']['output']>>;
  answerType: AnswerType;
  createdAt: Scalars['String']['output'];
  dataId: Scalars['String']['output'];
  lockingReferences: Array<LockingReference>;
  organizationId: Scalars['ID']['output'];
  questionText: Scalars['String']['output'];
  questionTitle: Scalars['String']['output'];
  questionType: QuestionType;
  tags?: Maybe<Array<Maybe<TaggedEntity>>>;
  updatedAt: Scalars["String"]["output"];
};

export type FlowTemplate = {
  __typename?: 'FlowTemplate';
  _id: Scalars['ID']['output'];
  chapters?: Maybe<Array<Scalars['String']['output']>>;
  createdAt: Scalars['String']['output'];
  entryStepId: Scalars['String']['output'];
  familyId: Scalars['ID']['output'];
  flowType: FlowType;
  isActive: Scalars['Boolean']['output'];
  lockedByUse: Scalars['Boolean']['output'];
  lockingReferences: Array<LockingReference>;
  organizationId: Scalars['ID']['output'];
  stagePositions: JourneyStagePositions;
  startNodePosition: XyCoord;
  steps?: Maybe<Array<FlowStep>>;
  title: Scalars['String']['output'];
  updatedAt: Scalars['String']['output'];
  version: Scalars['Int']['output'];
};

export type FlowTemplateFamily = {
  __typename?: 'FlowTemplateFamily';
  currentVersion: FlowTemplate;
  priorVersions: Array<FlowTemplate>;
};

export type FlowTemplateFamilyIdAndOrgInput = {
  familyId: Scalars['ID']['input'];
  organizationId: Scalars['ID']['input'];
};

export type FlowTemplateFamilyWithAccess = {
  __typename?: 'FlowTemplateFamilyWithAccess';
  accessType: AccessType;
  flowTemplateFamily: FlowTemplateFamily;
};

export type FlowTemplateIdAndOrgInput = {
  flowTemplateId: Scalars['ID']['input'];
  organizationId: Scalars['ID']['input'];
};

export type FlowTemplateWithAccess = {
  __typename?: 'FlowTemplateWithAccess';
  accessType: AccessType;
  flowTemplate: FlowTemplate;
};

export const FlowType = {
  Assessment: 'Assessment',
  Journey: 'Journey',
  Script: 'Script'
} as const;

export type FlowType = typeof FlowType[keyof typeof FlowType];
export type Gcbau1TemplateContext = {
  __typename?: 'GCBAU1TemplateContext';
  goalId: Scalars['String']['output'];
  initiatingUserId?: Maybe<Scalars['String']['output']>;
  memberId?: Maybe<Scalars['String']['output']>;
};

export type Gnc1TemplateContext = {
  __typename?: 'GNC1TemplateContext';
  goalId: Scalars['String']['output'];
  initiatingUserId?: Maybe<Scalars['String']['output']>;
  memberId?: Maybe<Scalars['String']['output']>;
};

export const Gender = {
  Agender: 'Agender',
  Cisgender: 'Cisgender',
  DoNotIdentifyWithLabels: 'DoNotIdentifyWithLabels',
  Genderfluid: 'Genderfluid',
  Genderqueer: 'Genderqueer',
  IntersexDsd: 'IntersexDsd',
  Man: 'Man',
  Nonbinary: 'Nonbinary',
  NotListed: 'NotListed',
  PreferNotToDisclose: 'PreferNotToDisclose',
  Transgender: 'Transgender',
  Woman: 'Woman'
} as const;

export type Gender = typeof Gender[keyof typeof Gender];
export type GenerateBillInput = {
  billId?: InputMaybe<Scalars['ID']['input']>;
  insuranceFamilyId?: InputMaybe<Scalars['ID']['input']>;
  memberId: Scalars['ID']['input'];
  organizationId: Scalars['ID']['input'];
  originalRefId?: InputMaybe<Scalars['ID']['input']>;
  reIssue?: InputMaybe<Scalars['Boolean']['input']>;
};

export const GenerationFrequencyUnit = {
  Day: 'Day',
  Month: 'Month',
  Week: 'Week'
} as const;

export type GenerationFrequencyUnit = typeof GenerationFrequencyUnit[keyof typeof GenerationFrequencyUnit];
export type GetAclForObjectTypeResponse = {
  __typename?: 'GetACLForObjectTypeResponse';
  data: Array<AclObject>;
};

export type GetActiveMembersByMonthResponse = {
  __typename?: 'GetActiveMembersByMonthResponse';
  data?: Maybe<Array<ActiveMembersByMonth>>;
  message?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

export type GetActiveMembersThisMonthResponse = {
  __typename?: 'GetActiveMembersThisMonthResponse';
  data?: Maybe<ActiveMembersThisMonth>;
  message?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

export type GetActivitiesInput = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  episodeOfCareId?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<ActivitiesFilterInput>;
  includeBillingSummary?: InputMaybe<Scalars['Boolean']['input']>;
  includeSnapshot?: InputMaybe<Scalars['Boolean']['input']>;
  memberId?: InputMaybe<Scalars['ID']['input']>;
  organizationId: Scalars['String']['input'];
  userId?: InputMaybe<Scalars['ID']['input']>;
};

export type GetActivitiesResponse = {
  __typename?: 'GetActivitiesResponse';
  data?: Maybe<PaginatedActivities>;
  message?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

export type GetActivityInput = {
  activityId: Scalars['String']['input'];
  lastStartedAt?: InputMaybe<Scalars['String']['input']>;
  organizationId: Scalars['String']['input'];
};

export type GetActivityResponse = {
  __typename?: 'GetActivityResponse';
  data?: Maybe<Activity>;
  message?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

export type GetActivityTemplateResponse = {
  __typename?: 'GetActivityTemplateResponse';
  data?: Maybe<ActivityTemplate>;
  message?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

export type GetActivityTemplateWithAccessResponse = {
  __typename?: 'GetActivityTemplateWithAccessResponse';
  data?: Maybe<ActivityTemplateWithAccess>;
  message?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

export type GetActivityTemplatesWithAccessResponse = {
  __typename?: 'GetActivityTemplatesWithAccessResponse';
  data?: Maybe<Array<ActivityTemplateWithAccess>>;
  message?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

export type GetAllActivitiesResponse = {
  __typename?: 'GetAllActivitiesResponse';
  data?: Maybe<Array<Activity>>;
  message?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

export type GetAllMembersResponse = {
  __typename?: 'GetAllMembersResponse';
  data?: Maybe<Array<Member>>;
  message?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

export type GetApiKeyResponse = {
  __typename?: 'GetApiKeyResponse';
  data?: Maybe<ApiKey>;
  message?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

export type GetApiKeysResponse = {
  __typename?: 'GetApiKeysResponse';
  data?: Maybe<Array<Maybe<ApiKey>>>;
  message?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

export type GetAssessmentResponse = {
  __typename?: 'GetAssessmentResponse';
  data?: Maybe<Assessment>;
  message?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

export type GetBillActivitiesInput = {
  billId: Scalars['String']['input'];
  memberId: Scalars['String']['input'];
  organizationId: Scalars['String']['input'];
};

export type GetBillBatchResponse = {
  __typename?: 'GetBillBatchResponse';
  data?: Maybe<BillBatch>;
  message?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

export type GetBillBatchesResponse = {
  __typename?: 'GetBillBatchesResponse';
  data?: Maybe<PaginatedBillBatches>;
  message?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

export type GetBillResponse = {
  __typename?: 'GetBillResponse';
  data?: Maybe<Bill>;
  message?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

export type GetBillSignedUrlInput = {
  id: Scalars['ID']['input'];
  organizationId: Scalars['ID']['input'];
};

export type GetBillingProviderResponse = {
  __typename?: 'GetBillingProviderResponse';
  data?: Maybe<BillingProvider>;
  message?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

export type GetBillingProvidersResponse = {
  __typename?: 'GetBillingProvidersResponse';
  data?: Maybe<Array<BillingProvider>>;
  message?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

export type GetBillsInput = {
  date?: InputMaybe<Array<Scalars['String']['input']>>;
  groups?: InputMaybe<Array<Scalars['String']['input']>>;
  inNetwork?: InputMaybe<Scalars['Boolean']['input']>;
  insuranceCompany?: InputMaybe<Array<Scalars['String']['input']>>;
  memberId?: InputMaybe<Scalars['ID']['input']>;
  organizationId?: InputMaybe<Scalars['ID']['input']>;
  statuses?: InputMaybe<Array<BillStatus>>;
};

export type GetBillsResponse = {
  __typename?: 'GetBillsResponse';
  data?: Maybe<PaginatedBills>;
  message?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

export type GetBuilderGraphResponse = {
  __typename?: 'GetBuilderGraphResponse';
  data?: Maybe<BuilderBasicNode>;
  message?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

export type GetByTagsInput = {
  entityType: EntityType;
  organizationId: Scalars['ID']['input'];
  tagIds: Array<Scalars['ID']['input']>;
};

export type GetCalculationResponse = {
  __typename?: 'GetCalculationResponse';
  data?: Maybe<Calculation>;
  message?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

export type GetCalculationsInput = {
  filters?: InputMaybe<Array<InputMaybe<CalculationFilter>>>;
  organizationId: Scalars['ID']['input'];
};

export type GetCalculationsResponse = {
  __typename?: 'GetCalculationsResponse';
  data?: Maybe<PaginatedCalculations>;
  message?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

export type GetCallRoutingConfigurationResponse = {
  __typename?: 'GetCallRoutingConfigurationResponse';
  data?: Maybe<CallRoutingConfiguration>;
  message?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

export type GetCompletedActivitiesByTypeByMonthResponse = {
  __typename?: 'GetCompletedActivitiesByTypeByMonthResponse';
  data?: Maybe<Array<CompletedActivitiesByTypeByMonth>>;
  message?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

export type GetCompletedGoalsByTypeByMonthResponse = {
  __typename?: 'GetCompletedGoalsByTypeByMonthResponse';
  data?: Maybe<Array<CompletedGoalsByTypeByMonth>>;
  message?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

export type GetDataPointTemplateResponse = {
  __typename?: 'GetDataPointTemplateResponse';
  data?: Maybe<DataPointTemplate>;
  message?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

export type GetDataPointTemplatesInput = {
  filters?: InputMaybe<Array<InputMaybe<DataPointTemplateFilter>>>;
  organizationId: Scalars['ID']['input'];
};

export type GetDataPointTemplatesResponse = {
  __typename?: 'GetDataPointTemplatesResponse';
  data?: Maybe<PaginatedDataPointTemplates>;
  message?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

export type GetDomainsResponse = {
  __typename?: 'GetDomainsResponse';
  data?: Maybe<PaginatedDomains>;
  message?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

export type GetDroppedMembersInput = {
  organization: Scalars['ID']['input'];
};

export type GetDroppedMembersResponse = {
  __typename?: 'GetDroppedMembersResponse';
  data?: Maybe<PaginatedDroppedMembers>;
  message?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

export type GetEhrNoteTemplateResponse = {
  __typename?: 'GetEHRNoteTemplateResponse';
  data?: Maybe<EhrNoteTemplate>;
  message?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

export type GetEhrNoteTemplatesResponse = {
  __typename?: 'GetEHRNoteTemplatesResponse';
  data?: Maybe<Array<EhrNoteTemplate>>;
  message?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

export type GetEmailTemplateResponse = {
  __typename?: 'GetEmailTemplateResponse';
  data?: Maybe<EmailTemplate>;
  message?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

export type GetEmailTemplateWithAccessResponse = {
  __typename?: 'GetEmailTemplateWithAccessResponse';
  data?: Maybe<EmailTemplateWithAccess>;
  message?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

export type GetEmailTemplatesMetaResponse = {
  __typename?: 'GetEmailTemplatesMetaResponse';
  data?: Maybe<Array<EmailTemplateMetaWithAccess>>;
  message?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

export type GetEmailTemplatesResponse = {
  __typename?: 'GetEmailTemplatesResponse';
  data?: Maybe<Array<EmailTemplateWithAccess>>;
  message?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

export type GetEventTemplateResponse = {
  __typename?: 'GetEventTemplateResponse';
  data?: Maybe<EventTemplate>;
  message?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

export type GetEventTemplatesResponse = {
  __typename?: 'GetEventTemplatesResponse';
  data?: Maybe<Array<EventTemplate>>;
  message?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

export type GetExternalContactsResponse = {
  __typename?: 'GetExternalContactsResponse';
  data?: Maybe<PaginatedExternalContacts>;
  message?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

export type GetExternalResourceContactResponse = {
  __typename?: 'GetExternalResourceContactResponse';
  data?: Maybe<ExternalResourceContact>;
  message?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

export type GetExternalResourceContactsInput = {
  onlyPhone?: InputMaybe<Scalars['Boolean']['input']>;
  organization: Scalars['ID']['input'];
};

export type GetExternalResourceResponse = {
  __typename?: 'GetExternalResourceResponse';
  data?: Maybe<ExternalResource>;
  message?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

export type GetExternalResourcesResponse = {
  __typename?: 'GetExternalResourcesResponse';
  data?: Maybe<Array<ExternalResource>>;
  message?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

export type GetFileResponse = {
  __typename?: 'GetFileResponse';
  data?: Maybe<PsFile>;
  message?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

export type GetFilesInput = {
  episodeOfCareId?: InputMaybe<Scalars['String']['input']>;
  includeDeleted?: InputMaybe<Scalars['Boolean']['input']>;
  memberId?: InputMaybe<Scalars['ID']['input']>;
  organizationId: Scalars['ID']['input'];
  tagIds?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type GetFilesResponse = {
  __typename?: 'GetFilesResponse';
  data?: Maybe<PaginatedFiles>;
  message?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

export type GetFlowByActivityInput = {
  activityId: Scalars['ID']['input'];
  flowTemplateFamilyId: Scalars['ID']['input'];
  memberId: Scalars['ID']['input'];
  organizationId: Scalars['ID']['input'];
};

export type GetFlowResponse = {
  __typename?: 'GetFlowResponse';
  data?: Maybe<Flow>;
  message?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

export type GetFlowTemplateFamiliesResponse = {
  __typename?: 'GetFlowTemplateFamiliesResponse';
  data?: Maybe<Array<FlowTemplateFamily>>;
  message?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

export type GetFlowTemplateFamiliesWithAccessResponse = {
  __typename?: 'GetFlowTemplateFamiliesWithAccessResponse';
  data?: Maybe<Array<FlowTemplateFamilyWithAccess>>;
  message?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

export type GetFlowTemplateFamilyResponse = {
  __typename?: 'GetFlowTemplateFamilyResponse';
  data?: Maybe<FlowTemplateFamily>;
  message?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

export type GetFlowTemplateResponse = {
  __typename?: 'GetFlowTemplateResponse';
  data?: Maybe<FlowTemplate>;
  message?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

export type GetFlowTemplateWithAccessResponse = {
  __typename?: 'GetFlowTemplateWithAccessResponse';
  data?: Maybe<FlowTemplateWithAccess>;
  message?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

export type GetFlowTemplatesResponse = {
  __typename?: 'GetFlowTemplatesResponse';
  data?: Maybe<Array<FlowTemplate>>;
  message?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

export type GetFlowTemplatesWithAccessResponse = {
  __typename?: 'GetFlowTemplatesWithAccessResponse';
  data?: Maybe<Array<FlowTemplateWithAccess>>;
  message?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

export type GetFlowsResponse = {
  __typename?: 'GetFlowsResponse';
  data?: Maybe<Array<Flow>>;
  message?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

export type GetGoalResponse = {
  __typename?: 'GetGoalResponse';
  data?: Maybe<Goal>;
  message?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

export type GetGoalTemplateRes = {
  __typename?: 'GetGoalTemplateRes';
  data?: Maybe<GoalTemplate>;
  message?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

export type GetGoalTemplatesRes = {
  __typename?: 'GetGoalTemplatesRes';
  data?: Maybe<Array<GoalTemplate>>;
  message?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

export type GetGoalTemplatesWithAccessRes = {
  __typename?: 'GetGoalTemplatesWithAccessRes';
  data?: Maybe<Array<GoalTemplateWithAccess>>;
  message?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

export type GetGoalsResponse = {
  __typename?: 'GetGoalsResponse';
  data?: Maybe<Array<Goal>>;
  message?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

export type GetGroupResponse = {
  __typename?: 'GetGroupResponse';
  data?: Maybe<Group>;
  message?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

export type GetGroupsResponse = {
  __typename?: 'GetGroupsResponse';
  data?: Maybe<Array<GroupWithAccess>>;
  message?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

export type GetInsuranceAccountTransactionsInput = {
  stripeCustomerId?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type GetInsuranceAccountTransactionsResponse = {
  __typename?: 'GetInsuranceAccountTransactionsResponse';
  data?: Maybe<PaginatedInsuranceAccountTransactions>;
  message?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

export type GetInsuranceBillingConfigurationResponse = {
  __typename?: 'GetInsuranceBillingConfigurationResponse';
  data?: Maybe<InsuranceBillingConfiguration>;
  message?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

export type GetInsuranceBillingConfigurationsResponse = {
  __typename?: 'GetInsuranceBillingConfigurationsResponse';
  data?: Maybe<Array<InsuranceBillingConfiguration>>;
  message?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

export type GetMemberBillingSummaryInput = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  memberId: Scalars['ID']['input'];
  organizationId: Scalars['ID']['input'];
};

export type GetMemberBillingSummaryResponse = {
  __typename?: 'GetMemberBillingSummaryResponse';
  data?: Maybe<Array<ProcedureClaimsSummary>>;
  message?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

export type GetMemberCarePlanResponse = {
  __typename?: 'GetMemberCarePlanResponse';
  data?: Maybe<MemberCarePlan>;
  message?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

export type GetMemberGoalStatsResponse = {
  __typename?: 'GetMemberGoalStatsResponse';
  data?: Maybe<MemberGoalStats>;
  message?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

export type GetMemberOverviewMetricResultsResponse = {
  __typename?: 'GetMemberOverviewMetricResultsResponse';
  data?: Maybe<Array<Maybe<MemberOverviewMetricCategoryResult>>>;
  message?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

export type GetMemberResponse = {
  __typename?: 'GetMemberResponse';
  data?: Maybe<Member>;
  message?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

export type GetMemberStatsInput = {
  excludeInactive?: InputMaybe<Scalars['Boolean']['input']>;
  filters?: InputMaybe<Array<InputMaybe<MemberStatFilter>>>;
  organization: Scalars['ID']['input'];
};

export type GetMemberStatsResponse = {
  __typename?: 'GetMemberStatsResponse';
  data?: Maybe<PaginatedMemberStats>;
  message?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

export type GetMembersInput = {
  excludeInactive?: InputMaybe<Scalars['Boolean']['input']>;
  groups?: InputMaybe<Array<Scalars['String']['input']>>;
  organizations: Array<Scalars['ID']['input']>;
  recommendationStatuses?: InputMaybe<Array<ProviderRecommendationStatus>>;
  showOnlyRecommendationRequired?: InputMaybe<Scalars['Boolean']['input']>;
  unassigned?: InputMaybe<Scalars['Boolean']['input']>;
  user?: InputMaybe<Scalars['ID']['input']>;
};

export type GetMembersResponse = {
  __typename?: 'GetMembersResponse';
  data?: Maybe<PaginatedMembers>;
  message?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

export type GetNextFlowQuestionResponse = {
  __typename?: 'GetNextFlowQuestionResponse';
  data?: Maybe<NextQuestionData>;
  message?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

export type GetNoteResponse = {
  __typename?: 'GetNoteResponse';
  data?: Maybe<Note>;
  message?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

export type GetNoteTemplateInput = {
  noteTemplateId: Scalars['ID']['input'];
  organizationId: Scalars['ID']['input'];
};

export type GetNoteTemplateResponse = {
  __typename?: 'GetNoteTemplateResponse';
  data?: Maybe<NoteTemplate>;
  message?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

export type GetNoteTemplatesInput = {
  organizationId: Scalars['ID']['input'];
};

export type GetNoteTemplatesResponse = {
  __typename?: 'GetNoteTemplatesResponse';
  data?: Maybe<Array<NoteTemplate>>;
  message?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

export type GetNotesInput = {
  activityId?: InputMaybe<Scalars['ID']['input']>;
  authorId?: InputMaybe<Scalars['ID']['input']>;
  episodeOfCareId?: InputMaybe<Scalars['String']['input']>;
  externalResourceContactId?: InputMaybe<Scalars['ID']['input']>;
  externalResourceId?: InputMaybe<Scalars['ID']['input']>;
  memberId?: InputMaybe<Scalars['ID']['input']>;
  organizationId: Scalars['ID']['input'];
};

export type GetNotesResponse = {
  __typename?: 'GetNotesResponse';
  data?: Maybe<Array<Note>>;
  message?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

export type GetOnboardingLinkInput = {
  organizationId: Scalars['ID']['input'];
  surveyId?: InputMaybe<Scalars['ID']['input']>;
  surveyTitle?: InputMaybe<Scalars['String']['input']>;
  templateId: Scalars['ID']['input'];
};

export type GetOnboardingLinkResponse = {
  __typename?: 'GetOnboardingLinkResponse';
  data?: Maybe<Scalars['String']['output']>;
  message?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

export type GetOnboardingSurveysResponse = {
  __typename?: 'GetOnboardingSurveysResponse';
  data?: Maybe<Array<OnboardingSurvey>>;
  message?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

export type GetOrganizationBillBatchesInput = {
  organizationId: Scalars['ID']['input'];
  status?: InputMaybe<BillBatchStatus>;
};

export type GetOrganizationResponse = {
  __typename?: 'GetOrganizationResponse';
  data?: Maybe<Organization>;
  message?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

export type GetOrganizationsResponse = {
  __typename?: 'GetOrganizationsResponse';
  data?: Maybe<Array<Organization>>;
  message?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

export type GetPaginatedQuestionsInput = {
  filters?: InputMaybe<Array<InputMaybe<QuestionFilter>>>;
  organizationId: Scalars['ID']['input'];
  type?: InputMaybe<QuestionType>;
};

export type GetPaginatedQuestionsResponse = {
  __typename?: 'GetPaginatedQuestionsResponse';
  data?: Maybe<PaginatedQuestions>;
  message?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

export type GetPhoneCallByConferenceFriendlyNameInput = {
  conferenceFriendlyName: Scalars['String']['input'];
  organizationId: Scalars['String']['input'];
};

export type GetPhoneCallInput = {
  identifier: Scalars['ID']['input'];
  identifierType: PhoneCallIdentifierType;
  organizationId: Scalars['ID']['input'];
};

export type GetPhoneCallsInput = {
  memberId?: InputMaybe<Scalars['ID']['input']>;
  missedOnly?: InputMaybe<Scalars['Boolean']['input']>;
  organizationId: Scalars['ID']['input'];
  userId?: InputMaybe<Scalars['ID']['input']>;
};

export type GetPhoneCallsResponse = {
  __typename?: 'GetPhoneCallsResponse';
  data?: Maybe<PaginatedPhoneCalls>;
  message?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

export type GetProviderReportsInput = {
  organizationId: Scalars['ID']['input'];
  statuses?: InputMaybe<Array<ProviderRecommendationStatus>>;
};

export type GetProviderReportsResponse = {
  __typename?: 'GetProviderReportsResponse';
  data?: Maybe<PaginatedProviderReports>;
  message?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

export type GetQuestionResponse = {
  __typename?: 'GetQuestionResponse';
  data?: Maybe<Question>;
  message?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

export type GetQuestionsResponse = {
  __typename?: 'GetQuestionsResponse';
  data?: Maybe<Array<Question>>;
  message?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

export type GetRecommendationResponse = {
  __typename?: 'GetRecommendationResponse';
  data?: Maybe<Recommendation>;
  message?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

export type GetRecommendingProvidersInput = {
  getDeactivated?: InputMaybe<Scalars['Boolean']['input']>;
  organizationId: Scalars['ID']['input'];
};

export type GetReferralInput = {
  organizationId: Scalars['ID']['input'];
  referralId: Scalars['ID']['input'];
};

export type GetReferralResponse = {
  __typename?: 'GetReferralResponse';
  data?: Maybe<Referral>;
  message?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

export type GetReferralTemplateInput = {
  organizationId: Scalars['ID']['input'];
  templateId?: InputMaybe<Scalars['ID']['input']>;
};

export type GetReferralTemplateResponse = {
  __typename?: 'GetReferralTemplateResponse';
  data?: Maybe<ReferralTemplate>;
  message?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

export type GetReferralTemplatesInput = {
  organizationId: Scalars['ID']['input'];
  resourceId?: InputMaybe<Scalars['ID']['input']>;
};

export type GetReferralTemplatesResponse = {
  __typename?: 'GetReferralTemplatesResponse';
  data?: Maybe<Array<ReferralTemplate>>;
  message?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

export type GetReferralsByMemberInput = {
  episodeOfCareId?: InputMaybe<Scalars['String']['input']>;
  memberId: Scalars['ID']['input'];
  organizationId: Scalars['ID']['input'];
  statusFilter?: InputMaybe<Array<InputMaybe<ReferralStatus>>>;
  typeFilter?: InputMaybe<Array<InputMaybe<ReferralType>>>;
};

export type GetReferralsByResourceInput = {
  externalResourceId: Scalars['ID']['input'];
  organizationId: Scalars['ID']['input'];
  statusFilter?: InputMaybe<Array<InputMaybe<ReferralStatus>>>;
  typeFilter?: InputMaybe<Array<InputMaybe<ReferralType>>>;
};

export type GetReferralsResponse = {
  __typename?: 'GetReferralsResponse';
  data?: Maybe<PaginatedReferrals>;
  message?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

export type GetRoleResponse = {
  __typename?: 'GetRoleResponse';
  data?: Maybe<Role>;
  message?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

export type GetRolesInput = {
  organizationId: Scalars['ID']['input'];
};

export type GetRolesResponse = {
  __typename?: 'GetRolesResponse';
  data?: Maybe<Array<Role>>;
  message?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

export type GetScheduleDataResponse = {
  __typename?: 'GetScheduleDataResponse';
  activities: Array<Maybe<Activity>>;
  dateEvents: Array<Maybe<CalendarEvent>>;
  pastDueActivities: Array<Maybe<Activity>>;
};

export type GetScheduleInput = {
  of?: InputMaybe<GetScheduleOfInput>;
  organizationId: Scalars['ID']['input'];
  userId?: InputMaybe<Scalars['ID']['input']>;
};

export type GetScheduleOfInput = {
  from: Scalars['String']['input'];
  to: Scalars['String']['input'];
};

export type GetScheduleResponse = {
  __typename?: 'GetScheduleResponse';
  data?: Maybe<GetScheduleDataResponse>;
  message: Scalars['String']['output'];
  success: Scalars['Boolean']['output'];
};

export type GetSignedDocumentResponse = {
  __typename?: 'GetSignedDocumentResponse';
  data?: Maybe<SignedDocument>;
  message?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

export type GetSubOrgsResponse = {
  __typename?: 'GetSubOrgsResponse';
  data?: Maybe<Array<SubOrg>>;
  message?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

export type GetTagResponse = {
  __typename?: 'GetTagResponse';
  data?: Maybe<Tag>;
  message?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

export type GetTagsInput = {
  organizationId: Scalars['ID']['input'];
};

export type GetTagsResponse = {
  __typename?: 'GetTagsResponse';
  data?: Maybe<PaginatedTags>;
  message?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

export type GetTextMessageThreadsInput = {
  organizationId: Scalars['String']['input'];
  unreadOnly?: InputMaybe<Scalars['Boolean']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
};

export type GetTextMessageThreadsResponse = {
  __typename?: 'GetTextMessageThreadsResponse';
  data?: Maybe<PaginatedTextMessageThreads>;
  message?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

export type GetTextMessagesInput = {
  organizationId: Scalars['String']['input'];
  participantPhone: Scalars['String']['input'];
};

export type GetTextMessagesResponse = {
  __typename?: 'GetTextMessagesResponse';
  data?: Maybe<PaginatedTextMessages>;
  message?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

export type GetTitledReferencesResponse = {
  __typename?: 'GetTitledReferencesResponse';
  data?: Maybe<Array<TitledLockingReference>>;
  message?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

export type GetUnassignedMembersInput = {
  organization: Scalars['ID']['input'];
};

export type GetUnassignedMembersResponse = {
  __typename?: 'GetUnassignedMembersResponse';
  data?: Maybe<PaginatedUnassignedMembers>;
  message?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

export type GetUrlResponse = {
  __typename?: 'GetUrlResponse';
  data?: Maybe<Scalars['String']['output']>;
  message?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

export type GetUserCurrentActivityInput = {
  lastStartedAt?: InputMaybe<Scalars['String']['input']>;
  organizationId: Scalars['String']['input'];
  userId: Scalars['String']['input'];
};

export type GetUserGroupInput = {
  _id: Scalars['ID']['input'];
  organizationId: Scalars['ID']['input'];
};

export type GetUserGroupResponse = {
  __typename?: 'GetUserGroupResponse';
  data?: Maybe<UserGroup>;
  message?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

export type GetUserGroupsResponse = {
  __typename?: 'GetUserGroupsResponse';
  data?: Maybe<Array<UserGroup>>;
  message?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

export type GetUserIntegrationsResponse = {
  __typename?: 'GetUserIntegrationsResponse';
  data?: Maybe<Array<Maybe<UserIntegration>>>;
  message?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

export type GetUserResponse = {
  __typename?: 'GetUserResponse';
  data?: Maybe<User>;
  message?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

export type GetUserStatsResponse = {
  __typename?: 'GetUserStatsResponse';
  data?: Maybe<Array<Maybe<UserStat>>>;
  message?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

export type GetUsersInput = {
  excludeRecommendingProviders?: InputMaybe<Scalars['Boolean']['input']>;
  excludeSuperAdmins?: InputMaybe<Scalars['Boolean']['input']>;
  getDeactivatedUsers?: InputMaybe<Scalars['Boolean']['input']>;
  organizationId?: InputMaybe<Scalars['ID']['input']>;
};

export type GetUsersResponse = {
  __typename?: 'GetUsersResponse';
  data?: Maybe<Array<User>>;
  message?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

export type GetVoicemailsInput = {
  organizationId: Scalars['ID']['input'];
  showHidden: Scalars['Boolean']['input'];
  userId?: InputMaybe<Scalars['ID']['input']>;
};

export type Goal = {
  __typename?: 'Goal';
  _id: Scalars['ID']['output'];
  actionItems: Array<ActionItem>;
  activities?: Maybe<Array<Activity>>;
  baseTemplateId?: Maybe<Scalars['String']['output']>;
  carePathways?: Maybe<Array<CarePathway>>;
  completedAt?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['String']['output'];
  description: Scalars['String']['output'];
  domain?: Maybe<Domain>;
  dueDate?: Maybe<Scalars['String']['output']>;
  episodeOfCareId?: Maybe<Scalars['String']['output']>;
  memberId: Scalars['ID']['output'];
  memberName?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  organizationId: Scalars['String']['output'];
  referrals?: Maybe<Array<Referral>>;
  status: GoalStatus;
  updatedAt: Scalars['String']['output'];
};

export const GoalStatus = {
  Active: 'Active',
  Completed: 'Completed',
  Resolved: 'Resolved',
  Unresolved: 'Unresolved'
} as const;

export type GoalStatus = typeof GoalStatus[keyof typeof GoalStatus];
export type GoalTemplate = {
  __typename?: 'GoalTemplate';
  _id: Scalars['ID']['output'];
  actionItemDescriptions: Array<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  domain?: Maybe<Domain>;
  domainId?: Maybe<Scalars['String']['output']>;
  lockingReferences: Array<LockingReference>;
  name: Scalars['String']['output'];
  organizationId: Scalars['ID']['output'];
};

export type GoalTemplateInput = {
  _id?: InputMaybe<Scalars['ID']['input']>;
  actionItemDescriptions: Array<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  domainId?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  organizationId: Scalars['ID']['input'];
};

export type GoalTemplateWithAccess = {
  __typename?: 'GoalTemplateWithAccess';
  accessType: AccessType;
  goalTemplate: GoalTemplate;
};

export type GoalsStats = {
  __typename?: 'GoalsStats';
  data: Array<ResourceGranularData>;
  groupByTimeUnit: GroupTimeUnit;
};

export type GoalsStatsResponse = {
  __typename?: 'GoalsStatsResponse';
  message?: Maybe<Scalars['String']['output']>;
  stats?: Maybe<GoalsStats>;
  success: Scalars['Boolean']['output'];
};

export type GranularData = {
  __typename?: 'GranularData';
  key: Scalars['String']['output'];
  value: Array<GranularValue>;
};

export type GranularValue = {
  __typename?: 'GranularValue';
  type: Scalars['String']['output'];
  value: Scalars['Float']['output'];
};

export type Group = {
  __typename?: 'Group';
  _id: Scalars['ID']['output'];
  createdAt: Scalars['String']['output'];
  endAt?: Maybe<Scalars['String']['output']>;
  endInDays?: Maybe<Scalars['Int']['output']>;
  endInMonths?: Maybe<Scalars['Int']['output']>;
  endInWeeks?: Maybe<Scalars['Int']['output']>;
  organizationId: Scalars['String']['output'];
  title: Scalars['String']['output'];
  updatedAt: Scalars['String']['output'];
};

export type GroupActivityInput = {
  activityTemplateId: Scalars['ID']['input'];
  appointmentDetails?: InputMaybe<ActivityAppointmentDetailsInput>;
  billable?: InputMaybe<Scalars['Boolean']['input']>;
  date: Scalars['String']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  forAllMembersAssignedToUser?: InputMaybe<Scalars['Boolean']['input']>;
  groups?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  organization: Scalars['String']['input'];
  organizationId: Scalars['ID']['input'];
  scheduledEndAt: Scalars['String']['input'];
  status?: InputMaybe<ActivityStatus>;
  sync?: InputMaybe<Scalars['Boolean']['input']>;
  syncAppointment?: InputMaybe<Scalars['Boolean']['input']>;
  tasks?: InputMaybe<Array<ActivityTaskInput>>;
  title?: InputMaybe<Scalars['String']['input']>;
  userId?: InputMaybe<Scalars['ID']['input']>;
};

export type GroupMemberInfoInput = {
  activityTemplateId?: InputMaybe<Scalars['ID']['input']>;
  groupId: Scalars['ID']['input'];
  includeMembersWithActivityTemplate: Scalars['Boolean']['input'];
  organizationId: Scalars['ID']['input'];
};

export const GroupTimeUnit = {
  Day: 'Day',
  Month: 'Month',
  Quarter: 'Quarter',
  Week: 'Week',
  Year: 'Year'
} as const;

export type GroupTimeUnit = typeof GroupTimeUnit[keyof typeof GroupTimeUnit];
export type GroupWithAccess = {
  __typename?: 'GroupWithAccess';
  accessType: AccessType;
  group: Group;
};

export type IdObject = {
  id: Scalars['ID']['input'];
};

export type InProgressCallRouting = {
  __typename?: 'InProgressCallRouting';
  createdAt: Scalars['String']['output'];
  currentNodeId: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  updatedAt: Scalars['String']['output'];
};

export type InsuranceAccountTransaction = {
  __typename?: 'InsuranceAccountTransaction';
  _id: Scalars['ID']['output'];
  amount: Scalars['Float']['output'];
  date: Scalars['String']['output'];
  insurances: Array<Scalars['String']['output']>;
  reference: Scalars['String']['output'];
  resolvedAmount: Scalars['Float']['output'];
  senderName?: Maybe<Scalars['String']['output']>;
  senderNetwork?: Maybe<Scalars['String']['output']>;
  stripeCustomerId: Scalars['String']['output'];
  stripeId: Scalars['String']['output'];
};

export type InsuranceBillingConfiguration = {
  __typename?: 'InsuranceBillingConfiguration';
  _id: Scalars['ID']['output'];
  abaAccountNumber?: Maybe<Scalars['String']['output']>;
  abaBankName?: Maybe<Scalars['String']['output']>;
  abaRoutingNumber?: Maybe<Scalars['String']['output']>;
  abaSupportedNetworks?: Maybe<Array<Scalars['String']['output']>>;
  archived: Scalars['Boolean']['output'];
  billingProvider?: Maybe<BillingProvider>;
  claimType?: Maybe<InsuranceClaimType>;
  costs: Array<BillingCostConfiguration>;
  coverageCheckPayerId?: Maybe<Scalars['String']['output']>;
  defaultUnitCost: Scalars['Int']['output'];
  deliveryEmail?: Maybe<Scalars['String']['output']>;
  deliveryMethod?: Maybe<BillDeliveryMethod>;
  familyId: Scalars['ID']['output'];
  generator?: Maybe<BillGenerator>;
  inNetwork: Scalars['Boolean']['output'];
  insurance: Scalars['String']['output'];
  payerFirstName?: Maybe<Scalars['String']['output']>;
  payerId?: Maybe<Scalars['String']['output']>;
  payerIdType?: Maybe<Scalars['String']['output']>;
  payerOrganizationNameOrLastName?: Maybe<Scalars['String']['output']>;
  stripeCustomerId?: Maybe<Scalars['String']['output']>;
  submitRecommendingProviderWithClaim: Scalars['Boolean']['output'];
  submitterFirstName?: Maybe<Scalars['String']['output']>;
  submitterId?: Maybe<Scalars['String']['output']>;
  submitterIdType?: Maybe<Scalars['String']['output']>;
  submitterOrganizationNameOrLastName?: Maybe<Scalars['String']['output']>;
  swiftAccountNumber?: Maybe<Scalars['String']['output']>;
  swiftBankName?: Maybe<Scalars['String']['output']>;
  swiftCode?: Maybe<Scalars['String']['output']>;
  swiftSupportedNetworks?: Maybe<Array<Scalars['String']['output']>>;
};

export type InsuranceBillingConfigurationInput = {
  billingProviderId?: InputMaybe<Scalars['ID']['input']>;
  claimType?: InputMaybe<InsuranceClaimType>;
  coverageCheckPayerId?: InputMaybe<Scalars['String']['input']>;
  defaultUnitCost?: InputMaybe<Scalars['Float']['input']>;
  deliveryEmail?: InputMaybe<Scalars['String']['input']>;
  deliveryMethod?: InputMaybe<BillDeliveryMethod>;
  generator?: InputMaybe<BillGenerator>;
  insurance: Scalars['String']['input'];
  payerFirstName?: InputMaybe<Scalars['String']['input']>;
  payerId?: InputMaybe<Scalars['String']['input']>;
  payerIdType?: InputMaybe<Scalars['String']['input']>;
  payerOrganizationNameOrLastName?: InputMaybe<Scalars['String']['input']>;
  stripeCustomerId?: InputMaybe<Scalars['String']['input']>;
  submitRecommendingProviderWithClaim?: InputMaybe<Scalars['Boolean']['input']>;
  submitterFirstName?: InputMaybe<Scalars['String']['input']>;
  submitterId?: InputMaybe<Scalars['String']['input']>;
  submitterIdType?: InputMaybe<Scalars['String']['input']>;
  submitterOrganizationNameOrLastName?: InputMaybe<Scalars['String']['input']>;
};

export type InsuranceBillingConfigurationSummary = {
  __typename?: 'InsuranceBillingConfigurationSummary';
  _id: Scalars['ID']['output'];
  archived: Scalars['Boolean']['output'];
  deliveryMethod?: Maybe<BillDeliveryMethod>;
  familyId: Scalars['ID']['output'];
  generator?: Maybe<BillGenerator>;
  inNetwork: Scalars['Boolean']['output'];
  insurance: Scalars['String']['output'];
};

export const InsuranceClaimType = {
  AutomobileMedical: 'AutomobileMedical',
  BlueCrossBlueShield: 'BlueCrossBlueShield',
  Champus: 'Champus',
  CommericalInsuranceCo: 'CommericalInsuranceCo',
  DentalMaintenanceOrganization: 'DentalMaintenanceOrganization',
  Disability: 'Disability',
  ExclusiveProviderOrganization: 'ExclusiveProviderOrganization',
  FederalEmployeesProgram: 'FederalEmployeesProgram',
  HealthMaintenanceOrganization: 'HealthMaintenanceOrganization',
  HealthMaintenanceOrganizationMedicareRisk: 'HealthMaintenanceOrganizationMedicareRisk',
  IdemnityInsurance: 'IdemnityInsurance',
  LiabilityMedical: 'LiabilityMedical',
  Medicaid: 'Medicaid',
  MedicarePartA: 'MedicarePartA',
  MedicarePartB: 'MedicarePartB',
  OtherFederalProgram: 'OtherFederalProgram',
  OtherNonFederalPrograms: 'OtherNonFederalPrograms',
  PointOfService: 'PointOfService',
  PreferredProviderOrganization: 'PreferredProviderOrganization',
  TitleV: 'TitleV',
  VeteransAffairsPlan: 'VeteransAffairsPlan',
  WorkersCompensationHealthClaim: 'WorkersCompensationHealthClaim'
} as const;

export type InsuranceClaimType = typeof InsuranceClaimType[keyof typeof InsuranceClaimType];
export const Integration = {
  RedoxAppointmentScheduling: 'RedoxAppointmentScheduling',
  RedoxNoteSending: 'RedoxNoteSending',
  RedoxPatientSearch: 'RedoxPatientSearch',
  RedoxPatientSync: 'RedoxPatientSync'
} as const;

export type Integration = typeof Integration[keyof typeof Integration];
export type InviteUserInput = {
  email: Scalars['String']['input'];
  isSuperAdmin: Scalars['Boolean']['input'];
  location?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  organizationIds: Array<Scalars['String']['input']>;
  recommendingProvider?: InputMaybe<RecommendingProviderInput>;
  spokenLanguages?: InputMaybe<Array<Language>>;
  userOrgRoles: Array<UserOrgRoleInput>;
};

export const JourneyStage = {
  Action: 'Action',
  Contemplation: 'Contemplation',
  Maintenance: 'Maintenance',
  Precontemplation: 'Precontemplation',
  Preparation: 'Preparation'
} as const;

export type JourneyStage = typeof JourneyStage[keyof typeof JourneyStage];
export type JourneyStagePositions = {
  __typename?: 'JourneyStagePositions';
  Action: Scalars['Int']['output'];
  Contemplation: Scalars['Int']['output'];
  Maintenance: Scalars['Int']['output'];
  Preparation: Scalars['Int']['output'];
};

export type JourneyStagePositionsInput = {
  Action: Scalars['Int']['input'];
  Contemplation: Scalars['Int']['input'];
  Maintenance: Scalars['Int']['input'];
  Preparation: Scalars['Int']['input'];
};

export const Language = {
  AffanOromo: 'AffanOromo',
  Amharic: 'Amharic',
  Arabic: 'Arabic',
  Armenian: 'Armenian',
  Asl: 'Asl',
  Bantu: 'Bantu',
  Bengali: 'Bengali',
  Bosnian: 'Bosnian',
  CHamoru: 'CHamoru',
  Cantonese: 'Cantonese',
  Chinese: 'Chinese',
  Chuukese: 'Chuukese',
  Croatian: 'Croatian',
  Dari: 'Dari',
  Dinka: 'Dinka',
  English: 'English',
  Farsi: 'Farsi',
  Fijian: 'Fijian',
  Filipino: 'Filipino',
  French: 'French',
  German: 'German',
  Greek: 'Greek',
  Gujarati: 'Gujarati',
  Haitian: 'Haitian',
  Hawaiian: 'Hawaiian',
  Hebrew: 'Hebrew',
  Hindi: 'Hindi',
  Hmong: 'Hmong',
  Ilocano: 'Ilocano',
  Indonesian: 'Indonesian',
  Italian: 'Italian',
  Japanese: 'Japanese',
  Khmer: 'Khmer',
  Kinyarwanda: 'Kinyarwanda',
  Korean: 'Korean',
  Lao: 'Lao',
  Mandarin: 'Mandarin',
  Marshallese: 'Marshallese',
  Montenegrin: 'Montenegrin',
  Navajo: 'Navajo',
  NotListed: 'NotListed',
  Pashto: 'Pashto',
  Persian: 'Persian',
  Polish: 'Polish',
  Portuguese: 'Portuguese',
  Punjabi: 'Punjabi',
  Russian: 'Russian',
  Samoan: 'Samoan',
  Serbian: 'Serbian',
  SerboCroatian: 'SerboCroatian',
  Somali: 'Somali',
  Spanish: 'Spanish',
  Swahili: 'Swahili',
  Tagalog: 'Tagalog',
  Tamil: 'Tamil',
  Telugu: 'Telugu',
  Thai: 'Thai',
  ThaiKadai: 'ThaiKadai',
  Tigrigna: 'Tigrigna',
  Tonga: 'Tonga',
  Turkish: 'Turkish',
  Urdu: 'Urdu',
  Vietnamese: 'Vietnamese'
} as const;

export type Language = typeof Language[keyof typeof Language];
export type LinkRecommendingProviderInput = {
  nationalProviderIdentifier: Scalars['String']['input'];
  organizationId: Scalars['ID']['input'];
};

export type LinkedContact = {
  __typename?: 'LinkedContact';
  _id: Scalars['ID']['output'];
  canBeCalled?: Maybe<Scalars['Boolean']['output']>;
  canBeTexted?: Maybe<Scalars['Boolean']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  phone?: Maybe<Scalars['String']['output']>;
  relation?: Maybe<Scalars['String']['output']>;
  type: LinkedContactType;
};

export type LinkedContactInput = {
  _id?: InputMaybe<Scalars['String']['input']>;
  canBeCalled?: InputMaybe<Scalars['Boolean']['input']>;
  canBeTexted?: InputMaybe<Scalars['Boolean']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  phone?: InputMaybe<Scalars['String']['input']>;
  relation?: InputMaybe<Scalars['String']['input']>;
  type: LinkedContactType;
};

export const LinkedContactType = {
  Caregiver: 'Caregiver',
  EmergencyContact: 'EmergencyContact',
  Other: 'Other',
  PrimaryCareProvider: 'PrimaryCareProvider'
} as const;

export type LinkedContactType = typeof LinkedContactType[keyof typeof LinkedContactType];
export type LockingReference = {
  __typename?: 'LockingReference';
  isActive: Scalars['Boolean']['output'];
  referencingId: Scalars['String']['output'];
  referencingType: LockingReferenceType;
};

export type LockingReferenceInput = {
  isActive: Scalars['Boolean']['input'];
  referencingId: Scalars['String']['input'];
  referencingType: LockingReferenceType;
};

export const LockingReferenceType = {
  Activity: 'Activity',
  ActivityTemplate: 'ActivityTemplate',
  Calculation: 'Calculation',
  CalculationVariable: 'CalculationVariable',
  CallRoutingConfiguration: 'CallRoutingConfiguration',
  CarePathwayTemplate: 'CarePathwayTemplate',
  ConditionItem: 'ConditionItem',
  ConsentTemplate: 'ConsentTemplate',
  FlowTemplate: 'FlowTemplate',
  Organization: 'Organization',
  Question: 'Question',
  Recommendation: 'Recommendation',
  ReferralTemplate: 'ReferralTemplate'
} as const;

export type LockingReferenceType = typeof LockingReferenceType[keyof typeof LockingReferenceType];
export type LockingReferencesAndOrgIdInput = {
  organizationId: Scalars['String']['input'];
  references: Array<LockingReferenceInput>;
};

export const MaritalStatus = {
  Divorced: 'Divorced',
  Married: 'Married',
  Separated: 'Separated',
  Single: 'Single',
  Widowed: 'Widowed'
} as const;

export type MaritalStatus = typeof MaritalStatus[keyof typeof MaritalStatus];
export type Member = {
  __typename?: 'Member';
  _id: Scalars['ID']['output'];
  assignedUserIds?: Maybe<Array<Scalars['String']['output']>>;
  childrenInHousehold?: Maybe<Scalars['Int']['output']>;
  contactInfo: ContactInfo;
  createdAt: Scalars['String']['output'];
  diagnoses?: Maybe<Array<Scalars['String']['output']>>;
  diagnosisCodes?: Maybe<Array<DiagnosisCode>>;
  dob?: Maybe<Scalars['String']['output']>;
  ehrID?: Maybe<Scalars['String']['output']>;
  ehrIDType?: Maybe<Scalars['String']['output']>;
  employerName?: Maybe<Scalars['String']['output']>;
  employmentStatus?: Maybe<EmploymentStatus>;
  episodesOfCare?: Maybe<Array<EpisodeOfCare>>;
  ethnicity?: Maybe<Ethnicity>;
  familyMembers?: Maybe<Array<FamilyMember>>;
  firstName: Scalars['String']['output'];
  gender?: Maybe<Array<Gender>>;
  highestLevelOfEducation?: Maybe<EducationLevel>;
  insuranceCoverages?: Maybe<Array<MemberInsuranceCoverage>>;
  insuranceDetails?: Maybe<Array<MemberInsuranceDetail>>;
  lastCoverageUpdate?: Maybe<Scalars['String']['output']>;
  lastEhrSync?: Maybe<Scalars['String']['output']>;
  lastName: Scalars['String']['output'];
  linkedContacts: Array<LinkedContact>;
  maidenNameOrAlias?: Maybe<Scalars['String']['output']>;
  maritalStatus?: Maybe<MaritalStatus>;
  medicaidStatus?: Maybe<EnrollmentStatus>;
  medicalRecordNumber?: Maybe<Scalars['String']['output']>;
  medicareStatus?: Maybe<EnrollmentStatus>;
  nickName?: Maybe<Scalars['String']['output']>;
  overriddenProcedureCodeLimits: Array<Scalars['String']['output']>;
  peopleInHousehold?: Maybe<Scalars['Int']['output']>;
  phonetic?: Maybe<Scalars['String']['output']>;
  pregnancyStatusEnum?: Maybe<PregnancyStatus>;
  prescriptions?: Maybe<Array<Scalars['String']['output']>>;
  primaryCIN?: Maybe<Scalars['String']['output']>;
  pronouns?: Maybe<Array<Pronouns>>;
  providerRecommendations?: Maybe<Array<ProviderRecommendation>>;
  race?: Maybe<Array<Scalars['String']['output']>>;
  reasonForDeactivation?: Maybe<Scalars['String']['output']>;
  secondaryCIN?: Maybe<Scalars['String']['output']>;
  selfOnboarded?: Maybe<Scalars['Boolean']['output']>;
  sex?: Maybe<Sex>;
  sexualOrientation?: Maybe<Array<SexualOrientation>>;
  socialSecurityNumber?: Maybe<Scalars['String']['output']>;
  spokenLanguages?: Maybe<Array<Language>>;
  status?: Maybe<MemberStatus>;
  timezone?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['String']['output'];
};

export type MemberBrief = {
  __typename?: 'MemberBrief';
  _id: Scalars['ID']['output'];
  email?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
};

export type MemberCarePlan = {
  __typename?: 'MemberCarePlan';
  additionalSections: Array<MemberCarePlanAdditionalSection>;
  assignedUsers?: Maybe<Array<User>>;
  billingProvider?: Maybe<BillingProvider>;
  domains: Array<Domain>;
  memberDataIds?: Maybe<Array<CarePlanAdditionalSectionDataId>>;
  recommendingProvider?: Maybe<RecommendingProvider>;
  snapshots?: Maybe<Array<CarePlanSnapshot>>;
};

export type MemberCarePlanAdditionalSection = {
  __typename?: 'MemberCarePlanAdditionalSection';
  dataIds: Array<CarePlanAdditionalSectionDataId>;
  title?: Maybe<Scalars['String']['output']>;
};

export type MemberDomainDescription = {
  __typename?: 'MemberDomainDescription';
  _id: Scalars['ID']['output'];
  description?: Maybe<Scalars['String']['output']>;
  domainId?: Maybe<Scalars['ID']['output']>;
  memberId?: Maybe<Scalars['ID']['output']>;
};

export const MemberField = {
  DiagnosisCodes: 'diagnosisCodes',
  Dob: 'dob',
  Email: 'email',
  FirstName: 'firstName',
  Gender: 'gender',
  InsuranceCompany: 'insuranceCompany',
  LastName: 'lastName',
  MedicaidStatus: 'medicaidStatus',
  MedicareStatus: 'medicareStatus',
  NickName: 'nickName',
  PhoneNumber: 'phoneNumber',
  Phonetic: 'phonetic',
  Pronouns: 'pronouns',
  Race: 'race',
  ReasonForDeactivation: 'reasonForDeactivation',
  SocialSecurityNumber: 'socialSecurityNumber',
  SpokenLanguages: 'spokenLanguages',
  Status: 'status'
} as const;

export type MemberField = typeof MemberField[keyof typeof MemberField];
export type MemberGoalStats = {
  __typename?: 'MemberGoalStats';
  complete: Scalars['Int']['output'];
  inProgress: Scalars['Int']['output'];
  memberCount: Scalars['Int']['output'];
};

export type MemberGroup = {
  __typename?: 'MemberGroup';
  _id: Scalars['ID']['output'];
  createdAt: Scalars['String']['output'];
  endAt?: Maybe<Scalars['String']['output']>;
  endInDays?: Maybe<Scalars['Int']['output']>;
  endInMonths?: Maybe<Scalars['Int']['output']>;
  endInWeeks?: Maybe<Scalars['Int']['output']>;
  joinedAt: Scalars['String']['output'];
  title: Scalars['String']['output'];
  updatedAt: Scalars['String']['output'];
};

export type MemberIdAndName = {
  __typename?: 'MemberIdAndName';
  firstName: Scalars['String']['output'];
  lastName: Scalars['String']['output'];
  memberId: Scalars['ID']['output'];
  nickName?: Maybe<Scalars['String']['output']>;
  phoneNumber: Scalars['String']['output'];
};

export type MemberIdAndNameInput = {
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  memberId: Scalars['ID']['input'];
  nickName?: InputMaybe<Scalars['String']['input']>;
  phoneNumber: Scalars['String']['input'];
};

export type MemberInfo = {
  __typename?: 'MemberInfo';
  memberDisplayName: Scalars['String']['output'];
  memberId: Scalars['ID']['output'];
};

export type MemberInfoResponse = {
  __typename?: 'MemberInfoResponse';
  data?: Maybe<Array<MemberInfo>>;
  message?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

export type MemberInput = {
  childrenInHousehold?: InputMaybe<Scalars['Int']['input']>;
  contactInfo: ContactInfoInput;
  diagnoses?: InputMaybe<Array<Scalars['String']['input']>>;
  dob?: InputMaybe<Scalars['String']['input']>;
  ehrID?: InputMaybe<Scalars['String']['input']>;
  ehrIDType?: InputMaybe<Scalars['String']['input']>;
  employerName?: InputMaybe<Scalars['String']['input']>;
  employmentStatus?: InputMaybe<EmploymentStatus>;
  ethnicity?: InputMaybe<Ethnicity>;
  familyMembers?: InputMaybe<Array<FamilyMemberInput>>;
  firstName: Scalars['String']['input'];
  gender?: InputMaybe<Array<Gender>>;
  highestLevelOfEducation?: InputMaybe<EducationLevel>;
  lastName: Scalars['String']['input'];
  linkedContacts?: InputMaybe<Array<LinkedContactInput>>;
  maidenNameOrAlias?: InputMaybe<Scalars['String']['input']>;
  maritalStatus?: InputMaybe<MaritalStatus>;
  medicaidStatus?: InputMaybe<EnrollmentStatus>;
  medicalRecordNumber?: InputMaybe<Scalars['String']['input']>;
  medicareStatus?: InputMaybe<EnrollmentStatus>;
  nickName?: InputMaybe<Scalars['String']['input']>;
  organizationId: Scalars['ID']['input'];
  peopleInHousehold?: InputMaybe<Scalars['Int']['input']>;
  phonetic?: InputMaybe<Scalars['String']['input']>;
  pregnancyStatusEnum?: InputMaybe<PregnancyStatus>;
  prescriptions?: InputMaybe<Array<Scalars['String']['input']>>;
  primaryCIN?: InputMaybe<Scalars['String']['input']>;
  primaryInsuranceCompany?: InputMaybe<Scalars['String']['input']>;
  pronouns?: InputMaybe<Array<Pronouns>>;
  race?: InputMaybe<Array<Scalars['String']['input']>>;
  reasonForDeactivation?: InputMaybe<Scalars['String']['input']>;
  secondaryCIN?: InputMaybe<Scalars['String']['input']>;
  secondaryInsuranceCompany?: InputMaybe<Scalars['String']['input']>;
  sex?: InputMaybe<Sex>;
  sexualOrientation?: InputMaybe<Array<SexualOrientation>>;
  socialSecurityNumber?: InputMaybe<Scalars['String']['input']>;
  spokenLanguages?: InputMaybe<Array<Language>>;
  timezone?: InputMaybe<Scalars['String']['input']>;
};

export type MemberInputOutput = {
  __typename?: 'MemberInputOutput';
  contactInfo: ContactInfo;
  dob?: Maybe<Scalars['String']['output']>;
  ehrID?: Maybe<Scalars['String']['output']>;
  ehrIDType?: Maybe<Scalars['String']['output']>;
  ethnicity?: Maybe<Ethnicity>;
  firstName: Scalars['String']['output'];
  gender?: Maybe<Array<Gender>>;
  lastName: Scalars['String']['output'];
  medicaidStatus?: Maybe<EnrollmentStatus>;
  medicareStatus?: Maybe<EnrollmentStatus>;
  nickName?: Maybe<Scalars['String']['output']>;
  phonetic?: Maybe<Scalars['String']['output']>;
  primaryCIN?: Maybe<Scalars['String']['output']>;
  primaryInsuranceCompany?: Maybe<Scalars['String']['output']>;
  pronouns?: Maybe<Array<Pronouns>>;
  race?: Maybe<Array<Scalars['String']['output']>>;
  reasonForDeactivation?: Maybe<Scalars['String']['output']>;
  secondaryCIN?: Maybe<Scalars['String']['output']>;
  secondaryInsuranceCompany?: Maybe<Scalars['String']['output']>;
  sex?: Maybe<Sex>;
  sexualOrientation?: Maybe<Array<SexualOrientation>>;
  spokenLanguages?: Maybe<Array<Language>>;
  status?: Maybe<MemberStatus>;
  timezone?: Maybe<Scalars['String']['output']>;
};

export type MemberInsuranceAdditonalPayer = {
  __typename?: 'MemberInsuranceAdditonalPayer';
  _id: Scalars['String']['output'];
  insuredId?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  type?: Maybe<MemberInsuranceAdditonalPayerType>;
};

export const MemberInsuranceAdditonalPayerType = {
  Primary: 'Primary',
  Secondary: 'Secondary',
  Tertiary: 'Tertiary'
} as const;

export type MemberInsuranceAdditonalPayerType = typeof MemberInsuranceAdditonalPayerType[keyof typeof MemberInsuranceAdditonalPayerType];
export type MemberInsuranceCoverage = {
  __typename?: 'MemberInsuranceCoverage';
  _id: Scalars['String']['output'];
  asOfDate?: Maybe<Scalars['String']['output']>;
  availityExpirationDate?: Maybe<Scalars['String']['output']>;
  availityUpdatedDate?: Maybe<Scalars['String']['output']>;
  cardIssueDate?: Maybe<Scalars['String']['output']>;
  customerId?: Maybe<Scalars['String']['output']>;
  patientBirthDate?: Maybe<Scalars['String']['output']>;
  patientFirstName?: Maybe<Scalars['String']['output']>;
  patientGender?: Maybe<Scalars['String']['output']>;
  patientLastName?: Maybe<Scalars['String']['output']>;
  patientMiddleName?: Maybe<Scalars['String']['output']>;
  payerId?: Maybe<Scalars['String']['output']>;
  payerName?: Maybe<Scalars['String']['output']>;
  plans?: Maybe<Array<MemberInsurancePlan>>;
  procedureCodes?: Maybe<Array<Scalars['String']['output']>>;
  requestedServiceType?: Maybe<Array<MemberInsuranceRequestedServiceType>>;
  subscriberFirstName?: Maybe<Scalars['String']['output']>;
  subscriberLastName?: Maybe<Scalars['String']['output']>;
  subscriberMedicaidId?: Maybe<Scalars['String']['output']>;
  subscriberMemberId?: Maybe<Scalars['String']['output']>;
  subscriberMiddleName?: Maybe<Scalars['String']['output']>;
  subscriberRelationship?: Maybe<Scalars['String']['output']>;
  toDate?: Maybe<Scalars['String']['output']>;
};

export type MemberInsuranceDetail = {
  __typename?: 'MemberInsuranceDetail';
  _id: Scalars['ID']['output'];
  organizationId: Scalars['ID']['output'];
  primaryInsuranceCompany?: Maybe<InsuranceBillingConfigurationSummary>;
  secondaryInsuranceCompany?: Maybe<InsuranceBillingConfigurationSummary>;
};

export type MemberInsurancePlan = {
  __typename?: 'MemberInsurancePlan';
  _id: Scalars['String']['output'];
  additionalPayers: Array<MemberInsuranceAdditonalPayer>;
  coverageEndDate?: Maybe<Scalars['String']['output']>;
  coverageStartDate?: Maybe<Scalars['String']['output']>;
  eligibilityEndDate?: Maybe<Scalars['String']['output']>;
  eligibilityStartDate?: Maybe<Scalars['String']['output']>;
  groupName?: Maybe<Scalars['String']['output']>;
  groupNumber?: Maybe<Scalars['String']['output']>;
  insuranceType?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  statusCode?: Maybe<Scalars['String']['output']>;
};

export type MemberInsuranceRequestedServiceType = {
  __typename?: 'MemberInsuranceRequestedServiceType';
  code?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

export const MemberOverviewConfigurationCategoryType = {
  Historic: 'Historic',
  Label: 'Label',
  Score: 'Score'
} as const;

export type MemberOverviewConfigurationCategoryType = typeof MemberOverviewConfigurationCategoryType[keyof typeof MemberOverviewConfigurationCategoryType];
export type MemberOverviewConfigurationMetric = {
  __typename?: 'MemberOverviewConfigurationMetric';
  dataPointTemplateId?: Maybe<Scalars['String']['output']>;
  label?: Maybe<Scalars['String']['output']>;
};

export type MemberOverviewConfigurationMetricCategory = {
  __typename?: 'MemberOverviewConfigurationMetricCategory';
  metrics?: Maybe<Array<Maybe<MemberOverviewConfigurationMetric>>>;
  title?: Maybe<Scalars['String']['output']>;
  type?: Maybe<MemberOverviewConfigurationCategoryType>;
};

export type MemberOverviewConfigurationMetricCategoryInput = {
  metrics?: InputMaybe<Array<InputMaybe<MemberOverviewConfigurationMetricInput>>>;
  title: Scalars['String']['input'];
  type?: InputMaybe<MemberOverviewConfigurationCategoryType>;
};

export type MemberOverviewConfigurationMetricInput = {
  dataPointTemplateId: Scalars['String']['input'];
  label?: InputMaybe<Scalars['String']['input']>;
};

export type MemberOverviewConfigurationMetricResult = {
  __typename?: 'MemberOverviewConfigurationMetricResult';
  dataId?: Maybe<Scalars['String']['output']>;
  dataPointTemplateId: Scalars['String']['output'];
  granularData: Array<MetricGranularData>;
  label: Scalars['String']['output'];
  resultLabelValue?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

export type MemberOverviewMetricCategoryResult = {
  __typename?: 'MemberOverviewMetricCategoryResult';
  metrics: Array<MemberOverviewConfigurationMetricResult>;
  title: Scalars['String']['output'];
  type?: Maybe<MemberOverviewConfigurationCategoryType>;
};

export type MemberProfileSettings = {
  __typename?: 'MemberProfileSettings';
  memberAddressHidden?: Maybe<Scalars['Boolean']['output']>;
  memberChildrenInHouseholdHidden?: Maybe<Scalars['Boolean']['output']>;
  memberDOBHidden?: Maybe<Scalars['Boolean']['output']>;
  memberEmployerNameHidden?: Maybe<Scalars['Boolean']['output']>;
  memberEmploymentStatusHidden?: Maybe<Scalars['Boolean']['output']>;
  memberEthnicityHidden?: Maybe<Scalars['Boolean']['output']>;
  memberGenderHidden?: Maybe<Scalars['Boolean']['output']>;
  memberHighestLevelOfEducationHidden?: Maybe<Scalars['Boolean']['output']>;
  memberLanguagesHidden?: Maybe<Scalars['Boolean']['output']>;
  memberLinkedContactsHidden?: Maybe<Scalars['Boolean']['output']>;
  memberMaidenNameOrAliasHidden?: Maybe<Scalars['Boolean']['output']>;
  memberMaritalStatusHidden?: Maybe<Scalars['Boolean']['output']>;
  memberMedicalRecordNumberHidden?: Maybe<Scalars['Boolean']['output']>;
  memberOrgGroupsHidden?: Maybe<Scalars['Boolean']['output']>;
  memberPeopleInHouseholdHidden?: Maybe<Scalars['Boolean']['output']>;
  memberPhoneticHidden?: Maybe<Scalars['Boolean']['output']>;
  memberPreferredNameHidden?: Maybe<Scalars['Boolean']['output']>;
  memberPregnancyHidden?: Maybe<Scalars['Boolean']['output']>;
  memberPrimaryInsuranceHidden?: Maybe<Scalars['Boolean']['output']>;
  memberPronounsHidden?: Maybe<Scalars['Boolean']['output']>;
  memberRaceHidden?: Maybe<Scalars['Boolean']['output']>;
  memberSecondaryInsuranceHidden?: Maybe<Scalars['Boolean']['output']>;
  memberSexHidden?: Maybe<Scalars['Boolean']['output']>;
  memberSexualOrientationHidden?: Maybe<Scalars['Boolean']['output']>;
  memberSocialSecurityNumberHidden?: Maybe<Scalars['Boolean']['output']>;
};

export type MemberProfileSettingsInput = {
  memberAddressHidden?: InputMaybe<Scalars['Boolean']['input']>;
  memberChildrenInHouseholdHidden?: InputMaybe<Scalars['Boolean']['input']>;
  memberDOBHidden?: InputMaybe<Scalars['Boolean']['input']>;
  memberEmployerNameHidden?: InputMaybe<Scalars['Boolean']['input']>;
  memberEmploymentStatusHidden?: InputMaybe<Scalars['Boolean']['input']>;
  memberEthnicityHidden?: InputMaybe<Scalars['Boolean']['input']>;
  memberGenderHidden?: InputMaybe<Scalars['Boolean']['input']>;
  memberHighestLevelOfEducationHidden?: InputMaybe<Scalars['Boolean']['input']>;
  memberLanguagesHidden?: InputMaybe<Scalars['Boolean']['input']>;
  memberLinkedContactsHidden?: InputMaybe<Scalars['Boolean']['input']>;
  memberMaidenNameOrAliasHidden?: InputMaybe<Scalars['Boolean']['input']>;
  memberMaritalStatusHidden?: InputMaybe<Scalars['Boolean']['input']>;
  memberMedicalRecordNumberHidden?: InputMaybe<Scalars['Boolean']['input']>;
  memberOrgGroupsHidden?: InputMaybe<Scalars['Boolean']['input']>;
  memberPeopleInHouseholdHidden?: InputMaybe<Scalars['Boolean']['input']>;
  memberPhoneticHidden?: InputMaybe<Scalars['Boolean']['input']>;
  memberPreferredNameHidden?: InputMaybe<Scalars['Boolean']['input']>;
  memberPregnancyHidden?: InputMaybe<Scalars['Boolean']['input']>;
  memberPrimaryInsuranceHidden?: InputMaybe<Scalars['Boolean']['input']>;
  memberPronounsHidden?: InputMaybe<Scalars['Boolean']['input']>;
  memberRaceHidden?: InputMaybe<Scalars['Boolean']['input']>;
  memberSecondaryInsuranceHidden?: InputMaybe<Scalars['Boolean']['input']>;
  memberSexHidden?: InputMaybe<Scalars['Boolean']['input']>;
  memberSexualOrientationHidden?: InputMaybe<Scalars['Boolean']['input']>;
  memberSocialSecurityNumberHidden?: InputMaybe<Scalars['Boolean']['input']>;
};

export type MemberSearchInput = {
  dob?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  gender?: InputMaybe<Gender>;
  id?: InputMaybe<Scalars['String']['input']>;
  idType?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  nickName?: InputMaybe<Scalars['String']['input']>;
  organizationId: Scalars['ID']['input'];
  pronouns?: InputMaybe<Pronouns>;
};

export type MemberSearchResponse = {
  __typename?: 'MemberSearchResponse';
  data?: Maybe<Array<MemberInputOutput>>;
  message?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

export type MemberStat = {
  __typename?: 'MemberStat';
  _id: Scalars['ID']['output'];
  assignedUsers?: Maybe<Scalars['String']['output']>;
  completedGoalDate?: Maybe<Scalars['String']['output']>;
  groups?: Maybe<Scalars['String']['output']>;
  memberId: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  nextActivityDate?: Maybe<Scalars['String']['output']>;
  numCompletedActivities: Scalars['Int']['output'];
  numCompletedGoals: Scalars['Int']['output'];
  numGoals: Scalars['Int']['output'];
  numScheduledActivities: Scalars['Int']['output'];
  prevActivityDate?: Maybe<Scalars['String']['output']>;
  status?: Maybe<MemberStatus>;
};

export type MemberStatFilter = {
  condition: FilterCondition;
  filter: MemberStatsFilterProp;
  filterValue: Scalars['String']['input'];
};

export const MemberStatsFilterProp = {
  AssignedUsers: 'assignedUsers',
  CompletedGoalDate: 'completedGoalDate',
  Groups: 'groups',
  NextActivityDate: 'nextActivityDate',
  NumCompletedActivities: 'numCompletedActivities',
  NumCompletedGoals: 'numCompletedGoals',
  NumGoals: 'numGoals',
  NumScheduledActivities: 'numScheduledActivities',
  PrevActivityDate: 'prevActivityDate'
} as const;

export type MemberStatsFilterProp = typeof MemberStatsFilterProp[keyof typeof MemberStatsFilterProp];
export const MemberStatus = {
  Active: 'Active',
  ClosedSuccessful: 'ClosedSuccessful',
  ClosedUnsuccessful: 'ClosedUnsuccessful',
  Declined: 'Declined'
} as const;

export type MemberStatus = typeof MemberStatus[keyof typeof MemberStatus];
export type MetricGranularData = {
  __typename?: 'MetricGranularData';
  date: Scalars['String']['output'];
  value: Scalars['Float']['output'];
};

export const MetricValueType = {
  Avg: 'AVG',
  Count: 'COUNT',
  Max: 'MAX',
  Min: 'MIN',
  Sum: 'SUM'
} as const;

export type MetricValueType = typeof MetricValueType[keyof typeof MetricValueType];
export const MfaPolicy = {
  None: 'None',
  Sms: 'Sms'
} as const;

export type MfaPolicy = typeof MfaPolicy[keyof typeof MfaPolicy];
export type Mutation = {
  __typename?: 'Mutation';
  activityPing: GetActivityResponse;
  addDefaultAnsweringUser: DefaultResponse;
  addMemberToActivity: GetActivityResponse;
  addMemberToGroup: DefaultResponse;
  addParticipantToConferenceCall: DefaultResponse;
  assignUserToMember: DefaultResponse;
  bulkAssignUsersToMembers: DefaultResponse;
  bulkCreateData: BulkDataResponse;
  changeUserPassword: GetUserResponse;
  claimIncomingPhoneCall: ClaimIncomingPhoneCallResponse;
  closeEpisodeOfCare: EpisodeOfCareResponse;
  closeMember: GetMemberResponse;
  connectMemberWithRedox: GetMemberResponse;
  copyCarePathwayTemplate: CarePathwayTemplateResponse;
  copyEmailTemplate: GetEmailTemplateResponse;
  copyFlowTemplate: GetFlowTemplateResponse;
  createAPIKey?: Maybe<GetApiKeyResponse>;
  createActivity: CreateActivityResponse;
  createActivityTemplate: GetActivityTemplateResponse;
  createCalculation: GetCalculationResponse;
  createCalendarEvent: CreateCalendarEventResponse;
  createCarePlanTemplate: CarePlanTemplateResponse;
  createData: DataResponse;
  createDataExport?: Maybe<DataExportResponse>;
  createDataImport?: Maybe<DataImportResponse>;
  createDomain: DomainResponse;
  createEHRNoteTemplate: GetEhrNoteTemplateResponse;
  createEmailTemplate: GetEmailTemplateResponse;
  createEpisodeOfCare: EpisodeOfCareResponse;
  createEventTemplate: GetEventTemplateResponse;
  createExternalResource: GetExternalResourceResponse;
  createExternalResourceContact: GetExternalResourceContactResponse;
  createFlow: CreateFlowResponse;
  createFlowTemplate: GetFlowTemplateResponse;
  createGoal: CreateGoalResponse;
  createGoalTemplate: GetGoalTemplateRes;
  createGroup: GetGroupResponse;
  createGroupActivities: CreateGroupActivityResponse;
  createMember: CreateOrUpdateMemberResponse;
  createNote: GetNotesResponse;
  createNoteTemplate: GetNoteTemplateResponse;
  createOrUpdateBillingProvider: GetBillingProviderResponse;
  createOrUpdateCarePathwayTemplate: CarePathwayTemplateResponse;
  createOrUpdateCarePlanTemplate: CarePlanTemplateResponse;
  createOrUpdateCertification: CertificationResponse;
  createOrUpdateCertificationTemplate: CertificationTemplateResponse;
  createOrUpdateConsent: ConsentResponse;
  createOrUpdateConsentTemplate: ConsentTemplateResponse;
  createOrUpdateCostConfigurations: GetInsuranceBillingConfigurationsResponse;
  createOrUpdateDataExportTemplate?: Maybe<DataExportTemplateResponse>;
  createOrUpdateDataImportTemplate?: Maybe<DataImportTemplateResponse>;
  createOrUpdateDataPointTemplate: GetDataPointTemplateResponse;
  createOrUpdateInNetworkInsuranceBillingConfiguration: GetInsuranceBillingConfigurationResponse;
  createOrUpdateOrganizationInsuranceBillingConfiguration: GetOrganizationResponse;
  createOrUpdateRole: GetRoleResponse;
  createOrganization: GetOrganizationResponse;
  createProviderRecommendation: CreateProviderRecommendationResponse;
  createQuestion: GetQuestionResponse;
  createRecommendation: GetRecommendationResponse;
  createReferral: CreateReferralResponse;
  createReferralTemplate: GetReferralTemplateResponse;
  createSignatureRequest: CreateSignatureRequestResponse;
  createTag: GetTagResponse;
  createUserGroup: GetUserGroupResponse;
  csvUploadProviderRecommendationForMembers: DefaultResponse;
  deleteAPIKey?: Maybe<GetApiKeyResponse>;
  deleteActivity: DefaultResponse;
  deleteBill: DefaultResponse;
  deleteBillingProvider: DefaultResponse;
  deleteCalculation: GetCalculationResponse;
  deleteCarePathway: CarePathwayResponse;
  deleteCarePathwayTemplate: CarePathwayTemplateResponse;
  deleteCarePlanTemplate: CarePlanTemplateResponse;
  deleteCertificationTemplate: CertificationTemplateResponse;
  deleteConsentTemplate: ConsentTemplateResponse;
  deleteCostConfiguration: GetInsuranceBillingConfigurationsResponse;
  deleteData: DefaultResponse;
  deleteDataExportTemplate?: Maybe<DataExportTemplateResponse>;
  deleteDataImportTemplate?: Maybe<DataImportTemplateResponse>;
  deleteDataPointTemplate: GetDataPointTemplateResponse;
  deleteDomain: DomainResponse;
  deleteEHRNoteTemplate: DefaultResponse;
  deleteEmailTemplate: GetEmailTemplateResponse;
  deleteEventTemplate: GetEventTemplateResponse;
  deleteFlow: DefaultResponse;
  deleteGoal: GetGoalResponse;
  deleteGoalTemplate: GetGoalTemplateRes;
  deleteGroup: GetGroupResponse;
  deleteInsuranceBillingConfiguration: DefaultResponse;
  deleteNoteTemplate: DefaultResponse;
  deleteNotification: NotificationResponse;
  deleteOnboardingSurvey: DefaultResponse;
  deleteOrganization: GetOrganizationResponse;
  deletePSFile: GetFileResponse;
  deleteProviderRecommendationBatch: DefaultResponse;
  deleteQuestion: GetQuestionResponse;
  deleteRecommendation: GetRecommendationResponse;
  deleteRole: DefaultResponse;
  deleteTag: GetTagResponse;
  deleteTextMessageThread: TextMessageThreadResponse;
  deleteUser: GetUserResponse;
  deleteUserGroup: GetUserGroupResponse;
  deleteVoicemail: DefaultResponse;
  duplicateActivityTemplate: DefaultResponse;
  duplicateCarePathway: DefaultResponse;
  duplicateEmailTemplate: DefaultResponse;
  duplicateFlowTemplate: DefaultResponse;
  duplicateGoalTemplate: DefaultResponse;
  duplicateQuestions: DefaultResponse;
  duplicateRecommendation: DefaultResponse;
  exchangeOneTimeIdToken: DefaultResponse;
  exportBills: DefaultResponse;
  exportDataPointTemplateData: DefaultResponse;
  exportFlowTemplateData: DefaultResponse;
  generateBill: GetBillResponse;
  generateBillsForOrganization: DefaultResponse;
  getOnboardingLink: GetOnboardingLinkResponse;
  hideRecommendationForMember: DefaultResponse;
  inCallPing: DefaultResponse;
  inviteUser?: Maybe<GetUserResponse>;
  keepalivePing?: Maybe<DefaultResponse>;
  linkRecommendingProviderToOrganization: GetUserResponse;
  makeCarePathwayTemplateDefault: CarePathwayTemplateResponse;
  markAllNotificationAsRead: NotificationsResponse;
  markNotificationAsRead: NotificationResponse;
  performRecommendationAction: CompleteActionResponse;
  reactivateMember: GetMemberResponse;
  reactivateUser: GetUserResponse;
  referMember: GetMemberResponse;
  removeCalendarEvent: RemoveCalendarEventResponse;
  removeCarePathwayTemplateFromDefault: CarePathwayTemplateResponse;
  removeDefaultAnsweringUser: DefaultResponse;
  removeMemberFromActivity: GetActivityResponse;
  removeMemberFromGroup: DefaultResponse;
  renameOrganizationInsuranceBillingConfiguration: GetOrganizationResponse;
  resendEmailInvoiceBatch: GetBillBatchResponse;
  restoreOrganization: GetOrganizationResponse;
  retireActivityTemplate: GetActivityTemplateResponse;
  retireCarePathwayTemplate: CarePathwayTemplateResponse;
  retireExternalResource: GetExternalResourceResponse;
  retireExternalResourceContact: GetExternalResourceContactResponse;
  retireFlowTemplateFamily: GetFlowTemplateFamilyResponse;
  retireReferralTemplate: GetReferralTemplateResponse;
  retireUser: GetUserResponse;
  revokeCertification: CertificationResponse;
  revokeConsent: ConsentResponse;
  revokeTraining: TrainingResponse;
  revokeUserIntegration: RevokeUserIntegrationResponse;
  runCalculationForMembers: RunCalculationForMembersResponse;
  sendNotificationManually: NotificationResponse;
  sendTextMessage: SendTextMessageResponse;
  setPassword: DefaultResponse;
  startCarePathwayTemplateForMember: CarePathwayResponse;
  submitAssessment: SubmitAssessmentResponse;
  swapOrganizationInsuranceBillingConfiguration: DefaultResponse;
  toggleCarePlans: DefaultResponse;
  toggleEpisodesOfCare: DefaultResponse;
  unassignUserFromMember: DefaultResponse;
  undoLastFlowProgress: DefaultResponse;
  unlinkRecommendingProviderFromOrganization: GetUserResponse;
  updateAPIKey?: Maybe<GetApiKeyResponse>;
  updateAccessForObject: UpdateAccessForObjectResponse;
  updateActivity: GetActivityResponse;
  updateActivityTaskStatus: GetActivityResponse;
  updateActivityTemplate?: Maybe<GetActivityTemplateResponse>;
  updateBill: GetBillResponse;
  updateBills: GetBillBatchResponse;
  updateCalculation: GetCalculationResponse;
  updateCalendarEvent: UpdateCalendarEventResponse;
  updateCallRoutingConfiguration: GetCallRoutingConfigurationResponse;
  updateCarePathwayStatus: CarePathwayResponse;
  updateCarePathwayTemplateDefaultAssignedUserGroup: CarePathwayTemplateResponse;
  updateCoverageDetails: GetMemberResponse;
  updateData: DataResponse;
  updateEHRNoteTemplate: GetEhrNoteTemplateResponse;
  updateEmailTemplate?: Maybe<GetEmailTemplateResponse>;
  updateEpisodeOfCare: EpisodeOfCareResponse;
  updateEventMemberAttendance: UpdateEventMemberAttendanceResponse;
  updateEventTemplate?: Maybe<GetEventTemplateResponse>;
  updateExternalResource: GetExternalResourceResponse;
  updateExternalResourceContact: GetExternalResourceContactResponse;
  updateFlowFromSnapshotEditor: UpdateFlowFromSnapshotEditorResponse;
  updateFlowProgress: CompleteActionResponse;
  updateFlowTemplate: GetFlowTemplateResponse;
  updateGoal: GetGoalResponse;
  updateGoalProgress: GetGoalResponse;
  updateGoalStatus: GetGoalResponse;
  updateGoalTemplate: GetGoalTemplateRes;
  updateGroup: GetGroupResponse;
  updateMember: CreateOrUpdateMemberResponse;
  updateMemberOverriddenProcedureCodeLimits: GetMemberResponse;
  updateNote: GetNoteResponse;
  updateNoteTemplate: GetNoteTemplateResponse;
  updateNotificationSettings: NotificationSettingsResponse;
  updateOnboardingSurvey: GetOnboardingLinkResponse;
  updateOrganization: UpdateOrganizationResponse;
  updateOrganizationBillingConfiguration: GetOrganizationResponse;
  updatePSFile: GetFileResponse;
  updatePhoneCallEntry: PhoneCallEntryResponse;
  updateProviderRecommendationBatchStatus: UpdateProviderRecommendationBatchStatusResponse;
  updateProviderRecommendationStatus: UpdateProviderRecommendationStatusResponse;
  updateQuestion: GetQuestionResponse;
  updateRecommendation: GetRecommendationResponse;
  updateReferralStatus: GetReferralResponse;
  updateReferralStatusPublic: GetReferralResponse;
  updateReferralTemplate: GetReferralTemplateResponse;
  updateTag: GetTagResponse;
  updateTextMessageThreadRead: TextMessageThreadResponse;
  updateUser: GetUserResponse;
  updateUserGroup: GetUserGroupResponse;
  updateVoicemail: VoicemailResponse;
  updateVoicemailByCall: VoicemailResponse;
  uploadPSFile: GetFileResponse;
  uploadProviderRecommendationForMembers: DefaultResponse;
  upsertDomain: DomainResponse;
  upsertDomainDescription: DefaultResponse;
  upsertRecommendingProvider: RecommendingProviderResponse;
  upsertTraining: TrainingResponse;
  voiceGrantAccessToken: VoiceGrantAccessTokenResponse;
};


export type MutationActivityPingArgs = {
  input: ActivityPingInput;
};


export type MutationAddDefaultAnsweringUserArgs = {
  organizationId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
};


export type MutationAddMemberToActivityArgs = {
  input: ActivityMemberInput;
};


export type MutationAddMemberToGroupArgs = {
  groupId: Scalars['ID']['input'];
  memberId: Scalars['ID']['input'];
  organizationId: Scalars['ID']['input'];
};


export type MutationAddParticipantToConferenceCallArgs = {
  input: AddParticipantToConferenceCallInput;
};


export type MutationAssignUserToMemberArgs = {
  input: UserMemberInput;
};


export type MutationBulkAssignUsersToMembersArgs = {
  input: BulkUserAssignmentInput;
};


export type MutationBulkCreateDataArgs = {
  input: BulkCreateDataInput;
};


export type MutationChangeUserPasswordArgs = {
  newPassword: Scalars['String']['input'];
  userId: Scalars['ID']['input'];
};


export type MutationClaimIncomingPhoneCallArgs = {
  organizationId: Scalars['ID']['input'];
  phoneCallId: Scalars['ID']['input'];
};


export type MutationCloseEpisodeOfCareArgs = {
  memberId: Scalars['String']['input'];
  organizationId: Scalars['ID']['input'];
  reasonForClosure: Scalars['String']['input'];
};


export type MutationCloseMemberArgs = {
  input: CloseMemberInput;
};


export type MutationConnectMemberWithRedoxArgs = {
  memberId: Scalars['ID']['input'];
  organizationId: Scalars['ID']['input'];
  search?: InputMaybe<MemberSearchInput>;
};


export type MutationCopyCarePathwayTemplateArgs = {
  input: CopyCarePathwayTemplateInput;
};


export type MutationCopyEmailTemplateArgs = {
  input: CopyEmailTemplateInput;
};


export type MutationCopyFlowTemplateArgs = {
  input: CopyFlowTemplateInput;
};


export type MutationCreateApiKeyArgs = {
  input: ApiKeyInput;
};


export type MutationCreateActivityArgs = {
  input: ActivityInput;
};


export type MutationCreateActivityTemplateArgs = {
  input: ActivityTemplateInput;
};


export type MutationCreateCalculationArgs = {
  input: CreateCalculationInput;
};


export type MutationCreateCalendarEventArgs = {
  input: CreateCalendarEventInput;
};


export type MutationCreateCarePlanTemplateArgs = {
  input: CreateCarePlanTemplateInput;
};


export type MutationCreateDataArgs = {
  input: CreateDataInput;
};


export type MutationCreateDataExportArgs = {
  input: DataExportInput;
};


export type MutationCreateDataImportArgs = {
  input: DataImportInput;
};


export type MutationCreateDomainArgs = {
  input: DomainInput;
};


export type MutationCreateEhrNoteTemplateArgs = {
  input: CreateEhrNoteTemplateInput;
};


export type MutationCreateEmailTemplateArgs = {
  input: EmailTemplateInput;
};


export type MutationCreateEpisodeOfCareArgs = {
  input: EpisodeOfCareInput;
};


export type MutationCreateEventTemplateArgs = {
  input: EventTemplateInput;
};


export type MutationCreateExternalResourceArgs = {
  input: ExternalResourceInput;
};


export type MutationCreateExternalResourceContactArgs = {
  input: ExternalResourceContactInput;
};


export type MutationCreateFlowArgs = {
  input: CreateFlowInput;
};


export type MutationCreateFlowTemplateArgs = {
  input: CreateFlowTemplateInput;
};


export type MutationCreateGoalArgs = {
  input: CreateGoalInput;
};


export type MutationCreateGoalTemplateArgs = {
  input: GoalTemplateInput;
};


export type MutationCreateGroupArgs = {
  input: CreateGroupInput;
};


export type MutationCreateGroupActivitiesArgs = {
  input: GroupActivityInput;
};


export type MutationCreateMemberArgs = {
  input: MemberInput;
};


export type MutationCreateNoteArgs = {
  input: CreateNoteInput;
};


export type MutationCreateNoteTemplateArgs = {
  input: CreateNoteTemplateInput;
};


export type MutationCreateOrUpdateBillingProviderArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
  input: BillingProviderInput;
};


export type MutationCreateOrUpdateCarePathwayTemplateArgs = {
  input: CarePathwayTemplateInput;
};


export type MutationCreateOrUpdateCarePlanTemplateArgs = {
  input: CarePlanTemplateInput;
};


export type MutationCreateOrUpdateCertificationArgs = {
  input: CertificationInput;
};


export type MutationCreateOrUpdateCertificationTemplateArgs = {
  input: CertificationTemplateInput;
};


export type MutationCreateOrUpdateConsentArgs = {
  input: ConsentInput;
};


export type MutationCreateOrUpdateConsentTemplateArgs = {
  input: ConsentTemplateInput;
};


export type MutationCreateOrUpdateCostConfigurationsArgs = {
  input: CreateOrUpdateBillingCostConfigurationInput;
};


export type MutationCreateOrUpdateDataExportTemplateArgs = {
  input: DataExportTemplateInput;
};


export type MutationCreateOrUpdateDataImportTemplateArgs = {
  input: DataImportTemplateInput;
};


export type MutationCreateOrUpdateDataPointTemplateArgs = {
  input: DataPointTemplateInput;
};


export type MutationCreateOrUpdateInNetworkInsuranceBillingConfigurationArgs = {
  input: InsuranceBillingConfigurationInput;
};


export type MutationCreateOrUpdateOrganizationInsuranceBillingConfigurationArgs = {
  input: CreateOrUpdateOrganizationBillingConfigurationInsuranceInput;
  organizationId: Scalars['ID']['input'];
};


export type MutationCreateOrUpdateRoleArgs = {
  input: CreateOrUpdateRoleInput;
};


export type MutationCreateOrganizationArgs = {
  input: OrganizationInput;
};


export type MutationCreateProviderRecommendationArgs = {
  input: CreateProviderRecommendationInput;
};


export type MutationCreateQuestionArgs = {
  input: QuestionInput;
};


export type MutationCreateRecommendationArgs = {
  input: CreateRecommendationInput;
};


export type MutationCreateReferralArgs = {
  input: CreateReferralInput;
};


export type MutationCreateReferralTemplateArgs = {
  input: ReferralTemplateInput;
};


export type MutationCreateSignatureRequestArgs = {
  input: SignatureRequestInput;
};


export type MutationCreateTagArgs = {
  input: CreateTagInput;
};


export type MutationCreateUserGroupArgs = {
  input: CreateUserGroupInput;
};


export type MutationCsvUploadProviderRecommendationForMembersArgs = {
  input: CsvUploadProviderRecommendationForMembersInput;
};


export type MutationDeleteApiKeyArgs = {
  apiKeyId: Scalars['String']['input'];
  organizationId: Scalars['String']['input'];
};


export type MutationDeleteActivityArgs = {
  activityId: Scalars['ID']['input'];
  organizationId: Scalars['ID']['input'];
};


export type MutationDeleteBillArgs = {
  input: DeleteBillInput;
};


export type MutationDeleteBillingProviderArgs = {
  id: Scalars['ID']['input'];
  organizationId?: InputMaybe<Scalars['ID']['input']>;
  userId?: InputMaybe<Scalars['ID']['input']>;
};


export type MutationDeleteCalculationArgs = {
  calculationId: Scalars['String']['input'];
  organizationId: Scalars['String']['input'];
};


export type MutationDeleteCarePathwayArgs = {
  carePathwayId: Scalars['ID']['input'];
  memberId: Scalars['ID']['input'];
  organizationId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
};


export type MutationDeleteCarePathwayTemplateArgs = {
  carePathwayTemplateId: Scalars['ID']['input'];
  organizationId: Scalars['ID']['input'];
};


export type MutationDeleteCarePlanTemplateArgs = {
  carePlanTemplateId: Scalars['ID']['input'];
};


export type MutationDeleteCertificationTemplateArgs = {
  certificationTemplateId: Scalars['ID']['input'];
  organizationId: Scalars['ID']['input'];
};


export type MutationDeleteConsentTemplateArgs = {
  consentTemplateId: Scalars['ID']['input'];
  organizationId: Scalars['ID']['input'];
};


export type MutationDeleteCostConfigurationArgs = {
  costId: Scalars['ID']['input'];
  organizationId?: InputMaybe<Scalars['ID']['input']>;
};


export type MutationDeleteDataArgs = {
  dataId: Scalars['ID']['input'];
};


export type MutationDeleteDataExportTemplateArgs = {
  id: Scalars['ID']['input'];
  organizationId: Scalars['ID']['input'];
};


export type MutationDeleteDataImportTemplateArgs = {
  id: Scalars['ID']['input'];
  organizationId: Scalars['ID']['input'];
};


export type MutationDeleteDataPointTemplateArgs = {
  input: DeleteDataPointTemplateInput;
};


export type MutationDeleteDomainArgs = {
  domainId: Scalars['ID']['input'];
  organizationId: Scalars['ID']['input'];
};


export type MutationDeleteEhrNoteTemplateArgs = {
  input: DeleteEhrNoteTemplateInput;
};


export type MutationDeleteEmailTemplateArgs = {
  input: EmailTemplateIdAndOrgInput;
};


export type MutationDeleteEventTemplateArgs = {
  id: Scalars['ID']['input'];
  organizationId: Scalars['ID']['input'];
};


export type MutationDeleteFlowArgs = {
  flowId: Scalars['ID']['input'];
  organizationId: Scalars['ID']['input'];
};


export type MutationDeleteGoalArgs = {
  input: DeleteGoalInput;
};


export type MutationDeleteGoalTemplateArgs = {
  input: DeleteGoalTemplateInput;
};


export type MutationDeleteGroupArgs = {
  input: DeleteGroupInput;
};


export type MutationDeleteInsuranceBillingConfigurationArgs = {
  insurance: Scalars['String']['input'];
  organizationId?: InputMaybe<Scalars['ID']['input']>;
};


export type MutationDeleteNoteTemplateArgs = {
  input: GetNoteTemplateInput;
};


export type MutationDeleteNotificationArgs = {
  notificationId: Scalars['ID']['input'];
};


export type MutationDeleteOnboardingSurveyArgs = {
  organizationId: Scalars['ID']['input'];
  surveyId: Scalars['ID']['input'];
};


export type MutationDeleteOrganizationArgs = {
  organizationId: Scalars['ID']['input'];
  permanently?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationDeletePsFileArgs = {
  id: Scalars['ID']['input'];
  organizationId: Scalars['ID']['input'];
};


export type MutationDeleteProviderRecommendationBatchArgs = {
  batchId: Scalars['ID']['input'];
  recommendingProviderId: Scalars['ID']['input'];
};


export type MutationDeleteQuestionArgs = {
  input: DeleteQuestionInput;
};


export type MutationDeleteRecommendationArgs = {
  organizationId: Scalars['ID']['input'];
  recommendationId: Scalars['ID']['input'];
};


export type MutationDeleteRoleArgs = {
  input: DeleteRoleInput;
};


export type MutationDeleteTagArgs = {
  id: Scalars['ID']['input'];
  organizationId: Scalars['ID']['input'];
};


export type MutationDeleteTextMessageThreadArgs = {
  organizationId: Scalars['String']['input'];
  participantPhone: Scalars['String']['input'];
};


export type MutationDeleteUserArgs = {
  organizationId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
};


export type MutationDeleteUserGroupArgs = {
  input: DeleteUserGroupInput;
};


export type MutationDeleteVoicemailArgs = {
  organizationId: Scalars['ID']['input'];
  voicemailId: Scalars['ID']['input'];
};


export type MutationDuplicateActivityTemplateArgs = {
  input: DuplicateActivityTemplateInput;
};


export type MutationDuplicateCarePathwayArgs = {
  input: DuplicateCarePathwayInput;
};


export type MutationDuplicateEmailTemplateArgs = {
  input: DuplicateEmailTemplateInput;
};


export type MutationDuplicateFlowTemplateArgs = {
  input: DuplicateFlowTemplateInput;
};


export type MutationDuplicateGoalTemplateArgs = {
  input: DuplicateGoalInput;
};


export type MutationDuplicateQuestionsArgs = {
  input: DuplicateQuestionsInput;
};


export type MutationDuplicateRecommendationArgs = {
  input: DuplicateRecommendationInput;
};


export type MutationExchangeOneTimeIdTokenArgs = {
  oneTimeIdToken: Scalars['String']['input'];
};


export type MutationExportBillsArgs = {
  input: GetBillsInput;
  search: Scalars['String']['input'];
};


export type MutationExportDataPointTemplateDataArgs = {
  input: ExportDataPointTemplateDataInput;
};


export type MutationExportFlowTemplateDataArgs = {
  input: ExportFlowTemplateInput;
};


export type MutationGenerateBillArgs = {
  input: GenerateBillInput;
};


export type MutationGenerateBillsForOrganizationArgs = {
  organizationId: Scalars['ID']['input'];
};


export type MutationGetOnboardingLinkArgs = {
  input: GetOnboardingLinkInput;
};


export type MutationHideRecommendationForMemberArgs = {
  generatedForMemberAt: Scalars['String']['input'];
  memberId: Scalars['ID']['input'];
  organizationId: Scalars['ID']['input'];
  recommendationId: Scalars['ID']['input'];
};


export type MutationInviteUserArgs = {
  input: InviteUserInput;
};


export type MutationKeepalivePingArgs = {
  clientId: Scalars['String']['input'];
};


export type MutationLinkRecommendingProviderToOrganizationArgs = {
  input: LinkRecommendingProviderInput;
};


export type MutationMakeCarePathwayTemplateDefaultArgs = {
  carePathwayTemplateId: Scalars['ID']['input'];
  organizationId: Scalars['ID']['input'];
};


export type MutationMarkAllNotificationAsReadArgs = {
  organizationId: Scalars['ID']['input'];
};


export type MutationMarkNotificationAsReadArgs = {
  notificationId: Scalars['ID']['input'];
};


export type MutationPerformRecommendationActionArgs = {
  actionData: ActionDataInput;
  generatedForMemberAt: Scalars['String']['input'];
  memberId: Scalars['ID']['input'];
  organizationId: Scalars['ID']['input'];
  recommendationId: Scalars['ID']['input'];
  scheduleActivityInput?: InputMaybe<ScheduleActivityInput>;
  userId: Scalars['ID']['input'];
};


export type MutationReactivateMemberArgs = {
  memberId: Scalars['ID']['input'];
  organizationId: Scalars['ID']['input'];
};


export type MutationReactivateUserArgs = {
  organizationId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
};


export type MutationReferMemberArgs = {
  input: ReferMemberInput;
  organizationId: Scalars['ID']['input'];
};


export type MutationRemoveCalendarEventArgs = {
  input: RemoveCalendarEventInput;
};


export type MutationRemoveCarePathwayTemplateFromDefaultArgs = {
  carePathwayTemplateId: Scalars['ID']['input'];
  organizationId: Scalars['ID']['input'];
};


export type MutationRemoveDefaultAnsweringUserArgs = {
  organizationId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
};


export type MutationRemoveMemberFromActivityArgs = {
  input: ActivityMemberInput;
};


export type MutationRemoveMemberFromGroupArgs = {
  groupId: Scalars['ID']['input'];
  memberId: Scalars['ID']['input'];
  organizationId: Scalars['ID']['input'];
};


export type MutationRenameOrganizationInsuranceBillingConfigurationArgs = {
  id: Scalars['ID']['input'];
  name: Scalars['String']['input'];
  organizationId: Scalars['ID']['input'];
};


export type MutationResendEmailInvoiceBatchArgs = {
  input: ResendEmailInvoiceBatchInput;
};


export type MutationRestoreOrganizationArgs = {
  organizationId: Scalars['ID']['input'];
};


export type MutationRetireActivityTemplateArgs = {
  input: ActivityTemplateIdAndOrgInput;
};


export type MutationRetireCarePathwayTemplateArgs = {
  input: CarePathwayTemplateIdAndOrgInput;
};


export type MutationRetireExternalResourceArgs = {
  input: ExternalResourceIdAndOrgInput;
};


export type MutationRetireExternalResourceContactArgs = {
  input: RetireExternalResourceContactInput;
};


export type MutationRetireFlowTemplateFamilyArgs = {
  input: FlowTemplateFamilyIdAndOrgInput;
};


export type MutationRetireReferralTemplateArgs = {
  input: RetireReferralTemplateInput;
};


export type MutationRetireUserArgs = {
  organizationId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
};


export type MutationRevokeCertificationArgs = {
  certificationId: Scalars['ID']['input'];
  organizationId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
};


export type MutationRevokeConsentArgs = {
  consentId: Scalars['ID']['input'];
  organizationId: Scalars['ID']['input'];
};


export type MutationRevokeTrainingArgs = {
  organizationId: Scalars['ID']['input'];
  trainingId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
};


export type MutationRevokeUserIntegrationArgs = {
  input: RevokeUserIntegrationInput;
};


export type MutationRunCalculationForMembersArgs = {
  input: RunCalculationForMembersInput;
};


export type MutationSendNotificationManuallyArgs = {
  channels: Array<NotificationChannel>;
  organizationId: Scalars['ID']['input'];
  renderedContent: Scalars['String']['input'];
  type: NotificationType;
  userIds: Array<Scalars['ID']['input']>;
};


export type MutationSendTextMessageArgs = {
  input: SendTextMessageInput;
};


export type MutationSetPasswordArgs = {
  password: Scalars['String']['input'];
};


export type MutationStartCarePathwayTemplateForMemberArgs = {
  carePathwayTemplateId: Scalars['ID']['input'];
  executeActionInput?: InputMaybe<Array<InputMaybe<ExecuteActionInput>>>;
  memberId: Scalars['ID']['input'];
  organizationId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
};


export type MutationSubmitAssessmentArgs = {
  input: SubmitAssessmentInput;
};


export type MutationSwapOrganizationInsuranceBillingConfigurationArgs = {
  fromId: Scalars['ID']['input'];
  organizationId: Scalars['ID']['input'];
  toId: Scalars['ID']['input'];
};


export type MutationToggleCarePlansArgs = {
  organizationId: Scalars['ID']['input'];
};


export type MutationToggleEpisodesOfCareArgs = {
  organizationId: Scalars['ID']['input'];
};


export type MutationUnassignUserFromMemberArgs = {
  input: UserMemberInput;
};


export type MutationUndoLastFlowProgressArgs = {
  input: UndoLastFlowProgressInput;
};


export type MutationUnlinkRecommendingProviderFromOrganizationArgs = {
  input: UnlinkRecommendingProviderInput;
};


export type MutationUpdateApiKeyArgs = {
  input: ApiKeyInput;
};


export type MutationUpdateAccessForObjectArgs = {
  input: UpdateAccessForObjectInput;
};


export type MutationUpdateActivityArgs = {
  input: UpdateActivityInput;
};


export type MutationUpdateActivityTaskStatusArgs = {
  input: UpdateActivityTaskStatusInput;
};


export type MutationUpdateActivityTemplateArgs = {
  input: UpdateActivityTemplateInput;
};


export type MutationUpdateBillArgs = {
  input: UpdateBillInput;
};


export type MutationUpdateBillsArgs = {
  input: UpdateBillsInput;
};


export type MutationUpdateCalculationArgs = {
  input: UpdateCalculationInput;
};


export type MutationUpdateCalendarEventArgs = {
  input: UpdateCalendarEventInput;
};


export type MutationUpdateCallRoutingConfigurationArgs = {
  input: CallRoutingConfigurationInput;
};


export type MutationUpdateCarePathwayStatusArgs = {
  input: UpdateCarePathwayStatusInput;
};


export type MutationUpdateCarePathwayTemplateDefaultAssignedUserGroupArgs = {
  input: CarePathwayTemplateUpdateDefaultAssignedUserGroupInput;
};


export type MutationUpdateCoverageDetailsArgs = {
  memberId: Scalars['ID']['input'];
  organizationId: Scalars['ID']['input'];
};


export type MutationUpdateDataArgs = {
  input: UpdateDataInput;
};


export type MutationUpdateEhrNoteTemplateArgs = {
  input: UpdateEhrNoteTemplateInput;
};


export type MutationUpdateEmailTemplateArgs = {
  input: UpdateEmailTemplateInput;
};


export type MutationUpdateEpisodeOfCareArgs = {
  input: UpdateEpisodeOfCareInput;
};


export type MutationUpdateEventMemberAttendanceArgs = {
  input: UpdateEventMemberAttendanceInput;
};


export type MutationUpdateEventTemplateArgs = {
  input: UpdateEventTemplateInput;
};


export type MutationUpdateExternalResourceArgs = {
  input: UpdateExternalResourceInput;
};


export type MutationUpdateExternalResourceContactArgs = {
  input: UpdateExternalResourceContactInput;
};


export type MutationUpdateFlowFromSnapshotEditorArgs = {
  input: UpdateFlowFromSnapshotEditorInput;
};


export type MutationUpdateFlowProgressArgs = {
  input: UpdateFlowProgressInput;
};


export type MutationUpdateFlowTemplateArgs = {
  input: UpdateFlowTemplateInput;
};


export type MutationUpdateGoalArgs = {
  input: UpdateGoalInput;
};


export type MutationUpdateGoalProgressArgs = {
  input: UpdateGoalProgressInput;
};


export type MutationUpdateGoalStatusArgs = {
  goalId: Scalars['ID']['input'];
  memberId: Scalars['ID']['input'];
  organizationId: Scalars['ID']['input'];
  status: GoalStatus;
};


export type MutationUpdateGoalTemplateArgs = {
  input: UpdateGoalTemplateInput;
};


export type MutationUpdateGroupArgs = {
  id: Scalars['ID']['input'];
  input: UpdateGroupInput;
  organizationId: Scalars['ID']['input'];
};


export type MutationUpdateMemberArgs = {
  input: UpdateMemberInput;
};


export type MutationUpdateMemberOverriddenProcedureCodeLimitsArgs = {
  memberId: Scalars['ID']['input'];
  organizationId: Scalars['ID']['input'];
  overriddenProcedureCodeLimits: Array<Scalars['String']['input']>;
};


export type MutationUpdateNoteArgs = {
  input: UpdateNoteInput;
};


export type MutationUpdateNoteTemplateArgs = {
  input: UpdateNoteTemplateInput;
};


export type MutationUpdateNotificationSettingsArgs = {
  notificationSettingsInput: NotificationSettingsInput;
};


export type MutationUpdateOnboardingSurveyArgs = {
  input: UpdateOnboardingSurveyInput;
};


export type MutationUpdateOrganizationArgs = {
  input: UpdateOrganizationInput;
};


export type MutationUpdateOrganizationBillingConfigurationArgs = {
  input: BillingConfigurationInput;
};


export type MutationUpdatePsFileArgs = {
  input: UpdateFileInput;
};


export type MutationUpdatePhoneCallEntryArgs = {
  input: UpdatePhoneCallEntryInput;
};


export type MutationUpdateProviderRecommendationBatchStatusArgs = {
  input: ProviderRecommendationBatchStatusInput;
};


export type MutationUpdateProviderRecommendationStatusArgs = {
  input: ProviderRecommendationStatusInput;
};


export type MutationUpdateQuestionArgs = {
  input: UpdateQuestionInput;
};


export type MutationUpdateRecommendationArgs = {
  input: UpdateRecommendationInput;
};


export type MutationUpdateReferralStatusArgs = {
  input: UpdateReferralStatusInput;
};


export type MutationUpdateReferralStatusPublicArgs = {
  input: UpdateReferralStatusPublicInput;
};


export type MutationUpdateReferralTemplateArgs = {
  input: UpdateReferralTemplateInput;
};


export type MutationUpdateTagArgs = {
  input: UpdateTagInput;
};


export type MutationUpdateTextMessageThreadReadArgs = {
  organizationId: Scalars['String']['input'];
  participantPhone: Scalars['String']['input'];
  read: Scalars['Boolean']['input'];
};


export type MutationUpdateUserArgs = {
  input: UpdateUserInput;
};


export type MutationUpdateUserGroupArgs = {
  input: UpdateUserGroupInput;
};


export type MutationUpdateVoicemailArgs = {
  input: UpdateVoicemailInput;
};


export type MutationUpdateVoicemailByCallArgs = {
  input: UpdateVoicemailByCallInput;
};


export type MutationUploadPsFileArgs = {
  input: UploadPsFileInput;
};


export type MutationUploadProviderRecommendationForMembersArgs = {
  input: UploadProviderRecommendationForMembersInput;
};


export type MutationUpsertDomainArgs = {
  input: DomainInput;
};


export type MutationUpsertDomainDescriptionArgs = {
  input: DomainDescriptionInput;
};


export type MutationUpsertRecommendingProviderArgs = {
  input: UpsertRecommendingProviderInput;
};


export type MutationUpsertTrainingArgs = {
  input: TrainingInput;
};


export type MutationVoiceGrantAccessTokenArgs = {
  organizationId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
};

export type Naa1TemplateContext = {
  __typename?: 'NAA1TemplateContext';
  activityId: Scalars['String']['output'];
  initiatingUserId?: Maybe<Scalars['String']['output']>;
  memberId?: Maybe<Scalars['String']['output']>;
};

export type Nma1TemplateContext = {
  __typename?: 'NMA1TemplateContext';
  initiatingUserId?: Maybe<Scalars['String']['output']>;
  memberId?: Maybe<Scalars['String']['output']>;
};

export type NeutralTemplateContext = {
  __typename?: 'NeutralTemplateContext';
  initiatingUserId?: Maybe<Scalars['String']['output']>;
};

export type NewAnswerInput = {
  answer?: InputMaybe<Scalars['String']['input']>;
  dataId?: InputMaybe<Scalars['String']['input']>;
  dataPointTemplateId?: InputMaybe<Scalars['String']['input']>;
  originalAnswer?: InputMaybe<Scalars['String']['input']>;
  placeholder?: InputMaybe<Scalars['String']['input']>;
};

export type NewDataIdInput = {
  dataId: Scalars['String']['input'];
  newDataId: Scalars['String']['input'];
};

export type NextAssessmentQuestion = {
  __typename?: 'NextAssessmentQuestion';
  flowTemplateStepId: Scalars['String']['output'];
  id: Scalars['String']['output'];
  nextStepId: Scalars['String']['output'];
  response: Scalars['String']['output'];
};

export type NextQuestion = {
  __typename?: 'NextQuestion';
  nextStepId: Scalars['String']['output'];
  response: Scalars['String']['output'];
};

export type NextQuestionData = {
  __typename?: 'NextQuestionData';
  actions?: Maybe<Array<Action>>;
  currentStep: Scalars['String']['output'];
  question: Question;
};

export type NextQuestionInput = {
  nextStepId: Scalars['String']['input'];
  response: Scalars['String']['input'];
};

export type NodeEdge = {
  __typename?: 'NodeEdge';
  id?: Maybe<Scalars['ID']['output']>;
  source?: Maybe<Scalars['String']['output']>;
  sourceHandle?: Maybe<Scalars['String']['output']>;
  target?: Maybe<Scalars['String']['output']>;
  targetHandle?: Maybe<Scalars['String']['output']>;
};

export type Note = {
  __typename?: 'Note';
  _id: Scalars['ID']['output'];
  accessType?: Maybe<AccessType>;
  activityId?: Maybe<Scalars['ID']['output']>;
  activityTitle?: Maybe<Scalars['String']['output']>;
  authorId?: Maybe<Scalars['ID']['output']>;
  authorName?: Maybe<Scalars['String']['output']>;
  content: Scalars['String']['output'];
  createdAt: Scalars['String']['output'];
  domains?: Maybe<Array<Maybe<Domain>>>;
  episodeOfCareId?: Maybe<Scalars['String']['output']>;
  externalResourceContactId?: Maybe<Scalars['ID']['output']>;
  externalResourceId?: Maybe<Scalars['ID']['output']>;
  familyId?: Maybe<Scalars['String']['output']>;
  memberId?: Maybe<Scalars['ID']['output']>;
  noteTemplateId?: Maybe<Scalars['ID']['output']>;
  noteTemplateTitle?: Maybe<Scalars['String']['output']>;
  organizationId: Scalars['ID']['output'];
  redacted: Scalars['Boolean']['output'];
  title?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['String']['output'];
};

export type NoteTemplate = {
  __typename?: 'NoteTemplate';
  _id: Scalars['ID']['output'];
  accessType?: Maybe<AccessType>;
  additionalFields?: Maybe<Array<AdditionalFields>>;
  content?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['String']['output'];
  domains?: Maybe<Array<Domain>>;
  notes?: Maybe<Array<Note>>;
  organizationId: Scalars['ID']['output'];
  title: Scalars['String']['output'];
  updatedAt: Scalars['String']['output'];
};

export type Notification = {
  __typename?: 'Notification';
  _id: Scalars['ID']['output'];
  code: NotificationCode;
  createdAt: Scalars['String']['output'];
  isRead: Scalars['Boolean']['output'];
  organizationId?: Maybe<Scalars['ID']['output']>;
  recipientContext?: Maybe<RecipientContext>;
  renderedContent: Scalars['String']['output'];
  resourceOrganizationId?: Maybe<Scalars['ID']['output']>;
  templateContext: TemplateContext;
  timestamp: Scalars['String']['output'];
  type: NotificationType;
  updatedAt: Scalars['String']['output'];
};

export const NotificationChannel = {
  App: 'App',
  Email: 'Email'
} as const;

export type NotificationChannel = typeof NotificationChannel[keyof typeof NotificationChannel];
export type NotificationChannelSettings = {
  __typename?: 'NotificationChannelSettings';
  App: Scalars['Boolean']['output'];
  Email: Scalars['Boolean']['output'];
};

export type NotificationChannelSettingsInput = {
  App: Scalars['Boolean']['input'];
  Email: Scalars['Boolean']['input'];
};

export const NotificationCode = {
  Gcbau1: 'GCBAU1',
  Gnc1: 'GNC1',
  Naa1: 'NAA1',
  Nma1: 'NMA1',
  Neutral: 'Neutral',
  OrganizationNotification: 'OrganizationNotification',
  Saas1: 'SAAS1'
} as const;

export type NotificationCode = typeof NotificationCode[keyof typeof NotificationCode];
export const NotificationRecipient = {
  User: 'User'
} as const;

export type NotificationRecipient = typeof NotificationRecipient[keyof typeof NotificationRecipient];
export type NotificationResponse = {
  __typename?: 'NotificationResponse';
  data?: Maybe<Notification>;
  message?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

export type NotificationSettings = {
  __typename?: 'NotificationSettings';
  GCBAU1: NotificationChannelSettings;
  GNC1: NotificationChannelSettings;
  NAA1: NotificationChannelSettings;
  NMA1: NotificationChannelSettings;
  Neutral: NotificationChannelSettings;
  OrganizationNotification: NotificationChannelSettings;
  SAAS1: NotificationChannelSettings;
};

export type NotificationSettingsInput = {
  GCBAU1: NotificationChannelSettingsInput;
  GNC1: NotificationChannelSettingsInput;
  NAA1: NotificationChannelSettingsInput;
  NMA1: NotificationChannelSettingsInput;
  Neutral: NotificationChannelSettingsInput;
  OrganizationNotification: NotificationChannelSettingsInput;
  SAAS1: NotificationChannelSettingsInput;
};

export type NotificationSettingsResponse = {
  __typename?: 'NotificationSettingsResponse';
  data?: Maybe<NotificationSettings>;
  message?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

export const NotificationType = {
  Error: 'Error',
  Informative: 'Informative',
  Success: 'Success',
  Warning: 'Warning'
} as const;

export type NotificationType = typeof NotificationType[keyof typeof NotificationType];
export type NotificationsMetadata = {
  __typename?: 'NotificationsMetadata';
  unreadNotificationsCount: Scalars['Int']['output'];
};

export type NotificationsMetadataResponse = {
  __typename?: 'NotificationsMetadataResponse';
  data?: Maybe<NotificationsMetadata>;
  message?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

export type NotificationsResponse = {
  __typename?: 'NotificationsResponse';
  data?: Maybe<Array<Notification>>;
  message?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

export const ObjectType = {
  ActivityTemplate: 'ActivityTemplate',
  Calculation: 'Calculation',
  CarePathwayTemplate: 'CarePathwayTemplate',
  ConsentTemplate: 'ConsentTemplate',
  DataExportTemplate: 'DataExportTemplate',
  DataImportTemplate: 'DataImportTemplate',
  DataPointTemplate: 'DataPointTemplate',
  Domain: 'Domain',
  EmailTemplate: 'EmailTemplate',
  EventTemplate: 'EventTemplate',
  ExternalResource: 'ExternalResource',
  ExternalResourceContact: 'ExternalResourceContact',
  FlowTemplate: 'FlowTemplate',
  GoalTemplate: 'GoalTemplate',
  Group: 'Group',
  Member: 'Member',
  NoteTemplate: 'NoteTemplate',
  Question: 'Question',
  RecommendationTemplate: 'RecommendationTemplate',
  ReferralTemplate: 'ReferralTemplate',
  Tag: 'Tag'
} as const;

export type ObjectType = typeof ObjectType[keyof typeof ObjectType];
export type OnboardingSurvey = {
  __typename?: 'OnboardingSurvey';
  _id?: Maybe<Scalars['ID']['output']>;
  activityTemplate?: Maybe<Scalars['ID']['output']>;
  enabled?: Maybe<Scalars['Boolean']['output']>;
  flowTemplate?: Maybe<Scalars['ID']['output']>;
  link?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

export type OnboardingSurveyInput = {
  enabled: Scalars['Boolean']['input'];
  flowTemplate?: InputMaybe<Scalars['ID']['input']>;
  surveyId?: InputMaybe<Scalars['ID']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export const Operator = {
  Contains: 'CONTAINS',
  Equal: 'EQUAL',
  GreaterThan: 'GREATER_THAN',
  GreaterThanOrEqual: 'GREATER_THAN_OR_EQUAL',
  LessThan: 'LESS_THAN',
  LessThanOrEqual: 'LESS_THAN_OR_EQUAL',
  NotContains: 'NOT_CONTAINS',
  NotEqual: 'NOT_EQUAL'
} as const;

export type Operator = typeof Operator[keyof typeof Operator];
export type Organization = {
  __typename?: 'Organization';
  _id: Scalars['ID']['output'];
  activatedIntegrations?: Maybe<Array<Integration>>;
  analyticsConfiguration: AnalyticsConfiguration;
  apiKeyManagementEnabled?: Maybe<Scalars['Boolean']['output']>;
  billingConsentTemplateId?: Maybe<Scalars['ID']['output']>;
  billingEnabled: Scalars['Boolean']['output'];
  billingInsuranceConfigurations: Array<InsuranceBillingConfiguration>;
  billingProviders: Array<BillingProvider>;
  billingRecommendingProviderEnabled: Scalars['Boolean']['output'];
  billingRenderingProvider?: Maybe<BillingProvider>;
  callRecordingEnabled: Scalars['Boolean']['output'];
  callRoutingConfiguration?: Maybe<CallRoutingConfiguration>;
  callerId?: Maybe<Scalars['String']['output']>;
  carePlanTemplate?: Maybe<CarePlanTemplate>;
  carePlanTemplateId?: Maybe<Scalars['ID']['output']>;
  carePlansEnabled?: Maybe<Scalars['Boolean']['output']>;
  claimsPlatformFeePercentage: Scalars['Int']['output'];
  defaultAnsweringUserIds?: Maybe<Array<Scalars['ID']['output']>>;
  description?: Maybe<Scalars['String']['output']>;
  diagnosisCodes: Array<DiagnosisCode>;
  domains?: Maybe<Array<Domain>>;
  ehrNoteProviderCredential?: Maybe<Scalars['String']['output']>;
  ehrNoteProviderFirstName?: Maybe<Scalars['String']['output']>;
  ehrNoteProviderID?: Maybe<Scalars['String']['output']>;
  ehrNoteProviderIDType?: Maybe<Scalars['String']['output']>;
  ehrNoteProviderLastName?: Maybe<Scalars['String']['output']>;
  episodesOfCareEnabled?: Maybe<Scalars['Boolean']['output']>;
  groups?: Maybe<Array<Group>>;
  hubControlEnabled: Scalars['Boolean']['output'];
  memberOverviewConfiguration?: Maybe<Array<Maybe<MemberOverviewConfigurationMetricCategory>>>;
  memberProfileSettings?: Maybe<MemberProfileSettings>;
  members?: Maybe<Array<Scalars['ID']['output']>>;
  mfaPolicy: MfaPolicy;
  onboardingMemberProfileSettings?: Maybe<MemberProfileSettings>;
  onboardingSurveys?: Maybe<Array<OnboardingSurvey>>;
  parentCallRoutingConfig?: Maybe<CallRoutingConfiguration>;
  parentCallRoutingConfigId?: Maybe<Scalars['ID']['output']>;
  parentCarePlanTemplate?: Maybe<CarePlanTemplate>;
  parentCarePlanTemplateId?: Maybe<Scalars['ID']['output']>;
  parentId?: Maybe<Scalars['ID']['output']>;
  placeOfServiceCodes: Array<Scalars['Int']['output']>;
  roles: Array<Role>;
  seats?: Maybe<Scalars['Int']['output']>;
  showMemberOverviewTab?: Maybe<Scalars['Boolean']['output']>;
  status: OrganizationStatus;
  stripeCustomPlanName?: Maybe<Scalars['String']['output']>;
  stripeCustomPriceId?: Maybe<Scalars['String']['output']>;
  stripeCustomerId?: Maybe<Scalars['String']['output']>;
  stripePayoutAccountEnabled: Scalars['Boolean']['output'];
  stripePayoutAccountId?: Maybe<Scalars['String']['output']>;
  stripePayoutRequirementsDeadline?: Maybe<Scalars['String']['output']>;
  stripePayoutRequirementsStatus: StripeRequirementsStatus;
  subscriptionDueDate?: Maybe<Scalars['String']['output']>;
  subscriptionIsActive: Scalars['Boolean']['output'];
  subscriptionPlan: Plan;
  timeTrackingEnabled: Scalars['Boolean']['output'];
  title: Scalars['String']['output'];
  userGroups: Array<UserGroup>;
  users?: Maybe<Array<Scalars['ID']['output']>>;
};

export type OrganizationInput = {
  callRoutingConfigurationId?: InputMaybe<Scalars['String']['input']>;
  callerId: Scalars['String']['input'];
  carePlansEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  claimsPlatformFeePercentage?: InputMaybe<Scalars['Int']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  domains?: InputMaybe<Array<InputMaybe<DomainInput>>>;
  groups?: InputMaybe<Array<Scalars['String']['input']>>;
  parentCallRoutingConfigId?: InputMaybe<Scalars['String']['input']>;
  parentCarePlanTemplateId?: InputMaybe<Scalars['String']['input']>;
  parentId?: InputMaybe<Scalars['String']['input']>;
  placeOfServiceCodes?: InputMaybe<Array<Scalars['Int']['input']>>;
  plan?: InputMaybe<Plan>;
  seats?: InputMaybe<Scalars['Int']['input']>;
  stripeCustomPriceId?: InputMaybe<Scalars['String']['input']>;
  stripeCustomerId?: InputMaybe<Scalars['String']['input']>;
  title: Scalars['String']['input'];
};

export type OrganizationMemberInfoInput = {
  activityTemplateId?: InputMaybe<Scalars['ID']['input']>;
  includeMembersWithActivityTemplate: Scalars['Boolean']['input'];
  organizationId: Scalars['ID']['input'];
};

export type OrganizationNotificationTemplateContext = {
  __typename?: 'OrganizationNotificationTemplateContext';
  initiatingUserId?: Maybe<Scalars['String']['output']>;
};

export const OrganizationStatus = {
  Active: 'Active',
  Archived: 'Archived',
  Deleted: 'Deleted'
} as const;

export type OrganizationStatus = typeof OrganizationStatus[keyof typeof OrganizationStatus];
export type PsFile = {
  __typename?: 'PSFile';
  _id: Scalars['ID']['output'];
  createdAt: Scalars['String']['output'];
  dataImports?: Maybe<Array<Maybe<DataImport>>>;
  deletedAt?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  encoding?: Maybe<Scalars['String']['output']>;
  memberId?: Maybe<Scalars['ID']['output']>;
  mimetype?: Maybe<Scalars['String']['output']>;
  organizationId: Scalars['ID']['output'];
  realFileName: Scalars['String']['output'];
  tags?: Maybe<Array<Maybe<Tag>>>;
  title: Scalars['String']['output'];
};

export type PaginatedActivities = {
  __typename?: 'PaginatedActivities';
  data: Array<Activity>;
  page: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type PaginatedBillBatches = {
  __typename?: 'PaginatedBillBatches';
  data: Array<BillBatch>;
  page: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type PaginatedBills = {
  __typename?: 'PaginatedBills';
  aggregate: Array<BillAggregate>;
  data: Array<Bill>;
  page: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type PaginatedCalculations = {
  __typename?: 'PaginatedCalculations';
  data: Array<Maybe<Calculation>>;
  page: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type PaginatedDataExportTemplates = {
  __typename?: 'PaginatedDataExportTemplates';
  data: Array<DataExportTemplate>;
  page: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type PaginatedDataImportTemplates = {
  __typename?: 'PaginatedDataImportTemplates';
  data: Array<DataImportTemplate>;
  page: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type PaginatedDataPointTemplates = {
  __typename?: 'PaginatedDataPointTemplates';
  data: Array<DataPointTemplate>;
  page: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type PaginatedDomains = {
  __typename?: 'PaginatedDomains';
  data: Array<Domain>;
  page: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type PaginatedDroppedMembers = {
  __typename?: 'PaginatedDroppedMembers';
  data: Array<DroppedMember>;
  page: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type PaginatedExternalContacts = {
  __typename?: 'PaginatedExternalContacts';
  data: Array<ExternalContact>;
  page: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type PaginatedFiles = {
  __typename?: 'PaginatedFiles';
  data: Array<PsFile>;
  page: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type PaginatedInsuranceAccountTransactions = {
  __typename?: 'PaginatedInsuranceAccountTransactions';
  data: Array<InsuranceAccountTransaction>;
  page: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type PaginatedMemberStats = {
  __typename?: 'PaginatedMemberStats';
  data: Array<MemberStat>;
  page: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type PaginatedMembers = {
  __typename?: 'PaginatedMembers';
  data: Array<Member>;
  page: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type PaginatedPhoneCalls = {
  __typename?: 'PaginatedPhoneCalls';
  data: Array<PhoneCall>;
  page: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type PaginatedProviderReports = {
  __typename?: 'PaginatedProviderReports';
  data: Array<ProviderReport>;
  page: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type PaginatedQuestions = {
  __typename?: 'PaginatedQuestions';
  data: Array<Question>;
  page: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type PaginatedReferrals = {
  __typename?: 'PaginatedReferrals';
  data: Array<Referral>;
  page: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type PaginatedTags = {
  __typename?: 'PaginatedTags';
  data: Array<Tag>;
  page: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type PaginatedTextMessageThreads = {
  __typename?: 'PaginatedTextMessageThreads';
  data: Array<TextMessageThread>;
  page: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
  unread: Scalars['Int']['output'];
};

export type PaginatedTextMessages = {
  __typename?: 'PaginatedTextMessages';
  data: Array<TextMessage>;
  page: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type PaginatedUnassignedMembers = {
  __typename?: 'PaginatedUnassignedMembers';
  data: Array<UnassignedMember>;
  page: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type PaginatedVoicemails = {
  __typename?: 'PaginatedVoicemails';
  data: Array<Voicemail>;
  page: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
  unlistened: Scalars['Int']['output'];
};

export type PaginationInput = {
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  size: Scalars['Int']['input'];
  sort?: InputMaybe<SortInput>;
};

export const Permission = {
  ApiKeyManagement: 'APIKeyManagement',
  AnalyticsAccess: 'AnalyticsAccess',
  BuilderToolsReadAccess: 'BuilderToolsReadAccess',
  BuilderToolsWriteAccess: 'BuilderToolsWriteAccess',
  ClaimGeneration: 'ClaimGeneration',
  ClaimsSubmission: 'ClaimsSubmission',
  DataImportAndExport: 'DataImportAndExport',
  DataManagement: 'DataManagement',
  ExternalResourceWriteAccess: 'ExternalResourceWriteAccess',
  MemberReadAccess: 'MemberReadAccess',
  MemberWriteAccess: 'MemberWriteAccess',
  OrganizationAccess: 'OrganizationAccess',
  RecommendationReadAccess: 'RecommendationReadAccess',
  RecommendationWriteAccess: 'RecommendationWriteAccess',
  RecommendingProvider: 'RecommendingProvider',
  UserManagement: 'UserManagement'
} as const;

export type Permission = typeof Permission[keyof typeof Permission];
export type PhoneCall = {
  __typename?: 'PhoneCall';
  _id: Scalars['ID']['output'];
  conferenceFriendlyName: Scalars['String']['output'];
  createdAt: Scalars['String']['output'];
  direction: PhoneCallDirection;
  disposition?: Maybe<Disposition>;
  duration: Scalars['Int']['output'];
  from: Scalars['String']['output'];
  initiatingCallSid: Scalars['String']['output'];
  members?: Maybe<Array<MemberIdAndName>>;
  organizationId: Scalars['String']['output'];
  recordingSid?: Maybe<Scalars['String']['output']>;
  routingStatus: CallRoutingStatus;
  to: Scalars['String']['output'];
  updatedAt: Scalars['String']['output'];
  userId?: Maybe<Scalars['ID']['output']>;
  userName?: Maybe<Scalars['String']['output']>;
  voicemail?: Maybe<Scalars['ID']['output']>;
  voicemailRecordingSid?: Maybe<Scalars['String']['output']>;
};

export const PhoneCallDirection = {
  Incoming: 'Incoming',
  Outgoing: 'Outgoing'
} as const;

export type PhoneCallDirection = typeof PhoneCallDirection[keyof typeof PhoneCallDirection];
export type PhoneCallEntryResponse = {
  __typename?: 'PhoneCallEntryResponse';
  data?: Maybe<PhoneCall>;
  message?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

export const PhoneCallIdentifierType = {
  ConferenceFriendlyName: 'ConferenceFriendlyName',
  PhoneCallId: 'PhoneCallId'
} as const;

export type PhoneCallIdentifierType = typeof PhoneCallIdentifierType[keyof typeof PhoneCallIdentifierType];
export const Plan = {
  Basic: 'Basic',
  Custom: 'Custom',
  Plus: 'Plus',
  Pro: 'Pro'
} as const;

export type Plan = typeof Plan[keyof typeof Plan];
export const PregnancyStatus = {
  NotPregnant: 'NotPregnant',
  Postpartum: 'Postpartum',
  Pregnant: 'Pregnant'
} as const;

export type PregnancyStatus = typeof PregnancyStatus[keyof typeof PregnancyStatus];
export type ProcedureClaimsSummary = {
  __typename?: 'ProcedureClaimsSummary';
  inNetwork: Scalars['Boolean']['output'];
  insuranceProviderName: Scalars['String']['output'];
  modifiers: Scalars['String']['output'];
  procedure: Scalars['String']['output'];
  unitsAccepted: Scalars['Int']['output'];
  unitsApproved: Scalars['Int']['output'];
  unitsDenied: Scalars['Int']['output'];
  unitsError: Scalars['Int']['output'];
  unitsErrorWhileDelivery: Scalars['Int']['output'];
  unitsGenerated: Scalars['Int']['output'];
  unitsPaid: Scalars['Int']['output'];
  unitsReadyForScreening: Scalars['Int']['output'];
  unitsRejected: Scalars['Int']['output'];
  unitsSent: Scalars['Int']['output'];
};

export const Pronouns = {
  HeHim: 'HeHim',
  NotListed: 'NotListed',
  PreferNotToDisclose: 'PreferNotToDisclose',
  SheHer: 'SheHer',
  TheyThem: 'TheyThem'
} as const;

export type Pronouns = typeof Pronouns[keyof typeof Pronouns];
export type ProviderRecommendation = {
  __typename?: 'ProviderRecommendation';
  _id: Scalars['ID']['output'];
  batch?: Maybe<ProviderRecommendationBatch>;
  batchId?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['String']['output'];
  memberId: Scalars['String']['output'];
  notes?: Maybe<Scalars['String']['output']>;
  status: ProviderRecommendationStatus;
  updatedAt: Scalars['String']['output'];
};

export type ProviderRecommendationBatch = {
  __typename?: 'ProviderRecommendationBatch';
  _id: Scalars['ID']['output'];
  createdAt: Scalars['String']['output'];
  recommendingProvider?: Maybe<RecommendingProvider>;
  signatureRequestId?: Maybe<Scalars['String']['output']>;
  templateId?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['String']['output'];
};

export type ProviderRecommendationBatchStatusInput = {
  batchId: Scalars['ID']['input'];
  organizationId: Scalars['ID']['input'];
  recommendingProviderId: Scalars['ID']['input'];
  status: ProviderRecommendationStatus;
};

export const ProviderRecommendationStatus = {
  FailedDelivery: 'FailedDelivery',
  Pending: 'Pending',
  Rejected: 'Rejected',
  Signed: 'Signed'
} as const;

export type ProviderRecommendationStatus = typeof ProviderRecommendationStatus[keyof typeof ProviderRecommendationStatus];
export type ProviderRecommendationStatusInput = {
  members: Array<Scalars['ID']['input']>;
  notes?: InputMaybe<Scalars['String']['input']>;
  organizationId?: InputMaybe<Scalars['ID']['input']>;
  status: ProviderRecommendationStatus;
};

export type ProviderReport = {
  __typename?: 'ProviderReport';
  _id: Scalars['ID']['output'];
  billingProviderNPI?: Maybe<Scalars['String']['output']>;
  generatedAt: Scalars['String']['output'];
  generatedId: Scalars['String']['output'];
  member: Member;
  renderingProviderNPI?: Maybe<Scalars['String']['output']>;
};

export const ProviderType = {
  Billing: 'Billing',
  Rendering: 'Rendering'
} as const;

export type ProviderType = typeof ProviderType[keyof typeof ProviderType];
export type Query = {
  __typename?: 'Query';
  accountDashboardLink: GetUrlResponse;
  activities: GetActivitiesResponse;
  activitiesStats: ActivitiesStatsResponse;
  activity: GetActivityResponse;
  activityTemplate: GetActivityTemplateWithAccessResponse;
  activityTemplates: GetActivityTemplatesWithAccessResponse;
  additionalFiltersOptions: AnalyticsFiltersMetadataResponse;
  allowedCustomMetrics?: Maybe<AllowedCustomMetricsResponse>;
  apiKeys?: Maybe<GetApiKeysResponse>;
  assignedMemberInfo: MemberInfoResponse;
  availableUsersByOrganizationId: GetUsersResponse;
  billActivities: GetAllActivitiesResponse;
  billSignedUrl: SignedUrlResponse;
  bills: GetBillsResponse;
  builderGraph: GetBuilderGraphResponse;
  calculation: GetCalculationResponse;
  calculations: GetCalculationsResponse;
  callCenterStats: CallCenterStatsResponse;
  callRoutingConfiguration: GetCallRoutingConfigurationResponse;
  carePathwayTemplateById: CarePathwayTemplateResponse;
  carePathwayTemplateDataByMemberId: CarePathwayResponse;
  carePathwayTemplatesByMemberId: CarePathwayTemplatesResponse;
  carePathwayTemplatesByOrganizationId: CarePathwayTemplatesResponse;
  carePathwaysByMemberId: CarePathwaysResponse;
  carePathwaysStats: CarePathwaysStatsResponse;
  carePlanTemplateByOrganizationId: CarePlanTemplateResponse;
  certificationTemplatesByOrganizationId: CertificationTemplatesResponse;
  certificationsByUserId: CertificationsResponse;
  checkFileName: CheckFileNameResponse;
  connectOnboardingLink: GetUrlResponse;
  consentTemplatesByOrganizationId: ConsentTemplatesResponse;
  consentsByMemberId: ConsentsResponse;
  currentEpisodeOfCareByMemberId: EpisodeOfCareResponse;
  customerPortalLink: GetUrlResponse;
  dataByActivity: BulkDataResponse;
  dataByMember: BulkDataResponse;
  dataExportTemplate?: Maybe<DataExportTemplateResponse>;
  dataExportTemplates?: Maybe<DataExportTemplatesResponse>;
  dataIdsWithAnswerTypesByOrganizationId: DataIdWithAnswerTypeResponse;
  dataImportTemplate?: Maybe<DataImportTemplateResponse>;
  dataImportTemplates?: Maybe<DataImportTemplatesResponse>;
  dataPointStats: DataPointStatsResponse;
  dataPointTemplates: GetDataPointTemplatesResponse;
  demographicsStats: DemographicsStatsResponse;
  domainById: DomainResponse;
  domainsByOrganizationId: GetDomainsResponse;
  droppedMembers: GetDroppedMembersResponse;
  ehrNoteTemplates: GetEhrNoteTemplatesResponse;
  emailTemplate: GetEmailTemplateWithAccessResponse;
  emailTemplates: GetEmailTemplatesResponse;
  episodesOfCareByMemberId: EpisodesOfCareResponse;
  eventTemplate: GetEventTemplateResponse;
  eventTemplates: GetEventTemplatesResponse;
  externalResource: GetExternalResourceResponse;
  externalResourceContacts: GetExternalContactsResponse;
  externalResources: GetExternalResourcesResponse;
  externalResourcesStats: ExternalResourcesStatsResponse;
  flowTemplate: GetFlowTemplateWithAccessResponse;
  flowTemplateFamilies: GetFlowTemplateFamiliesWithAccessResponse;
  flowTemplateFamily?: Maybe<GetFlowTemplateFamilyResponse>;
  flowTemplates: GetFlowTemplatesWithAccessResponse;
  getACLForObjectType: GetAclForObjectTypeResponse;
  getActivityTemplateQuestions: GetQuestionsResponse;
  getAssessment: GetAssessmentResponse;
  getFlow: GetFlowResponse;
  getFlowByActivity: GetFlowResponse;
  getFlowsByMemberId: GetFlowsResponse;
  getNextFlowQuestion: GetNextFlowQuestionResponse;
  getPaginatedQuestions: GetPaginatedQuestionsResponse;
  getSubOrgsByParentId: GetSubOrgsResponse;
  getUserIntegrations: GetUserIntegrationsResponse;
  goalTemplates: GetGoalTemplatesWithAccessRes;
  goals: GetGoalsResponse;
  goalsStats: GoalsStatsResponse;
  groupMemberInfo: MemberInfoResponse;
  groupMembers?: Maybe<GetAllMembersResponse>;
  groups?: Maybe<GetGroupsResponse>;
  historicalDataByMember: BulkDataResponse;
  inNetworkBillingProviders: GetBillingProvidersResponse;
  inNetworkInsuranceAccountTransactions: GetInsuranceAccountTransactionsResponse;
  inNetworkInsuranceBillingConfigurations: GetInsuranceBillingConfigurationsResponse;
  member: GetMemberResponse;
  memberBillingSummary: GetMemberBillingSummaryResponse;
  memberCarePlan: GetMemberCarePlanResponse;
  memberOverviewPageDetails: GetMemberOverviewMetricResultsResponse;
  memberRecommendations: RecommendationsResponse;
  memberSearch: MemberSearchResponse;
  memberStats: GetMemberStatsResponse;
  members: GetMembersResponse;
  noteTemplate: GetNoteTemplateResponse;
  noteTemplates: GetNoteTemplatesResponse;
  notes: GetNotesResponse;
  notificationSettings?: Maybe<NotificationSettingsResponse>;
  notificationsMetadata?: Maybe<NotificationsMetadataResponse>;
  onboardingFlow: GetFlowResponse;
  onboardingSurveysByOrganizationId: GetOnboardingSurveysResponse;
  organization: GetOrganizationResponse;
  organizationBillBatches: GetBillBatchesResponse;
  organizationByOnboardingToken: GetOrganizationResponse;
  organizationMemberInfo: MemberInfoResponse;
  organizations: GetOrganizationsResponse;
  organizationsByIds: GetOrganizationsResponse;
  organizationsForUser: GetOrganizationsResponse;
  paginatedNotifications?: Maybe<NotificationsResponse>;
  phoneCall: PhoneCallEntryResponse;
  phoneCalls: GetPhoneCallsResponse;
  providerReports: GetProviderReportsResponse;
  psFile?: Maybe<GetFileResponse>;
  psFiles: GetFilesResponse;
  recommendationById: GetRecommendationResponse;
  recommendationsByOrganizationId: RecommendationsResponse;
  recommendingProviders: GetUsersResponse;
  recommendingProvidersByOrganizationId: GetUsersResponse;
  referral: GetReferralResponse;
  referralTemplate: GetReferralTemplateResponse;
  referralTemplates: GetReferralTemplatesResponse;
  referralsByMember: GetReferralsResponse;
  referralsByResource: GetReferralsResponse;
  requestPasswordReset: DefaultResponse;
  resendSignupEmail: DefaultResponse;
  roles: GetRolesResponse;
  schedule: GetScheduleResponse;
  securePsFileUrl: SignedUrlResponse;
  signedDocument: GetSignedDocumentResponse;
  signedPhoneCallRecordingUrl: SignedPhoneCallRecordingUrlResponse;
  signedVoicemailRecordingUrl: SignedUrlResponse;
  startGoogleConnectFlow: StartGoogleConnectFlowResponse;
  startMicrosoftConnectFlow: StartMicrosoftConnectFlowResponse;
  summaryStats: SummaryStatsResponse;
  tags: GetTagsResponse;
  textMessageThread?: Maybe<TextMessageThreadResponse>;
  textMessageThreads: GetTextMessageThreadsResponse;
  textMessages: GetTextMessagesResponse;
  titledReferences: GetTitledReferencesResponse;
  trainingsByOrganizationId: TrainingsResponse;
  trainingsByUserId: TrainingsResponse;
  unassignedMembers: GetUnassignedMembersResponse;
  user: GetUserResponse;
  userCurrentActivity: GetActivityResponse;
  userGoals: GetGoalsResponse;
  userGroup: GetUserGroupResponse;
  userGroups: GetUserGroupsResponse;
  userStats: GetUserStatsResponse;
  users: GetUsersResponse;
  voicemails: VoicemailsResponse;
};


export type QueryAccountDashboardLinkArgs = {
  organizationId: Scalars['ID']['input'];
};


export type QueryActivitiesArgs = {
  input: GetActivitiesInput;
  pagination: PaginationInput;
};


export type QueryActivitiesStatsArgs = {
  input: AnalyticsInput;
};


export type QueryActivityArgs = {
  input: GetActivityInput;
};


export type QueryActivityTemplateArgs = {
  activityTemplateId: Scalars['ID']['input'];
  organizationId: Scalars['ID']['input'];
};


export type QueryActivityTemplatesArgs = {
  organizationId: Scalars['ID']['input'];
};


export type QueryAdditionalFiltersOptionsArgs = {
  input: AnalyticsInput;
};


export type QueryAllowedCustomMetricsArgs = {
  organizationId: Scalars['ID']['input'];
};


export type QueryApiKeysArgs = {
  organizationId: Scalars['String']['input'];
};


export type QueryAssignedMemberInfoArgs = {
  input: AssignedMemberInfoInput;
};


export type QueryAvailableUsersByOrganizationIdArgs = {
  organization: Scalars['ID']['input'];
};


export type QueryBillActivitiesArgs = {
  input: GetBillActivitiesInput;
};


export type QueryBillSignedUrlArgs = {
  input: GetBillSignedUrlInput;
};


export type QueryBillsArgs = {
  input: GetBillsInput;
  pagination: PaginationInput;
};


export type QueryBuilderGraphArgs = {
  id: Scalars['ID']['input'];
  organizationId: Scalars['ID']['input'];
  type: BuilderNodeType;
};


export type QueryCalculationArgs = {
  calculationId: Scalars['String']['input'];
  organizationId: Scalars['String']['input'];
};


export type QueryCalculationsArgs = {
  input: GetCalculationsInput;
  pagination: PaginationInput;
};


export type QueryCallCenterStatsArgs = {
  input: AnalyticsInput;
};


export type QueryCallRoutingConfigurationArgs = {
  organizationId: Scalars['String']['input'];
};


export type QueryCarePathwayTemplateByIdArgs = {
  carePathwayId?: InputMaybe<Scalars['String']['input']>;
  carePathwayTemplateId: Scalars['ID']['input'];
  organizationId: Scalars['ID']['input'];
};


export type QueryCarePathwayTemplateDataByMemberIdArgs = {
  carePathwayId: Scalars['ID']['input'];
  memberId: Scalars['ID']['input'];
  organizationId: Scalars['ID']['input'];
};


export type QueryCarePathwayTemplatesByMemberIdArgs = {
  memberId: Scalars['ID']['input'];
  organizationId: Scalars['ID']['input'];
};


export type QueryCarePathwayTemplatesByOrganizationIdArgs = {
  organizationId: Scalars['ID']['input'];
};


export type QueryCarePathwaysByMemberIdArgs = {
  episodeOfCareId?: InputMaybe<Scalars['String']['input']>;
  memberId: Scalars['ID']['input'];
  organizationId: Scalars['ID']['input'];
};


export type QueryCarePathwaysStatsArgs = {
  input: AnalyticsInput;
};


export type QueryCarePlanTemplateByOrganizationIdArgs = {
  organizationId: Scalars['ID']['input'];
};


export type QueryCertificationTemplatesByOrganizationIdArgs = {
  organizationId: Scalars['ID']['input'];
  showDeleted?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QueryCertificationsByUserIdArgs = {
  organizationId: Scalars['ID']['input'];
  showDeleted?: InputMaybe<Scalars['Boolean']['input']>;
  userId: Scalars['ID']['input'];
};


export type QueryCheckFileNameArgs = {
  input: CheckFileNameInput;
};


export type QueryConnectOnboardingLinkArgs = {
  organizationId: Scalars['ID']['input'];
};


export type QueryConsentTemplatesByOrganizationIdArgs = {
  organizationId: Scalars['ID']['input'];
  showDeleted?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QueryConsentsByMemberIdArgs = {
  episodeOfCareId?: InputMaybe<Scalars['String']['input']>;
  memberId: Scalars['ID']['input'];
  organizationId: Scalars['ID']['input'];
  showDeleted?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QueryCurrentEpisodeOfCareByMemberIdArgs = {
  memberId: Scalars['ID']['input'];
  organizationId: Scalars['ID']['input'];
};


export type QueryCustomerPortalLinkArgs = {
  organizationId: Scalars['ID']['input'];
};


export type QueryDataByActivityArgs = {
  activityId: Scalars['ID']['input'];
  memberId?: InputMaybe<Scalars['ID']['input']>;
  organizationId: Scalars['ID']['input'];
};


export type QueryDataByMemberArgs = {
  memberId: Scalars['ID']['input'];
  organizationId: Scalars['ID']['input'];
};


export type QueryDataExportTemplateArgs = {
  id: Scalars['ID']['input'];
  organizationId: Scalars['ID']['input'];
};


export type QueryDataExportTemplatesArgs = {
  organizationId: Scalars['ID']['input'];
  pagination: PaginationInput;
};


export type QueryDataIdsWithAnswerTypesByOrganizationIdArgs = {
  includeDemographicOptions?: InputMaybe<Scalars['Boolean']['input']>;
  organizationId: Scalars['ID']['input'];
};


export type QueryDataImportTemplateArgs = {
  id: Scalars['ID']['input'];
  organizationId: Scalars['ID']['input'];
};


export type QueryDataImportTemplatesArgs = {
  organizationId: Scalars['ID']['input'];
  pagination: PaginationInput;
};


export type QueryDataPointStatsArgs = {
  input: AnalyticsInput;
};


export type QueryDataPointTemplatesArgs = {
  input: GetDataPointTemplatesInput;
  pagination: PaginationInput;
};


export type QueryDemographicsStatsArgs = {
  input: AnalyticsInput;
};


export type QueryDomainByIdArgs = {
  domainId: Scalars['ID']['input'];
  organizationId: Scalars['ID']['input'];
};


export type QueryDomainsByOrganizationIdArgs = {
  organizationId: Scalars['ID']['input'];
  pagination: PaginationInput;
};


export type QueryDroppedMembersArgs = {
  input: GetDroppedMembersInput;
  pagination: PaginationInput;
};


export type QueryEhrNoteTemplatesArgs = {
  organizationId: Scalars['ID']['input'];
};


export type QueryEmailTemplateArgs = {
  input: EmailTemplateIdAndOrgInput;
};


export type QueryEmailTemplatesArgs = {
  organizationId: Scalars['ID']['input'];
};


export type QueryEpisodesOfCareByMemberIdArgs = {
  memberId: Scalars['ID']['input'];
  organizationId: Scalars['ID']['input'];
};


export type QueryEventTemplateArgs = {
  id: Scalars['ID']['input'];
  organizationId: Scalars['ID']['input'];
};


export type QueryEventTemplatesArgs = {
  organizationId: Scalars['ID']['input'];
};


export type QueryExternalResourceArgs = {
  input: ExternalResourceIdAndOrgInput;
};


export type QueryExternalResourceContactsArgs = {
  input: GetExternalResourceContactsInput;
  pagination: PaginationInput;
};


export type QueryExternalResourcesArgs = {
  organizationId: Scalars['ID']['input'];
};


export type QueryExternalResourcesStatsArgs = {
  input: AnalyticsInput;
};


export type QueryFlowTemplateArgs = {
  input: FlowTemplateIdAndOrgInput;
};


export type QueryFlowTemplateFamiliesArgs = {
  organizationId: Scalars['ID']['input'];
};


export type QueryFlowTemplateFamilyArgs = {
  input: FlowTemplateFamilyIdAndOrgInput;
};


export type QueryFlowTemplatesArgs = {
  organizationId: Scalars['ID']['input'];
};


export type QueryGetAclForObjectTypeArgs = {
  objectType: ObjectType;
  organizationId: Scalars['ID']['input'];
};


export type QueryGetActivityTemplateQuestionsArgs = {
  activityTemplateId: Scalars['ID']['input'];
  organizationId: Scalars['ID']['input'];
};


export type QueryGetAssessmentArgs = {
  flowId: Scalars['ID']['input'];
  organizationId: Scalars['ID']['input'];
};


export type QueryGetFlowArgs = {
  flowId: Scalars['ID']['input'];
  organizationId: Scalars['ID']['input'];
};


export type QueryGetFlowByActivityArgs = {
  input: GetFlowByActivityInput;
};


export type QueryGetFlowsByMemberIdArgs = {
  memberId: Scalars['ID']['input'];
  organizationId: Scalars['ID']['input'];
};


export type QueryGetNextFlowQuestionArgs = {
  flowId: Scalars['ID']['input'];
  organizationId: Scalars['ID']['input'];
};


export type QueryGetPaginatedQuestionsArgs = {
  input: GetPaginatedQuestionsInput;
  pagination: PaginationInput;
};


export type QueryGetSubOrgsByParentIdArgs = {
  organizationId: Scalars['ID']['input'];
};


export type QueryGetUserIntegrationsArgs = {
  input: UserIntegrationInput;
};


export type QueryGoalTemplatesArgs = {
  organizationId: Scalars['ID']['input'];
};


export type QueryGoalsArgs = {
  episodeOfCareId?: InputMaybe<Scalars['String']['input']>;
  memberId: Scalars['ID']['input'];
  organizationId: Scalars['ID']['input'];
};


export type QueryGoalsStatsArgs = {
  input: AnalyticsInput;
};


export type QueryGroupMemberInfoArgs = {
  input: GroupMemberInfoInput;
};


export type QueryGroupMembersArgs = {
  groupId: Scalars['ID']['input'];
  organizationId: Scalars['ID']['input'];
};


export type QueryGroupsArgs = {
  organizationId: Scalars['ID']['input'];
};


export type QueryHistoricalDataByMemberArgs = {
  memberId: Scalars['ID']['input'];
  organizationId: Scalars['ID']['input'];
};


export type QueryInNetworkInsuranceAccountTransactionsArgs = {
  input: GetInsuranceAccountTransactionsInput;
  pagination: PaginationInput;
};


export type QueryMemberArgs = {
  memberId: Scalars['ID']['input'];
  organizationIds: Array<Scalars['ID']['input']>;
};


export type QueryMemberBillingSummaryArgs = {
  input: GetMemberBillingSummaryInput;
};


export type QueryMemberCarePlanArgs = {
  memberId: Scalars['ID']['input'];
  organizationId: Scalars['ID']['input'];
};


export type QueryMemberOverviewPageDetailsArgs = {
  memberId: Scalars['ID']['input'];
  organizationId: Scalars['ID']['input'];
};


export type QueryMemberRecommendationsArgs = {
  episodeOfCareId?: InputMaybe<Scalars['String']['input']>;
  memberId: Scalars['ID']['input'];
  organizationId: Scalars['ID']['input'];
};


export type QueryMemberSearchArgs = {
  input: MemberSearchInput;
};


export type QueryMemberStatsArgs = {
  input: GetMemberStatsInput;
  pagination: PaginationInput;
};


export type QueryMembersArgs = {
  input: GetMembersInput;
  pagination: PaginationInput;
};


export type QueryNoteTemplateArgs = {
  input: GetNoteTemplateInput;
};


export type QueryNoteTemplatesArgs = {
  input: GetNoteTemplatesInput;
};


export type QueryNotesArgs = {
  input: GetNotesInput;
};


export type QueryOnboardingFlowArgs = {
  activityId: Scalars['ID']['input'];
  memberId: Scalars['ID']['input'];
  organizationId: Scalars['ID']['input'];
};


export type QueryOnboardingSurveysByOrganizationIdArgs = {
  organizationId: Scalars['ID']['input'];
};


export type QueryOrganizationArgs = {
  organizationId: Scalars['ID']['input'];
};


export type QueryOrganizationBillBatchesArgs = {
  input: GetOrganizationBillBatchesInput;
  pagination: PaginationInput;
};


export type QueryOrganizationByOnboardingTokenArgs = {
  token: Scalars['String']['input'];
};


export type QueryOrganizationMemberInfoArgs = {
  input: OrganizationMemberInfoInput;
};


export type QueryOrganizationsByIdsArgs = {
  organizationIds: Array<Scalars['ID']['input']>;
};


export type QueryOrganizationsForUserArgs = {
  userId: Scalars['ID']['input'];
};


export type QueryPaginatedNotificationsArgs = {
  page: Scalars['Int']['input'];
  showOnlyUnRead: Scalars['Boolean']['input'];
};


export type QueryPhoneCallArgs = {
  input: GetPhoneCallInput;
};


export type QueryPhoneCallsArgs = {
  input: GetPhoneCallsInput;
  pagination: PaginationInput;
};


export type QueryProviderReportsArgs = {
  input: GetProviderReportsInput;
  pagination: PaginationInput;
};


export type QueryPsFileArgs = {
  id: Scalars['ID']['input'];
  organizationId: Scalars['ID']['input'];
};


export type QueryPsFilesArgs = {
  input: GetFilesInput;
  pagination: PaginationInput;
};


export type QueryRecommendationByIdArgs = {
  organizationId: Scalars['ID']['input'];
  recommendationId: Scalars['ID']['input'];
};


export type QueryRecommendationsByOrganizationIdArgs = {
  organizationId: Scalars['ID']['input'];
};


export type QueryRecommendingProvidersByOrganizationIdArgs = {
  input: GetRecommendingProvidersInput;
};


export type QueryReferralArgs = {
  input: GetReferralInput;
};


export type QueryReferralTemplateArgs = {
  input: GetReferralTemplateInput;
};


export type QueryReferralTemplatesArgs = {
  input: GetReferralTemplatesInput;
};


export type QueryReferralsByMemberArgs = {
  input: GetReferralsByMemberInput;
  pagination: PaginationInput;
};


export type QueryReferralsByResourceArgs = {
  input: GetReferralsByResourceInput;
  pagination: PaginationInput;
};


export type QueryRequestPasswordResetArgs = {
  email: Scalars['String']['input'];
};


export type QueryResendSignupEmailArgs = {
  email: Scalars['String']['input'];
};


export type QueryRolesArgs = {
  input: GetRolesInput;
};


export type QueryScheduleArgs = {
  input: GetScheduleInput;
};


export type QuerySecurePsFileUrlArgs = {
  dataExportTemplateId?: InputMaybe<Scalars['ID']['input']>;
  dataImportTemplateId?: InputMaybe<Scalars['ID']['input']>;
  id: Scalars['ID']['input'];
  organizationId: Scalars['ID']['input'];
};


export type QuerySignedDocumentArgs = {
  memberId: Scalars['ID']['input'];
  organizationIds: Array<Scalars['ID']['input']>;
  providerRecommendationBatchId: Scalars['ID']['input'];
  signatureRequestId?: InputMaybe<Scalars['ID']['input']>;
};


export type QuerySignedPhoneCallRecordingUrlArgs = {
  organizationId: Scalars['ID']['input'];
  phoneCallId: Scalars['ID']['input'];
};


export type QuerySignedVoicemailRecordingUrlArgs = {
  call: Scalars['ID']['input'];
  organizationId: Scalars['ID']['input'];
};


export type QueryStartGoogleConnectFlowArgs = {
  input: UserIntegrationInput;
};


export type QueryStartMicrosoftConnectFlowArgs = {
  input: UserIntegrationInput;
};


export type QuerySummaryStatsArgs = {
  input: AnalyticsInput;
};


export type QueryTagsArgs = {
  input: GetTagsInput;
  pagination: PaginationInput;
};


export type QueryTextMessageThreadArgs = {
  organizationId: Scalars['ID']['input'];
  participantPhone: Scalars['String']['input'];
};


export type QueryTextMessageThreadsArgs = {
  input: GetTextMessageThreadsInput;
  pagination: PaginationInput;
};


export type QueryTextMessagesArgs = {
  input: GetTextMessagesInput;
  pagination: PaginationInput;
};


export type QueryTitledReferencesArgs = {
  input: LockingReferencesAndOrgIdInput;
};


export type QueryTrainingsByOrganizationIdArgs = {
  organizationId: Scalars['ID']['input'];
};


export type QueryTrainingsByUserIdArgs = {
  organizationId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
};


export type QueryUnassignedMembersArgs = {
  input: GetUnassignedMembersInput;
  pagination: PaginationInput;
};


export type QueryUserArgs = {
  userId: Scalars['ID']['input'];
};


export type QueryUserCurrentActivityArgs = {
  input: GetUserCurrentActivityInput;
};


export type QueryUserGoalsArgs = {
  input: UserGoalInput;
};


export type QueryUserGroupArgs = {
  input: GetUserGroupInput;
};


export type QueryUserGroupsArgs = {
  organizationId: Scalars['ID']['input'];
};


export type QueryUserStatsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  getDeactivatedUsers?: InputMaybe<Scalars['Boolean']['input']>;
  organization?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryUsersArgs = {
  input: GetUsersInput;
};


export type QueryVoicemailsArgs = {
  input: GetVoicemailsInput;
  pagination: PaginationInput;
};

export type Question = {
  __typename?: 'Question';
  _count?: Maybe<QuestionRelationCount>;
  _id: Scalars['ID']['output'];
  accessType?: Maybe<AccessType>;
  answerOptions?: Maybe<Array<Scalars['String']['output']>>;
  answerType: AnswerType;
  createdAt: Scalars['String']['output'];
  dataId: Scalars['String']['output'];
  dataPointTemplate?: Maybe<DataPointTemplate>;
  dataPointTemplateId?: Maybe<Scalars['ID']['output']>;
  lockingReferences: Array<LockingReference>;
  organizationId: Scalars['ID']['output'];
  questionText: Scalars['String']['output'];
  questionTitle: Scalars['String']['output'];
  questionType: QuestionType;
  tags?: Maybe<Array<Maybe<TaggedEntity>>>;
  updatedAt: Scalars['String']['output'];
};

export type QuestionFilter = {
  condition: FilterCondition;
  filter: QuestionFilterProp;
  filterValue: Scalars['String']['input'];
};

export const QuestionFilterProp = {
  Tags: 'tags'
} as const;

export type QuestionFilterProp = typeof QuestionFilterProp[keyof typeof QuestionFilterProp];
export type QuestionInput = {
  answerOptions?: InputMaybe<Array<Scalars['String']['input']>>;
  answerType: AnswerType;
  dataId: Scalars['String']['input'];
  organizationId: Scalars['String']['input'];
  questionText: Scalars['String']['input'];
  questionTitle: Scalars['String']['input'];
  questionType: QuestionType;
  tagNames?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type QuestionRelationCount = {
  __typename?: 'QuestionRelationCount';
  dataPoints?: Maybe<Scalars['Int']['output']>;
  questions?: Maybe<Scalars['Int']['output']>;
};

export const QuestionType = {
  Question: 'Question',
  Step: 'Step'
} as const;

export type QuestionType = typeof QuestionType[keyof typeof QuestionType];
export type RecipientContext = {
  __typename?: 'RecipientContext';
  userId?: Maybe<Scalars['String']['output']>;
};

export type Recommendation = {
  __typename?: 'Recommendation';
  _id: Scalars['ID']['output'];
  accessType?: Maybe<AccessType>;
  actions?: Maybe<Array<ActionData>>;
  conditionsGroups: Array<ConditionsGroupItem>;
  createdAt: Scalars['String']['output'];
  generatedForMemberAt?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  organizationId: Scalars['ID']['output'];
  recommendationText: Scalars['String']['output'];
  updatedAt: Scalars['String']['output'];
};

export type RecommendationActionData = {
  __typename?: 'RecommendationActionData';
  actionType: RecommendationActionDataType;
  carePathwayTemplateId?: Maybe<Scalars['String']['output']>;
  goalTemplateId?: Maybe<Scalars['String']['output']>;
};

export const RecommendationActionDataType = {
  CreateGoal: 'CREATE_GOAL',
  StartCarePathway: 'START_CARE_PATHWAY',
  UpdateMember: 'UPDATE_MEMBER'
} as const;

export type RecommendationActionDataType = typeof RecommendationActionDataType[keyof typeof RecommendationActionDataType];
export type RecommendationsResponse = {
  __typename?: 'RecommendationsResponse';
  data?: Maybe<Array<Recommendation>>;
  message?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

export type RecommendingProvider = {
  __typename?: 'RecommendingProvider';
  _id: Scalars['ID']['output'];
  companyName?: Maybe<Scalars['String']['output']>;
  faxNumber?: Maybe<Scalars['String']['output']>;
  firstName: Scalars['String']['output'];
  lastName: Scalars['String']['output'];
  nationalProviderIdentifier: Scalars['String']['output'];
  title?: Maybe<Scalars['String']['output']>;
};

export type RecommendingProviderInfo = {
  __typename?: 'RecommendingProviderInfo';
  _id?: Maybe<Scalars['ID']['output']>;
  companyName?: Maybe<Scalars['String']['output']>;
  faxNumber?: Maybe<Scalars['String']['output']>;
  firstName: Scalars['String']['output'];
  isEmailVerified?: Maybe<Scalars['Boolean']['output']>;
  lastName: Scalars['String']['output'];
  nationalProviderIdentifier: Scalars['String']['output'];
  title?: Maybe<Scalars['String']['output']>;
};

export type RecommendingProviderInput = {
  companyName?: InputMaybe<Scalars['String']['input']>;
  faxNumber?: InputMaybe<Scalars['String']['input']>;
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  nationalProviderIdentifier: Scalars['String']['input'];
  title?: InputMaybe<Scalars['String']['input']>;
};

export type RecommendingProviderResponse = {
  __typename?: 'RecommendingProviderResponse';
  data?: Maybe<RecommendingProvider>;
  message?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

export type ReferMemberInput = {
  memberId: Scalars['ID']['input'];
  organizationId: Scalars['ID']['input'];
};

export type Referral = {
  __typename?: 'Referral';
  _id: Scalars['ID']['output'];
  activityDate?: Maybe<Scalars['String']['output']>;
  activityId?: Maybe<Scalars['ID']['output']>;
  activityTitle?: Maybe<Scalars['String']['output']>;
  contactType?: Maybe<ReferralContactType>;
  content?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['String']['output'];
  externalResourceId: Scalars['ID']['output'];
  isSentWithActionButtons: Scalars['Boolean']['output'];
  memberDisplayName: Scalars['String']['output'];
  memberId: Scalars['ID']['output'];
  organizationId: Scalars['ID']['output'];
  referralTemplateId: Scalars['ID']['output'];
  referralTemplateTitle: Scalars['String']['output'];
  sendTo?: Maybe<Scalars['String']['output']>;
  status: ReferralStatus;
  type: ReferralType;
  updatedAt: Scalars['String']['output'];
  userId?: Maybe<Scalars['ID']['output']>;
  userName?: Maybe<Scalars['String']['output']>;
};

export const ReferralContactType = {
  External: 'External',
  Member: 'Member'
} as const;

export type ReferralContactType = typeof ReferralContactType[keyof typeof ReferralContactType];
export type ReferralOnGoal = {
  __typename?: 'ReferralOnGoal';
  goalId: Scalars['ID']['output'];
  referral?: Maybe<Referral>;
  referralId: Scalars['ID']['output'];
};

export const ReferralStatus = {
  Accepted: 'Accepted',
  ClosedSuccessful: 'ClosedSuccessful',
  ClosedUnsuccessful: 'ClosedUnsuccessful',
  InProgress: 'InProgress',
  Rejected: 'Rejected',
  Scheduled: 'Scheduled',
  Sent: 'Sent'
} as const;

export type ReferralStatus = typeof ReferralStatus[keyof typeof ReferralStatus];
export type ReferralTemplate = {
  __typename?: 'ReferralTemplate';
  SMSContent?: Maybe<Scalars['String']['output']>;
  _id: Scalars['ID']['output'];
  accessType?: Maybe<AccessType>;
  consentTemplateId?: Maybe<Scalars['ID']['output']>;
  contactType?: Maybe<ReferralContactType>;
  createdAt: Scalars['String']['output'];
  defaultContact?: Maybe<ExternalResourceContact>;
  defaultContactId?: Maybe<Scalars['ID']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  emailBody?: Maybe<Scalars['String']['output']>;
  emailSubject?: Maybe<Scalars['String']['output']>;
  isActive: Scalars['Boolean']['output'];
  lockingReferences: Array<LockingReference>;
  organizationId: Scalars['ID']['output'];
  resourceId: Scalars['ID']['output'];
  shouldSendActionButtons: Scalars['Boolean']['output'];
  targetTemplateId?: Maybe<Scalars['ID']['output']>;
  targetTemplateName?: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
  type: ReferralType;
  updatedAt: Scalars['String']['output'];
};

export type ReferralTemplateInput = {
  SMSContent?: InputMaybe<Scalars['String']['input']>;
  consentTemplateId?: InputMaybe<Scalars['ID']['input']>;
  contactType?: InputMaybe<ReferralContactType>;
  defaultContactId?: InputMaybe<Scalars['ID']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  emailBody?: InputMaybe<Scalars['String']['input']>;
  emailSubject?: InputMaybe<Scalars['String']['input']>;
  organizationId: Scalars['ID']['input'];
  resourceId: Scalars['ID']['input'];
  shouldSendActionButtons: Scalars['Boolean']['input'];
  targetTemplateId?: InputMaybe<Scalars['ID']['input']>;
  title: Scalars['String']['input'];
  type: ReferralType;
};

export type ReferralTemplateStatusCount = {
  __typename?: 'ReferralTemplateStatusCount';
  closedSuccessfullyCount: Scalars['Int']['output'];
  closedUnsuccessfullySentCount: Scalars['Int']['output'];
  inProgressCount: Scalars['Int']['output'];
  referralTemplate: Scalars['String']['output'];
  scheduledCount: Scalars['Int']['output'];
  sentCount: Scalars['Int']['output'];
};

export const ReferralType = {
  Activity: 'Activity',
  Email: 'Email',
  Sms: 'SMS'
} as const;

export type ReferralType = typeof ReferralType[keyof typeof ReferralType];
export type RemoveCalendarEventInput = {
  id: Scalars['ID']['input'];
  organizationId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
};

export type RemoveCalendarEventResponse = {
  __typename?: 'RemoveCalendarEventResponse';
  message: Scalars['String']['output'];
  success: Scalars['Boolean']['output'];
};

export type ResendEmailInvoiceBatchInput = {
  batchId: Scalars['ID']['input'];
  organizationId: Scalars['ID']['input'];
};

export type ResourceGranularData = {
  __typename?: 'ResourceGranularData';
  granularData: Array<GranularData>;
  resource: Scalars['String']['output'];
};

export type RetireExternalResourceContactInput = {
  _id: Scalars['ID']['input'];
  externalResourceId: Scalars['ID']['input'];
  organizationId: Scalars['ID']['input'];
};

export type RetireReferralTemplateInput = {
  organizationId: Scalars['ID']['input'];
  templateId: Scalars['ID']['input'];
};

export type RevokeUserIntegrationInput = {
  integrationId: Scalars['ID']['input'];
  organizationId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
};

export type RevokeUserIntegrationResponse = {
  __typename?: 'RevokeUserIntegrationResponse';
  message?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

export type Role = {
  __typename?: 'Role';
  _id: Scalars['ID']['output'];
  createdAt: Scalars['String']['output'];
  isEditable: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  organizationId: Scalars['ID']['output'];
  permissions: Array<Permission>;
  updatedAt: Scalars['String']['output'];
  usersCount: Scalars['Int']['output'];
};

export type RunCalculationForMembersInput = {
  calculationId: Scalars['ID']['input'];
  memberIds: Array<Scalars['ID']['input']>;
  organizationId: Scalars['ID']['input'];
  runForAllMembers?: InputMaybe<Scalars['Boolean']['input']>;
};

export type RunCalculationForMembersResponse = {
  __typename?: 'RunCalculationForMembersResponse';
  message?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

export type Saas1TemplateContext = {
  __typename?: 'SAAS1TemplateContext';
  activityId: Scalars['String']['output'];
  initiatingUserId?: Maybe<Scalars['String']['output']>;
  memberId?: Maybe<Scalars['String']['output']>;
};

export type ScheduleActivityActionInput = {
  activityTemplateId: Scalars['ID']['input'];
  id?: InputMaybe<Scalars['ID']['input']>;
  scheduleImmediately: Scalars['Boolean']['input'];
};

export type ScheduleActivityData = {
  __typename?: 'ScheduleActivityData';
  activityTemplateId: Scalars['ID']['output'];
  activityTime?: Maybe<Scalars['String']['output']>;
  carePathwayId?: Maybe<Scalars['ID']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  scheduleImmediately: Scalars['Boolean']['output'];
  userId?: Maybe<Scalars['ID']['output']>;
};

export type ScheduleActivityInput = {
  activityTemplateId: Scalars['ID']['input'];
  activityTime: Scalars['String']['input'];
  carePathwayTemplateId?: InputMaybe<Scalars['String']['input']>;
  scheduleImmediately: Scalars['Boolean']['input'];
  userId?: InputMaybe<Scalars['ID']['input']>;
};

export const ScheduleTypeEnum = {
  Immediately: 'IMMEDIATELY',
  Interval: 'INTERVAL',
  Scheduled: 'SCHEDULED'
} as const;

export type ScheduleTypeEnum = typeof ScheduleTypeEnum[keyof typeof ScheduleTypeEnum];
export type SectionInput = {
  dataIds?: InputMaybe<Array<InputMaybe<CarePlanSectionDataIdInput>>>;
  title: Scalars['String']['input'];
};

export const SendEmailTemplateTo = {
  Email: 'Email',
  Member: 'Member'
} as const;

export type SendEmailTemplateTo = typeof SendEmailTemplateTo[keyof typeof SendEmailTemplateTo];
export type SendTextMessageInput = {
  body: Scalars['String']['input'];
  organizationId: Scalars['String']['input'];
  organizationPhone: Scalars['String']['input'];
  participantPhone: Scalars['String']['input'];
};

export type SendTextMessageResponse = {
  __typename?: 'SendTextMessageResponse';
  data?: Maybe<TextMessage>;
  message?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

export const Sex = {
  Female: 'Female',
  Male: 'Male'
} as const;

export type Sex = typeof Sex[keyof typeof Sex];
export const SexualOrientation = {
  Asexual: 'Asexual',
  Bisexual: 'Bisexual',
  DoNotIdentifyWithLabels: 'DoNotIdentifyWithLabels',
  Gay: 'Gay',
  HeterosexualOrStraight: 'HeterosexualOrStraight',
  Lesbian: 'Lesbian',
  NotListed: 'NotListed',
  Pansexual: 'Pansexual',
  PreferNotToDisclose: 'PreferNotToDisclose',
  Queer: 'Queer'
} as const;

export type SexualOrientation = typeof SexualOrientation[keyof typeof SexualOrientation];
export type SignatureRequest = {
  __typename?: 'SignatureRequest';
  batchId: Scalars['ID']['output'];
  url: Scalars['String']['output'];
};

export type SignatureRequestInput = {
  email: Scalars['String']['input'];
  members: Array<Scalars['ID']['input']>;
  name: Scalars['String']['input'];
  organizationId: Scalars['ID']['input'];
  recommendingProviderId: Scalars['ID']['input'];
};

export type SignedDocument = {
  __typename?: 'SignedDocument';
  url: Scalars['String']['output'];
};

export type SignedPhoneCallRecordingUrlResponse = {
  __typename?: 'SignedPhoneCallRecordingUrlResponse';
  data?: Maybe<Scalars['String']['output']>;
  message?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

export type SignedUrlResponse = {
  __typename?: 'SignedUrlResponse';
  data?: Maybe<Scalars['String']['output']>;
  message?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

export type SortInput = {
  direction: Scalars['Int']['input'];
  field: Scalars['String']['input'];
};

export type StartCarePathwayActionInput = {
  carePathwayTemplateId: Scalars['ID']['input'];
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type StartCarePathwayData = {
  __typename?: 'StartCarePathwayData';
  activities?: Maybe<Array<Maybe<ScheduleActivityData>>>;
  carePathwayTemplateId: Scalars['ID']['output'];
  id?: Maybe<Scalars['ID']['output']>;
};

export type StartGoogleConnectFlowResponse = {
  __typename?: 'StartGoogleConnectFlowResponse';
  data?: Maybe<Scalars['String']['output']>;
  message?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

export type StartMicrosoftConnectFlowResponse = {
  __typename?: 'StartMicrosoftConnectFlowResponse';
  data?: Maybe<Scalars['String']['output']>;
  message?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

export const StripeRequirementsStatus = {
  ActionRequired: 'ActionRequired',
  Completed: 'Completed',
  Pending: 'Pending'
} as const;

export type StripeRequirementsStatus = typeof StripeRequirementsStatus[keyof typeof StripeRequirementsStatus];
export type SubOrg = {
  __typename?: 'SubOrg';
  _id: Scalars['String']['output'];
  createdAt?: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['String']['output']>;
};

export type SubmitAssessmentInput = {
  activityId: Scalars['ID']['input'];
  answers: Array<InputMaybe<AssessmentAnswer>>;
  flowId: Scalars['ID']['input'];
  organizationId: Scalars['ID']['input'];
};

export type SubmitAssessmentResponse = {
  __typename?: 'SubmitAssessmentResponse';
  message?: Maybe<Scalars['String']['output']>;
  stepId?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

export type Subscription = {
  __typename?: 'Subscription';
  activeUsers?: Maybe<Array<User>>;
  createdOrUpdatedTextMessage: TextMessage;
  createdOrUpdatedTextMessageThread: TextMessageThread;
  createdPhoneCallEntry: PhoneCall;
  createdVoicemail: Voicemail;
  deletedPhoneCallEntry: PhoneCall;
  deletedVoicemail: Voicemail;
  notificationReceived: Notification;
  testEvent?: Maybe<TestEventPayload>;
  updatedPhoneCallEntry: PhoneCall;
  updatedVoicemail: Voicemail;
};


export type SubscriptionActiveUsersArgs = {
  organizationId?: InputMaybe<Scalars['ID']['input']>;
};


export type SubscriptionCreatedOrUpdatedTextMessageArgs = {
  organizationId: Scalars['ID']['input'];
};


export type SubscriptionCreatedOrUpdatedTextMessageThreadArgs = {
  organizationId: Scalars['ID']['input'];
};


export type SubscriptionCreatedPhoneCallEntryArgs = {
  organizationId: Scalars['ID']['input'];
};


export type SubscriptionCreatedVoicemailArgs = {
  organizationId: Scalars['ID']['input'];
};


export type SubscriptionDeletedPhoneCallEntryArgs = {
  organizationId: Scalars['ID']['input'];
};


export type SubscriptionDeletedVoicemailArgs = {
  organizationId: Scalars['ID']['input'];
};


export type SubscriptionNotificationReceivedArgs = {
  organizationId: Scalars['ID']['input'];
};


export type SubscriptionTestEventArgs = {
  organizationId: Scalars['ID']['input'];
};


export type SubscriptionUpdatedPhoneCallEntryArgs = {
  organizationId: Scalars['ID']['input'];
};


export type SubscriptionUpdatedVoicemailArgs = {
  organizationId: Scalars['ID']['input'];
};

export type SuggestedDataId = {
  __typename?: 'SuggestedDataId';
  dataId: Scalars['String']['output'];
  suggestedDataId: Scalars['String']['output'];
};

export type SummaryStats = {
  __typename?: 'SummaryStats';
  activationRate: Scalars['Float']['output'];
  activeMembers: Scalars['Float']['output'];
  activeUsers: Scalars['Float']['output'];
  avgAgeOfMembers: Scalars['Float']['output'];
  avgMembersPerUser: Scalars['Float']['output'];
  engagementRate: Scalars['Float']['output'];
  inactiveMembers: Scalars['Float']['output'];
  inactiveUsers: Scalars['Float']['output'];
  livesImpacted: Scalars['Float']['output'];
  newMembers: Scalars['Float']['output'];
  totalMembers: Scalars['Float']['output'];
  totalUsers: Scalars['Float']['output'];
};

export type SummaryStatsResponse = {
  __typename?: 'SummaryStatsResponse';
  message?: Maybe<Scalars['String']['output']>;
  stats?: Maybe<SummaryStats>;
  success: Scalars['Boolean']['output'];
};

export type Tag = {
  __typename?: 'Tag';
  _id: Scalars['ID']['output'];
  createdAt?: Maybe<Scalars['String']['output']>;
  dataId?: Maybe<Scalars['String']['output']>;
  label: Scalars['String']['output'];
  organizationId: Scalars['ID']['output'];
  updatedAt?: Maybe<Scalars['String']['output']>;
};

export type TaggedEntity = {
  __typename?: 'TaggedEntity';
  _id?: Maybe<Scalars['ID']['output']>;
  createdAt?: Maybe<Scalars['String']['output']>;
  dataPointTemplate?: Maybe<DataPointTemplate>;
  dataPointTemplateId?: Maybe<Scalars['ID']['output']>;
  entityType?: Maybe<EntityType>;
  organizationId?: Maybe<Scalars['ID']['output']>;
  psFileId?: Maybe<Scalars['ID']['output']>;
  psfile?: Maybe<PsFile>;
  question?: Maybe<Question>;
  questionId?: Maybe<Scalars['ID']['output']>;
  tag?: Maybe<Tag>;
  updatedAt?: Maybe<Scalars['String']['output']>;
};

export const TaxIdentificationNumberType = {
  Ein: 'EIN',
  Ssn: 'SSN'
} as const;

export type TaxIdentificationNumberType = typeof TaxIdentificationNumberType[keyof typeof TaxIdentificationNumberType];
export type TemplateContext = Gcbau1TemplateContext | Gnc1TemplateContext | Naa1TemplateContext | Nma1TemplateContext | NeutralTemplateContext | OrganizationNotificationTemplateContext | Saas1TemplateContext;

export type TestEventPayload = {
  __typename?: 'TestEventPayload';
  eventCount: Scalars['Int']['output'];
  organizationId: Scalars['ID']['output'];
};

export type TextMessage = {
  __typename?: 'TextMessage';
  _id: Scalars['ID']['output'];
  attachments?: Maybe<Array<Scalars['String']['output']>>;
  body: Scalars['String']['output'];
  createdAt: Scalars['String']['output'];
  direction: TextMessageDirection;
  messageSid?: Maybe<Scalars['String']['output']>;
  notificationSid?: Maybe<Scalars['String']['output']>;
  organizationId: Scalars['ID']['output'];
  organizationPhone: Scalars['String']['output'];
  participantPhone: Scalars['String']['output'];
  status: TextMessageStatus;
};

export const TextMessageDirection = {
  Incoming: 'incoming',
  Outgoing: 'outgoing'
} as const;

export type TextMessageDirection = typeof TextMessageDirection[keyof typeof TextMessageDirection];
export const TextMessageStatus = {
  Accepted: 'accepted',
  Canceled: 'canceled',
  Delivered: 'delivered',
  Failed: 'failed',
  PartiallyDelivered: 'partially_delivered',
  Queued: 'queued',
  Read: 'read',
  Received: 'received',
  Receiving: 'receiving',
  Scheduled: 'scheduled',
  Sending: 'sending',
  Sent: 'sent',
  Undelivered: 'undelivered'
} as const;

export type TextMessageStatus = typeof TextMessageStatus[keyof typeof TextMessageStatus];
export type TextMessageThread = {
  __typename?: 'TextMessageThread';
  _id: Scalars['ID']['output'];
  members?: Maybe<Array<MemberIdAndName>>;
  organizationId: Scalars['String']['output'];
  organizationPhone: Scalars['String']['output'];
  participantPhone: Scalars['String']['output'];
  read: Scalars['Boolean']['output'];
  updatedAt: Scalars['String']['output'];
};

export type TextMessageThreadResponse = {
  __typename?: 'TextMessageThreadResponse';
  data?: Maybe<TextMessageThread>;
  message?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

export type TitledLockingReference = {
  __typename?: 'TitledLockingReference';
  isActive: Scalars['Boolean']['output'];
  referencingId: Scalars['String']['output'];
  referencingType: LockingReferenceType;
  title: Scalars['String']['output'];
};

export type Training = {
  __typename?: 'Training';
  _id: Scalars['ID']['output'];
  continuingEducationUnits?: Maybe<Scalars['Int']['output']>;
  createdAt: Scalars['String']['output'];
  documentationId?: Maybe<Scalars['ID']['output']>;
  expirationDate?: Maybe<Scalars['String']['output']>;
  fileName?: Maybe<Scalars['String']['output']>;
  issueDate?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  organizationId: Scalars['ID']['output'];
  revokedAt?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['String']['output'];
  userId: Scalars['ID']['output'];
};

export type TrainingInput = {
  _id?: InputMaybe<Scalars['ID']['input']>;
  continuingEducationUnits?: InputMaybe<Scalars['Int']['input']>;
  documentationId?: InputMaybe<Scalars['ID']['input']>;
  expirationDate?: InputMaybe<Scalars['String']['input']>;
  issueDate?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  organizationId?: InputMaybe<Scalars['ID']['input']>;
  userId: Scalars['ID']['input'];
};

export type TrainingResponse = {
  __typename?: 'TrainingResponse';
  data?: Maybe<Training>;
  message?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

export type TrainingsResponse = {
  __typename?: 'TrainingsResponse';
  data?: Maybe<Array<Training>>;
  message?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

export type UnassignedMember = {
  __typename?: 'UnassignedMember';
  _id: Scalars['ID']['output'];
  firstName: Scalars['String']['output'];
  groups?: Maybe<Scalars['String']['output']>;
  lastName: Scalars['String']['output'];
  status: MemberStatus;
};

export type UndoLastFlowProgressInput = {
  activityId: Scalars['ID']['input'];
  flowId: Scalars['ID']['input'];
  organizationId: Scalars['ID']['input'];
};

export type UnlinkRecommendingProviderInput = {
  nationalProviderIdentifier: Scalars['String']['input'];
  organizationId: Scalars['ID']['input'];
};

export type UpdateAccessForObjectExtraDataInput = {
  dataIdToNewDataId?: InputMaybe<Array<NewDataIdInput>>;
  groupId?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateAccessForObjectInput = {
  accessType?: InputMaybe<AccessType>;
  extraData?: InputMaybe<UpdateAccessForObjectExtraDataInput>;
  objectId?: InputMaybe<Scalars['String']['input']>;
  objectType: ObjectType;
  organizationId: Scalars['String']['input'];
  subOrgId: Scalars['String']['input'];
};

export type UpdateAccessForObjectResponse = {
  __typename?: 'UpdateAccessForObjectResponse';
  dataIdToSuggestedDataId?: Maybe<Array<SuggestedDataId>>;
  success: Scalars['Boolean']['output'];
};

export type UpdateActivity = {
  appointmentDetails?: InputMaybe<ActivityAppointmentDetailsInput>;
  billable?: InputMaybe<Scalars['Boolean']['input']>;
  billingDetails?: InputMaybe<Array<UpdateActivityBillingDetails>>;
  date?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  duration?: InputMaybe<Scalars['Int']['input']>;
  lastPing?: InputMaybe<Scalars['String']['input']>;
  lastStartedAt?: InputMaybe<Scalars['String']['input']>;
  scheduledEndAt?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<ActivityStatus>;
  syncAppointment?: InputMaybe<Scalars['Boolean']['input']>;
  tasks?: InputMaybe<Array<ActivityTaskInput>>;
  title?: InputMaybe<Scalars['String']['input']>;
  userId?: InputMaybe<Scalars['ID']['input']>;
};

export type UpdateActivityBillingDetails = {
  costId: Scalars['String']['input'];
  diagnosisCodes: Array<Scalars['String']['input']>;
  memberId: Scalars['ID']['input'];
  placeOfService: Scalars['Int']['input'];
};

export type UpdateActivityInput = {
  activityId: Scalars['ID']['input'];
  organizationId: Scalars['ID']['input'];
  updateActivity: UpdateActivity;
};

export type UpdateActivityTaskStatusInput = {
  activityId: Scalars['ID']['input'];
  organizationId: Scalars['ID']['input'];
  status: ActivityStatus;
  statusMessage?: InputMaybe<Scalars['String']['input']>;
  taskId: Scalars['Int']['input'];
};

export type UpdateActivityTemplate = {
  appointmentDetails?: InputMaybe<ActivityAppointmentDetailsInput>;
  billable?: InputMaybe<Scalars['Boolean']['input']>;
  defaultBillingDetails?: InputMaybe<DefaultBillingDetailsInput>;
  description?: InputMaybe<Scalars['String']['input']>;
  syncAppointment?: InputMaybe<Scalars['Boolean']['input']>;
  tasks: Array<ActivityTaskTemplateInput>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateActivityTemplateInput = {
  organizationId: Scalars['ID']['input'];
  templateId: Scalars['ID']['input'];
  updateActivityTemplate: UpdateActivityTemplate;
};

export type UpdateBill = {
  paidAmount?: InputMaybe<Scalars['Float']['input']>;
  status?: InputMaybe<BillStatus>;
  statusMessages?: InputMaybe<Array<Scalars['String']['input']>>;
  transaction?: InputMaybe<BillTransactionInput>;
};

export type UpdateBillInput = {
  id: Scalars['ID']['input'];
  organizationId: Scalars['ID']['input'];
  update: UpdateBill;
};

export type UpdateBillsInput = {
  batchId: Scalars['ID']['input'];
  organizationId: Scalars['ID']['input'];
  update: UpdateBill;
};

export type UpdateCalculationInput = {
  _id: Scalars['ID']['input'];
  expression: Scalars['String']['input'];
  name: Scalars['String']['input'];
  organizationId: Scalars['ID']['input'];
  resultLabels?: InputMaybe<Array<InputMaybe<CalculationResultLabelInput>>>;
  tagNames?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  variables: Array<CalculationVariableInput>;
};

export type UpdateCalendarEventInput = {
  description: Scalars['String']['input'];
  endingAt: Scalars['String']['input'];
  id: Scalars['ID']['input'];
  organizationId: Scalars['ID']['input'];
  scheduledAt: Scalars['String']['input'];
  title: Scalars['String']['input'];
  userId: Scalars['ID']['input'];
};

export type UpdateCalendarEventResponse = {
  __typename?: 'UpdateCalendarEventResponse';
  message: Scalars['String']['output'];
  success: Scalars['Boolean']['output'];
};

export type UpdateCarePathwayStatusActionInput = {
  carePathwayTemplateId: Scalars['ID']['input'];
  id?: InputMaybe<Scalars['ID']['input']>;
  status: CarePathwayStatus;
};

export type UpdateCarePathwayStatusData = {
  __typename?: 'UpdateCarePathwayStatusData';
  carePathwayTemplateId: Scalars['ID']['output'];
  id?: Maybe<Scalars['ID']['output']>;
  status: CarePathwayStatus;
};

export type UpdateCarePathwayStatusInput = {
  carePathwayId: Scalars['ID']['input'];
  organizationId: Scalars['ID']['input'];
  status: CarePathwayStatus;
};

export type UpdateContactInfoInput = {
  address?: InputMaybe<Scalars['String']['input']>;
  address2?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  doNotCall?: InputMaybe<Scalars['Boolean']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  groups?: InputMaybe<Array<Scalars['ID']['input']>>;
  phone?: InputMaybe<Scalars['String']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
  zip?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateDataInput = {
  _id: Scalars['ID']['input'];
  activityId?: InputMaybe<Scalars['ID']['input']>;
  answer?: InputMaybe<Scalars['String']['input']>;
  dataId?: InputMaybe<Scalars['String']['input']>;
  memberId?: InputMaybe<Scalars['ID']['input']>;
  organizationId: Scalars['ID']['input'];
  questionId?: InputMaybe<Scalars['ID']['input']>;
  questionText?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateEhrNoteTemplate = {
  contentType?: InputMaybe<EhrNoteTemplateContentType>;
  document?: InputMaybe<Scalars['String']['input']>;
  documentType?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateEhrNoteTemplateInput = {
  id: Scalars['ID']['input'];
  organizationId: Scalars['ID']['input'];
  update: UpdateEhrNoteTemplate;
};

export type UpdateEmailTemplateInput = {
  _id: Scalars['ID']['input'];
  body?: InputMaybe<Scalars['String']['input']>;
  organizationId: Scalars['ID']['input'];
  recipientAddress?: InputMaybe<Scalars['String']['input']>;
  sendTo?: InputMaybe<SendEmailTemplateTo>;
  subject?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateEpisodeOfCareInput = {
  dataId?: InputMaybe<Scalars['String']['input']>;
  endDate?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  memberId?: InputMaybe<Scalars['ID']['input']>;
  organizationId: Scalars['ID']['input'];
  reasonForClosure?: InputMaybe<Scalars['String']['input']>;
  startDate?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateEventMemberAttendanceInput = {
  attended: Scalars['Boolean']['input'];
  eventId: Scalars['ID']['input'];
  memberId: Scalars['ID']['input'];
  organizationId: Scalars['ID']['input'];
};

export type UpdateEventMemberAttendanceResponse = {
  __typename?: 'UpdateEventMemberAttendanceResponse';
  data?: Maybe<EventMember>;
  message?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

export type UpdateEventTemplateInput = {
  actionData?: InputMaybe<ActionDataInput>;
  description?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  organizationId: Scalars['ID']['input'];
  questionIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateExternalResourceContactInput = {
  _id: Scalars['ID']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  externalResourceId: Scalars['ID']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  organizationId: Scalars['ID']['input'];
  phone?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateExternalResourceInput = {
  _id: Scalars['ID']['input'];
  address?: InputMaybe<Scalars['String']['input']>;
  address2?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  longDescription?: InputMaybe<Scalars['String']['input']>;
  organizationId: Scalars['ID']['input'];
  shortDescription?: InputMaybe<Scalars['String']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  zip?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateFileInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  organizationId: Scalars['ID']['input'];
  tagIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateFlowFromSnapshotEditorInput = {
  associatedActivityId?: InputMaybe<Scalars['ID']['input']>;
  extraActionInfo?: InputMaybe<Array<ExecuteActionInput>>;
  flowId: Scalars['ID']['input'];
  nextActions: Array<ActionInput>;
  nextAnswers: Array<AnswerInput>;
  nextCurrentStepId: Scalars['ID']['input'];
  organizationId: Scalars['ID']['input'];
};

export type UpdateFlowFromSnapshotEditorResponse = {
  __typename?: 'UpdateFlowFromSnapshotEditorResponse';
  createdDataPoints?: Maybe<Array<DataPoint>>;
  createdObjects?: Maybe<Array<CompleteActionData>>;
  data?: Maybe<Flow>;
  message?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

export type UpdateFlowProgressInput = {
  activityId?: InputMaybe<Scalars['String']['input']>;
  answer: Scalars['String']['input'];
  currentStep: Scalars['String']['input'];
  executeActionInput?: InputMaybe<Array<ExecuteActionInput>>;
  flowId: Scalars['ID']['input'];
  organizationId: Scalars['ID']['input'];
};

export type UpdateFlowTemplateInput = {
  _id: Scalars['ID']['input'];
  chapters?: InputMaybe<Array<Scalars['String']['input']>>;
  entryStepId?: InputMaybe<Scalars['String']['input']>;
  organizationId: Scalars['ID']['input'];
  stagePositions?: InputMaybe<JourneyStagePositionsInput>;
  startNodePosition?: InputMaybe<XyCoordInput>;
  steps?: InputMaybe<Array<FlowStepInput>>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateGoalActionInput = {
  goalTemplateId: Scalars['ID']['input'];
  id?: InputMaybe<Scalars['ID']['input']>;
  status: GoalStatus;
};

export type UpdateGoalData = {
  __typename?: 'UpdateGoalData';
  goalTemplateId: Scalars['ID']['output'];
  id?: Maybe<Scalars['ID']['output']>;
  status: GoalStatus;
};

export type UpdateGoalInput = {
  activityIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  carePathwayIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  domainId?: InputMaybe<Scalars['String']['input']>;
  dueDate?: InputMaybe<Scalars['String']['input']>;
  goalId: Scalars['ID']['input'];
  memberId: Scalars['ID']['input'];
  organizationId: Scalars['ID']['input'];
  referralIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  template: GoalTemplateInput;
};

export type UpdateGoalProgress = {
  actionItems?: InputMaybe<Array<ActionItemInput>>;
  status?: InputMaybe<GoalStatus>;
};

export type UpdateGoalProgressInput = {
  goalId: Scalars['ID']['input'];
  memberId: Scalars['ID']['input'];
  organizationId: Scalars['ID']['input'];
  updateGoal: UpdateGoalProgress;
};

export type UpdateGoalTemplate = {
  actionItemDescriptions?: InputMaybe<Array<Scalars['String']['input']>>;
  description?: InputMaybe<Scalars['String']['input']>;
  domainId?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  organizationId?: InputMaybe<Scalars['ID']['input']>;
};

export type UpdateGoalTemplateInput = {
  goalTemplateId: Scalars['ID']['input'];
  organizationId: Scalars['String']['input'];
  updateGoalTemplate: UpdateGoalTemplate;
};

export type UpdateGroupInput = {
  endAt?: InputMaybe<Scalars['String']['input']>;
  endInDays?: InputMaybe<Scalars['Int']['input']>;
  endInMonths?: InputMaybe<Scalars['Int']['input']>;
  endInWeeks?: InputMaybe<Scalars['Int']['input']>;
  title: Scalars['String']['input'];
};

export type UpdateMember = {
  childrenInHousehold?: InputMaybe<Scalars['Int']['input']>;
  contactInfo?: InputMaybe<UpdateContactInfoInput>;
  diagnoses?: InputMaybe<Array<Scalars['String']['input']>>;
  diagnosisCodes?: InputMaybe<Array<Scalars['String']['input']>>;
  dob?: InputMaybe<Scalars['String']['input']>;
  ehrID?: InputMaybe<Scalars['String']['input']>;
  ehrIDType?: InputMaybe<Scalars['String']['input']>;
  employerName?: InputMaybe<Scalars['String']['input']>;
  employmentStatus?: InputMaybe<EmploymentStatus>;
  ethnicity?: InputMaybe<Ethnicity>;
  familyMembers?: InputMaybe<Array<FamilyMemberInput>>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  gender?: InputMaybe<Array<Gender>>;
  highestLevelOfEducation?: InputMaybe<EducationLevel>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  linkedContacts?: InputMaybe<Array<LinkedContactInput>>;
  maidenNameOrAlias?: InputMaybe<Scalars['String']['input']>;
  maritalStatus?: InputMaybe<MaritalStatus>;
  medicaidStatus?: InputMaybe<EnrollmentStatus>;
  medicalRecordNumber?: InputMaybe<Scalars['String']['input']>;
  medicareStatus?: InputMaybe<EnrollmentStatus>;
  nickName?: InputMaybe<Scalars['String']['input']>;
  peopleInHousehold?: InputMaybe<Scalars['Int']['input']>;
  phonetic?: InputMaybe<Scalars['String']['input']>;
  pregnancyStatusEnum?: InputMaybe<PregnancyStatus>;
  prescriptions?: InputMaybe<Array<Scalars['String']['input']>>;
  primaryCIN?: InputMaybe<Scalars['String']['input']>;
  primaryInsuranceCompany?: InputMaybe<Scalars['String']['input']>;
  pronouns?: InputMaybe<Array<Pronouns>>;
  race?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  reasonForDeactivation?: InputMaybe<Scalars['String']['input']>;
  secondaryCIN?: InputMaybe<Scalars['String']['input']>;
  secondaryInsuranceCompany?: InputMaybe<Scalars['String']['input']>;
  sex?: InputMaybe<Sex>;
  sexualOrientation?: InputMaybe<Array<SexualOrientation>>;
  socialSecurityNumber?: InputMaybe<Scalars['String']['input']>;
  spokenLanguages?: InputMaybe<Array<Language>>;
  status?: InputMaybe<MemberStatus>;
  timezone?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateMemberActionInput = {
  field: MemberField;
  id?: InputMaybe<Scalars['ID']['input']>;
  value: UpdateMemberValueInput;
};

export type UpdateMemberData = {
  __typename?: 'UpdateMemberData';
  field: MemberField;
  id?: Maybe<Scalars['ID']['output']>;
  value: UpdateMemberValueType;
};

export type UpdateMemberInput = {
  memberId: Scalars['ID']['input'];
  organizationId: Scalars['ID']['input'];
  updateMember: UpdateMember;
};

export type UpdateMemberValueInput = {
  constant: Scalars['Boolean']['input'];
  input: Scalars['String']['input'];
};

export type UpdateMemberValueType = {
  __typename?: 'UpdateMemberValueType';
  constant: Scalars['Boolean']['output'];
  input: Scalars['String']['output'];
};

export type UpdateNoteInput = {
  noteId: Scalars['ID']['input'];
  organizationId: Scalars['ID']['input'];
  redacted: Scalars['Boolean']['input'];
};

export type UpdateNoteTemplateInput = {
  additionalFields?: InputMaybe<Array<AdditionalFieldsInput>>;
  content?: InputMaybe<Scalars['String']['input']>;
  domainIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  noteTemplateId: Scalars['ID']['input'];
  organizationId: Scalars['ID']['input'];
  title?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateOnboardingSurveyInput = {
  organizationId: Scalars['ID']['input'];
  surveyId: Scalars['ID']['input'];
  templateId?: InputMaybe<Scalars['ID']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateOrganization = {
  activatedIntegrations?: InputMaybe<Array<Integration>>;
  analyticsConfiguration?: InputMaybe<AnalyticsConfigurationInput>;
  apiKeyManagementEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  attendingId?: InputMaybe<Scalars['String']['input']>;
  attendingIdType?: InputMaybe<Scalars['String']['input']>;
  callRecordingEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  callerId?: InputMaybe<Scalars['String']['input']>;
  carePlanTemplateId?: InputMaybe<Scalars['ID']['input']>;
  carePlansEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  claimsPlatformFeePercentage?: InputMaybe<Scalars['Int']['input']>;
  department?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  diagnosisCodes?: InputMaybe<Array<DiagnosisCodeInput>>;
  domains?: InputMaybe<Array<InputMaybe<DomainInput>>>;
  ehrNoteProviderCredential?: InputMaybe<Scalars['String']['input']>;
  ehrNoteProviderFirstName?: InputMaybe<Scalars['String']['input']>;
  ehrNoteProviderID?: InputMaybe<Scalars['String']['input']>;
  ehrNoteProviderIDType?: InputMaybe<Scalars['String']['input']>;
  ehrNoteProviderLastName?: InputMaybe<Scalars['String']['input']>;
  episodesOfCareEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  hubControlEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  memberOverviewConfiguration?: InputMaybe<Array<InputMaybe<MemberOverviewConfigurationMetricCategoryInput>>>;
  memberProfileSettings?: InputMaybe<MemberProfileSettingsInput>;
  members?: InputMaybe<Array<Scalars['ID']['input']>>;
  mfaPolicy?: InputMaybe<MfaPolicy>;
  onboardingMemberProfileSettings?: InputMaybe<MemberProfileSettingsInput>;
  parentCallRoutingConfigId?: InputMaybe<Scalars['String']['input']>;
  parentCarePlanTemplateId?: InputMaybe<Scalars['ID']['input']>;
  parentId?: InputMaybe<Scalars['String']['input']>;
  placeOfServiceCodes?: InputMaybe<Array<Scalars['Int']['input']>>;
  plan?: InputMaybe<Plan>;
  seats?: InputMaybe<Scalars['Int']['input']>;
  showMemberOverviewTab?: InputMaybe<Scalars['Boolean']['input']>;
  stripeCustomPriceId?: InputMaybe<Scalars['String']['input']>;
  stripeCustomerId?: InputMaybe<Scalars['String']['input']>;
  timeTrackingEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  users?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type UpdateOrganizationInput = {
  domains?: InputMaybe<Array<InputMaybe<DomainInput>>>;
  organizationId: Scalars['String']['input'];
  updateOrganization: UpdateOrganization;
};

export type UpdateOrganizationResponse = {
  __typename?: 'UpdateOrganizationResponse';
  data?: Maybe<Organization>;
  message?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

export type UpdatePhoneCallEntryInput = {
  disposition?: InputMaybe<Disposition>;
  identifier: Scalars['String']['input'];
  identifierType: PhoneCallIdentifierType;
  organizationId: Scalars['String']['input'];
  userId: Scalars['ID']['input'];
};

export type UpdateProviderRecommendationBatchStatusResponse = {
  __typename?: 'UpdateProviderRecommendationBatchStatusResponse';
  message?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

export type UpdateProviderRecommendationStatusResponse = {
  __typename?: 'UpdateProviderRecommendationStatusResponse';
  message?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

export type UpdateQuestionInput = {
  _id: Scalars['ID']['input'];
  answerOptions?: InputMaybe<Array<Scalars['String']['input']>>;
  answerType?: InputMaybe<AnswerType>;
  dataId?: InputMaybe<Scalars['String']['input']>;
  organizationId: Scalars['String']['input'];
  questionText?: InputMaybe<Scalars['String']['input']>;
  questionTitle?: InputMaybe<Scalars['String']['input']>;
  tagNames?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type UpdateRecommendationInput = {
  _id: Scalars['ID']['input'];
  actions?: InputMaybe<Array<ActionDataInput>>;
  conditionsGroups: Array<InputMaybe<ConditionsGroupItemInput>>;
  name?: InputMaybe<Scalars['String']['input']>;
  organizationId: Scalars['ID']['input'];
  recommendationText?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateReferralStatusInput = {
  organizationId: Scalars['ID']['input'];
  referralId: Scalars['ID']['input'];
  status: ReferralStatus;
};

export type UpdateReferralStatusPublicInput = {
  referralId: Scalars['ID']['input'];
  status: ReferralStatus;
};

export type UpdateReferralTemplateInput = {
  SMSContent?: InputMaybe<Scalars['String']['input']>;
  consentTemplateId?: InputMaybe<Scalars['ID']['input']>;
  contactType?: InputMaybe<ReferralContactType>;
  defaultContactId?: InputMaybe<Scalars['ID']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  emailBody?: InputMaybe<Scalars['String']['input']>;
  emailSubject?: InputMaybe<Scalars['String']['input']>;
  organizationId: Scalars['ID']['input'];
  shouldSendActionButtons: Scalars['Boolean']['input'];
  targetTemplateId?: InputMaybe<Scalars['ID']['input']>;
  templateId: Scalars['ID']['input'];
  title?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<ReferralType>;
};

export type UpdateTagInput = {
  dataId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  label?: InputMaybe<Scalars['String']['input']>;
  organizationId: Scalars['ID']['input'];
};

export type UpdateUser = {
  certifications?: InputMaybe<Array<CertificationInput>>;
  email?: InputMaybe<Scalars['String']['input']>;
  isSuperAdmin: Scalars['Boolean']['input'];
  location?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  organizationIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  renderingProvider?: InputMaybe<BillingProviderInput>;
  renderingProviderId?: InputMaybe<Scalars['ID']['input']>;
  spokenLanguages?: InputMaybe<Array<Language>>;
  trainings?: InputMaybe<Array<TrainingInput>>;
  userOrgRoles: Array<UserOrgRoleInput>;
};

export type UpdateUserGroupInput = {
  _id: Scalars['ID']['input'];
  addUsers?: InputMaybe<Array<IdObject>>;
  organizationId: Scalars['ID']['input'];
  removeUsers?: InputMaybe<Array<IdObject>>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateUserInput = {
  organizationId: Scalars['ID']['input'];
  updateUser: UpdateUser;
  userId: Scalars['ID']['input'];
};

export type UpdateVoicemailByCallInput = {
  call: Scalars['ID']['input'];
  hidden?: InputMaybe<Scalars['Boolean']['input']>;
  listenedTo?: InputMaybe<Scalars['Boolean']['input']>;
  organizationId: Scalars['ID']['input'];
  recordingSid?: InputMaybe<Scalars['String']['input']>;
  sid?: InputMaybe<Scalars['String']['input']>;
  transcription?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateVoicemailInput = {
  _id: Scalars['ID']['input'];
  call?: InputMaybe<Scalars['ID']['input']>;
  hidden?: InputMaybe<Scalars['Boolean']['input']>;
  listenedTo?: InputMaybe<Scalars['Boolean']['input']>;
  organizationId: Scalars['ID']['input'];
  recordingSid?: InputMaybe<Scalars['String']['input']>;
  sid?: InputMaybe<Scalars['String']['input']>;
  transcription?: InputMaybe<Scalars['String']['input']>;
};

export type UploadProviderRecommendationForMembersInput = {
  file: Scalars['Upload']['input'];
  memberIds: Array<Scalars['ID']['input']>;
  organizationId: Scalars['ID']['input'];
  recommendingProviderId?: InputMaybe<Scalars['ID']['input']>;
};

export type UploadPsFileInput = {
  createdAt?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  file: Scalars['Upload']['input'];
  genericFolderId?: InputMaybe<Scalars['ID']['input']>;
  isCarePlanSnapshot?: InputMaybe<Scalars['Boolean']['input']>;
  memberId?: InputMaybe<Scalars['ID']['input']>;
  organizationId: Scalars['ID']['input'];
  tagIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  title: Scalars['String']['input'];
};

export type UpsertRecommendingProviderInput = {
  _id?: InputMaybe<Scalars['ID']['input']>;
  companyName?: InputMaybe<Scalars['String']['input']>;
  faxNumber?: InputMaybe<Scalars['String']['input']>;
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  nationalProviderIdentifier: Scalars['String']['input'];
  organizationId: Scalars['ID']['input'];
  title?: InputMaybe<Scalars['String']['input']>;
  userEmail: Scalars['String']['input'];
};

export type User = {
  __typename?: 'User';
  _id: Scalars['ID']['output'];
  assignedMemberIds?: Maybe<Array<Scalars['ID']['output']>>;
  certifications?: Maybe<Array<Certification>>;
  createdAt: Scalars['String']['output'];
  email: Scalars['String']['output'];
  isActive: Scalars['Boolean']['output'];
  isSuperAdmin: Scalars['Boolean']['output'];
  location?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  organizationIds?: Maybe<Array<Maybe<Scalars['ID']['output']>>>;
  recommendingProvider?: Maybe<RecommendingProviderInfo>;
  renderingProvider?: Maybe<BillingProvider>;
  spokenLanguages?: Maybe<Array<Language>>;
  status?: Maybe<UserStatus>;
  trainings?: Maybe<Array<Training>>;
  updatedAt: Scalars['String']['output'];
  userOrgRoles?: Maybe<Array<UserOrgRole>>;
};

export type UserBrief = {
  __typename?: 'UserBrief';
  _id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export const UserEvent = {
  Activated: 'Activated',
  Deactivated: 'Deactivated',
  Offline: 'Offline',
  Online: 'Online'
} as const;

export type UserEvent = typeof UserEvent[keyof typeof UserEvent];
export type UserGoalInput = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  completed?: InputMaybe<Scalars['Boolean']['input']>;
  organizationId: Scalars['String']['input'];
  userId: Scalars['ID']['input'];
};

export type UserGroup = {
  __typename?: 'UserGroup';
  _id: Scalars['ID']['output'];
  lockingReferences: Array<LockingReference>;
  title: Scalars['String']['output'];
  users: Array<UserIdAndName>;
};

export type UserIdAndName = {
  __typename?: 'UserIdAndName';
  _id: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type UserInput = {
  email: Scalars['String']['input'];
  isSuperAdmin: Scalars['Boolean']['input'];
  location?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  organizationIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  recommendingProvider?: InputMaybe<RecommendingProviderInput>;
  spokenLanguages?: InputMaybe<Array<Language>>;
  userOrgRoles: Array<UserOrgRoleInput>;
};

export type UserIntegration = {
  __typename?: 'UserIntegration';
  id: Scalars['Int']['output'];
  with: Scalars['String']['output'];
};

export type UserIntegrationInput = {
  organizationId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
};

export type UserMemberInput = {
  memberId: Scalars['ID']['input'];
  organizationId?: InputMaybe<Scalars['ID']['input']>;
  userId: Scalars['ID']['input'];
};

export type UserOrgRole = {
  __typename?: 'UserOrgRole';
  isEditable: Scalars['Boolean']['output'];
  organizationId: Scalars['String']['output'];
  permissions: Array<Permission>;
  roleId: Scalars['String']['output'];
  roleName: Scalars['String']['output'];
};

export type UserOrgRoleInput = {
  organizationId: Scalars['String']['input'];
  roleId: Scalars['String']['input'];
};

export type UserStat = {
  __typename?: 'UserStat';
  _id: Scalars['ID']['output'];
  assignedMembers?: Maybe<Array<MemberBrief>>;
  billableAmount: Scalars['Float']['output'];
  billableUnits: Scalars['Int']['output'];
  createdAt: Scalars['String']['output'];
  email: Scalars['String']['output'];
  isActive: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  numActiveMember: Scalars['Int']['output'];
  numActivitiesCompleted: Scalars['Int']['output'];
  numActivitiesScheduled: Scalars['Int']['output'];
  numGoalsCompleted: Scalars['Int']['output'];
  numGoalsCreated: Scalars['Int']['output'];
  onlineDurationInMinutes: Scalars['Float']['output'];
  status: UserStatus;
  updatedAt: Scalars['String']['output'];
};

export const UserStatus = {
  InCall: 'InCall',
  Offline: 'Offline',
  Online: 'Online'
} as const;

export type UserStatus = typeof UserStatus[keyof typeof UserStatus];
export type VoiceGrantAccessTokenResponse = {
  __typename?: 'VoiceGrantAccessTokenResponse';
  data?: Maybe<Scalars['String']['output']>;
  message?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

export type Voicemail = {
  __typename?: 'Voicemail';
  _id: Scalars['ID']['output'];
  call: PhoneCall;
  createdAt: Scalars['String']['output'];
  hidden?: Maybe<Scalars['Boolean']['output']>;
  listenedTo: Scalars['Boolean']['output'];
  organizationId: Scalars['ID']['output'];
  recordingSid: Scalars['String']['output'];
  sid: Scalars['String']['output'];
  transcription?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['String']['output'];
};

export type VoicemailResponse = {
  __typename?: 'VoicemailResponse';
  data?: Maybe<Voicemail>;
  message?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

export type VoicemailsResponse = {
  __typename?: 'VoicemailsResponse';
  data?: Maybe<PaginatedVoicemails>;
  message?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

export type XyCoord = {
  __typename?: 'XYCoord';
  x: Scalars['Int']['output'];
  y: Scalars['Int']['output'];
};

export type XyCoordInput = {
  x: Scalars['Int']['input'];
  y: Scalars['Int']['input'];
};
