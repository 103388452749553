import { StepNodeData } from "src/components/flow-graph/util";
import {
  AnswerType,
  ConditionOperator,
  ConditionsGroupItem,
  CreateRecommendationInput,
  Operator,
} from "src/graphql";
import { BASE_RECOMMENDATION_ID } from ".";
import { Position, Node } from "react-flow-renderer";
import { generateMongoObjectID } from "src/components/carepathways/utils";

export type RecommendationExtended = Omit<
  CreateRecommendationInput,
  "organizationId"
> & {
  _id?: string;
  organizationId?: string;
  conditionsGroups?: ConditionsGroupItem[];
};

export const createBaseRecommendationObj = (
  organizationId: string
): RecommendationExtended => ({
  _id: BASE_RECOMMENDATION_ID,
  name: "",
  organizationId: organizationId,
  recommendationText: "",
  conditionsGroups: [
    {
      _id: generateMongoObjectID(),
      conditionOperator: ConditionOperator.And,
      conditions: [
        {
          _id: generateMongoObjectID(),
          operator: Operator.Equal,
          conditionOperator: ConditionOperator.And,
          dataId: "",
          value: [],
        },
      ],
    },
  ],
  actions: [],
});

export const createDummyNode = () => {
  const stepNode: Node<StepNodeData> = {
    type: "stepFlowNode",
    data: {
      isInputNode: false,
      actions: [],
      step: {
        nodePosition: {
          x: 1,
          y: 1,
        },
        stepId: "stepOd",
        question: {
          _id: "dummyId",
          answerType: AnswerType.ReadOnlyText,
          dataId: "dataId",
          questionText: "",
          questionTitle: "",
          createdAt: "",
          updatedAt: "",
          questionType: "Question",
          lockingReferences: [],
          organizationId: "",
        },
      },
      isMultiOutput: false,
    },
    targetPosition: Position.Left,
    sourcePosition: Position.Right,
    position: { x: 1, y: 1 },
    id: "stepId",
  };

  return stepNode;
};

export const AnswerTypeOperators: Record<AnswerType, Operator[]> = {
  [AnswerType.Boolean]: [Operator.Equal, Operator.NotEqual],
  [AnswerType.Date]: [
    Operator.Equal,
    Operator.NotEqual,
    Operator.GreaterThanOrEqual,
    Operator.GreaterThan,
    Operator.LessThan,
    Operator.LessThanOrEqual,
  ],
  [AnswerType.Multi]: [Operator.Equal, Operator.NotEqual],
  [AnswerType.MultiChoice]: [
    Operator.Equal,
    Operator.NotEqual,
    Operator.Contains,
    Operator.NotContains,
  ],
  [AnswerType.Number]: [
    Operator.Equal,
    Operator.NotEqual,
    Operator.GreaterThanOrEqual,
    Operator.GreaterThan,
    Operator.LessThan,
    Operator.LessThanOrEqual,
  ],
  [AnswerType.Text]: [
    Operator.Equal,
    Operator.NotEqual,
    Operator.Contains,
    Operator.NotContains,
  ],
  [AnswerType.ReadOnlyText]: [],
};
