import { Button, LoadingOverlay } from "@mantine/core";
import { FormikHelpers, FormikProps } from "formik";
import log from "loglevel";
import { useMemo, useRef, useState } from "react";

import {
  GoalTemplateForm,
  GoalTemplateFormValues,
  makeBaseGoalTemplate,
  TemplateFormContext,
} from "src/components";
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalSubHeader,
} from "src/components/modal";
import { WarnModalContent } from "src/components/warn-locking-references-modal/WarningModalContent";
import {
  GoalTemplate,
  Permission,
  useMutationCreateGoalTemplate,
  useMutationUpdateGoalTemplate,
  useQueryGoalTemplates,
} from "src/graphql";
import { useAuthContext } from "src/hooks";
import { BASE_CREATE_TEMPLATE_ID } from "../templates";

type GoalTemplateModalProps = {
  selectedTemplateId?: string;
  /**
   * Optional hook to run when the modal requests to close
   */
  onRequestClose?: () => void;
  /**
   * Optional hook to run after the modal successfully updates a template
   */
  afterUpdateSuccess?: () => void;
};

export const GoalTemplateModal = ({
  selectedTemplateId,
  onRequestClose,
  afterUpdateSuccess,
}: GoalTemplateModalProps) => {
  const { selectedOrganizationId, hasAnyPermission } = useAuthContext();
  const [showWarningView, setShowWarningView] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [pendingTemplate, setPendingTemplate] = useState<GoalTemplate>();
  const [isDirty, setIsDirty] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const canSubmit = isDirty && isValid;
  const [submissionPending, setSubmissionPending] = useState(false);
  const formikRef = useRef<FormikProps<GoalTemplateFormValues>>(null);

  const { loading, data: templatesResponse } = useQueryGoalTemplates(
    selectedOrganizationId,
    selectedTemplateId === BASE_CREATE_TEMPLATE_ID
  );

  const selectedTemplate = useMemo(
    () =>
      selectedTemplateId === BASE_CREATE_TEMPLATE_ID
        ? makeBaseGoalTemplate(selectedOrganizationId)
        : templatesResponse?.goalTemplates.data?.find(
            (template) => template.goalTemplate._id === selectedTemplateId
          )?.goalTemplate,
    [selectedTemplateId, selectedOrganizationId, templatesResponse]
  );

  const selectedTemplateAccess = useMemo(
    () =>
      templatesResponse?.goalTemplates.data?.find(
        (template) => template.goalTemplate._id === selectedTemplateId
      )?.accessType ?? "Write",
    [selectedTemplateId, templatesResponse]
  );

  const [mutationCreateTemplate] = useMutationCreateGoalTemplate();
  const [mutationUpdateTemplate] = useMutationUpdateGoalTemplate();

  const references = selectedTemplate?.lockingReferences.length
    ? selectedTemplate.lockingReferences.filter((ref) => ref.isActive)
    : [];

  const handleSubmit = async (
    goalTemplate: GoalTemplate,
    formikHelpers: FormikHelpers<GoalTemplateFormValues>
  ) => {
    const isNewTemplate = goalTemplate._id === BASE_CREATE_TEMPLATE_ID;
    const formikApi = formikRef.current;
    if (!formikApi)
      throw new Error(
        "Formik ref not populated, check GoalTemplateModal component definition"
      );

    try {
      if (isNewTemplate) {
        setSubmissionPending(true);
        const { _id, lockingReferences, ...input } = goalTemplate;
        const res = await mutationCreateTemplate({
          variables: { input },
        });
        if (
          res.errors?.length ||
          res.data?.createGoalTemplate?.success === false
        ) {
          formikHelpers.setStatus({
            errorMessage: "Oops! Something went wrong. Please try again.",
          });
        } else {
          afterUpdateSuccess?.();
        }
      } else {
        setPendingTemplate(goalTemplate);
        if (references.length > 0) {
          setShowWarningView(true);
        } else {
          submit(goalTemplate);
        }
      }
    } catch (e) {
      if (process.env.NODE_ENV === "development") log.log(e);
      formikHelpers.setStatus({
        errorMessage: "Oops! Something went wrong. Please try again.",
      });
    }

    setSubmissionPending(false);
  };

  const submit = async (values: GoalTemplate | undefined) => {
    if (!values) return;
    setSubmissionPending(true);

    try {
      const {
        _id: goalTemplateId,
        lockingReferences,
        ...updateGoalTemplate
      } = values;

      const res = await mutationUpdateTemplate({
        variables: {
          input: {
            goalTemplateId,
            organizationId: selectedOrganizationId ?? "",
            updateGoalTemplate,
          },
        },
      });
      if (
        res.errors?.length ||
        res.data?.updateGoalTemplate?.success === false
      ) {
        setErrorMessage("Oops! Something went wrong. Please try again.");
      } else {
        afterUpdateSuccess?.();
      }
    } catch (e) {
      if (process.env.NODE_ENV === "development") log.log(e);
      setErrorMessage("Oops! Something went wrong. Please try again.");
    }

    setShowWarningView(false);
    setPendingTemplate(undefined);
    setSubmissionPending(false);
  };

  const handleRequestClose = () => {
    formikRef.current?.resetForm();
    setIsDirty(false);
    setIsValid(false);
    onRequestClose?.();
  };

  const handleCancelUpdate = () => {
    setShowWarningView(false);
    setPendingTemplate(undefined);
    onRequestClose?.();
  };

  const readOnly =
    !hasAnyPermission(Permission.BuilderToolsWriteAccess) ||
    selectedTemplateAccess === "Read";

  const renderGoalTemplateForm = () => (
    <>
      <ModalBody>
        <GoalTemplateForm
          selectedTemplate={selectedTemplate}
          onSubmit={handleSubmit}
          innerRef={formikRef}
          formContext={TemplateFormContext.CreateTemplate}
          onDirtyStateChange={setIsDirty}
          onValidStateChange={setIsValid}
          readOnly={readOnly}
        />
      </ModalBody>

      <ModalFooter>
        {readOnly && (
          <Button
            color="red"
            onClick={onRequestClose}
            disabled={submissionPending}
          >
            Close
          </Button>
        )}

        {!readOnly && (
          <>
            <Button
              color="red"
              variant="outline"
              onClick={onRequestClose}
              disabled={submissionPending}
            >
              Cancel
            </Button>

            <Button
              onClick={formikRef.current?.submitForm}
              disabled={!canSubmit}
              loading={submissionPending}
            >
              Save
            </Button>
          </>
        )}
      </ModalFooter>
    </>
  );

  return (
    <Modal
      opened={!!selectedTemplateId || submissionPending}
      onClose={handleRequestClose}
    >
      <ModalHeader withSubHeader={showWarningView}>
        Goal Template Configuration
      </ModalHeader>

      {readOnly && (
        <ModalSubHeader>
          <small>You do not have permission to edit this template</small>
        </ModalSubHeader>
      )}

      {showWarningView ? (
        <WarnModalContent
          lockedObject="Goal Template"
          activeReferences={references}
          onConfirm={() => submit(pendingTemplate)}
          onCancel={handleCancelUpdate}
          isLoading={submissionPending}
          errorMessage={errorMessage}
        />
      ) : (
        renderGoalTemplateForm()
      )}

      <LoadingOverlay visible={loading} />
    </Modal>
  );
};
