import React, { PropsWithChildren, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  ActionIcon,
  Badge,
  Box,
  Divider,
  Drawer,
  Group,
  Popover,
  Select,
  Title,
  Tooltip,
  useMantineTheme,
} from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import {
  ChevronDown,
  LogOut,
  Plus,
  User as UserIcon,
  Bell,
} from "react-feather";
/**
 * @deprecated
 */
import styled from "styled-components";

import { useAuthContext } from "src/hooks";
import { PearLogo } from "../auth/AuthSplash";
import { MASTHEAD_HEIGHT } from "./constants";
import { MastheadActivityStatus } from "../user-current-activity/Status";
import { NotificationsContainer } from "../notifications-modal/NotificationsContainer";
import { useQueryNotificationsMetadata } from "src/graphql/Notification";
import { NotificationSettings } from "../notifications-modal/NotificationSettings";
import { Permission } from "src/graphql";

/**
 * @deprecated
 */
const StyledContainer = styled.div`
  position: fixed;
  width: 100%;
  z-index: 11;
  background-color: var(--color-pear-green);
  display: flex;
  align-items: stretch;
  height: ${MASTHEAD_HEIGHT}px;
  flex-shrink: 0;
  -webkit-box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.12);
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.12);
`;

/**
 * @deprecated
 */
const LogoContainer = styled.div`
  height: ${MASTHEAD_HEIGHT}px;
  width: 52px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background-color: var(--color-pear-green-highlight);
  }

  &:active {
    background-color: transparent;
  }
`;

/**
 * @deprecated
 */
const LogoutContainer = styled.div`
  height: ${MASTHEAD_HEIGHT}px;
  cursor: pointer;
  display: flex;
  align-items: center;
  color: white;
  padding: 0px 16px;

  &:hover {
    background-color: var(--color-pear-green-highlight);
  }

  &:active {
    background-color: transparent;
  }
`;

/**
 * @deprecated
 */
const BellIconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-right: 1px dotted white;
  box-sizing: border-box;
  padding-left: 20px;
  padding-right: 20px;
  transform: rotate(-45px);
  position: relative;

  &:hover {
    background-color: var(--color-pear-green-highlight);
  }

  &:active {
    background-color: transparent;
  }

  > #bell-icon {
    transform: rotate(45deg);
  }
`;

/**
 * @deprecated
 */
const Action = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding-left: 10px;
  padding-right: 10px;

  &:hover {
    background-color: var(--color-pear-green-highlight);
  }

  &:active {
    background-color: transparent;
  }
`;

export const Masthead = () => {
  const theme = useMantineTheme();
  const { signOut, hasExactPermissions } = useAuthContext();
  const {
    currentUser,
    organizationOptions,
    selectedOrganizationId,
    setSelectedOrganizationId,
  } = useAuthContext();
  const history = useHistory();

  const { data: notificationsMetadata } = useQueryNotificationsMetadata();

  const [changeNotificationModalOpen, setChangeNotificationModalOpen] =
    useState(false);

  const [
    isSettingsModalOpen,
    { open: openSettingsDrawer, close: closeSettingsDrawer },
  ] = useDisclosure(false);

  //
  // Handlers
  //

  const handleSignOut = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    signOut();
  };

  const handleRouteToDashboard = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    history.push("/");
  };

  const gotoSettingsPage = () => {
    history.push("/settings/profile");
  };

  //
  // Render
  //

  return (
    <StyledContainer>
      <a href={"/dashboard"} onClick={handleRouteToDashboard}>
        {hasExactPermissions(Permission.RecommendingProvider) ? (
          <Group position="apart" spacing="xs" style={{ alignItems: "center" }}>
            <LogoContainer>
              <PearLogo fill="white" size={32} />
            </LogoContainer>
            <Title color="white" mt="xs">
              Pear Suite
            </Title>
          </Group>
        ) : (
          <LogoContainer>
            <PearLogo fill="white" size={32} />
          </LogoContainer>
        )}
      </a>

      {!hasExactPermissions(Permission.RecommendingProvider) && (
        <Select
          org-select="true"
          pl="sm"
          radius={0}
          variant="unstyled"
          styles={{
            root: {
              height: "100%",
              "& > div": { height: "100%" },
              "&:hover": {
                backgroundColor:
                  theme.colors.green[(theme.primaryShade as number) - 1],
              },
              "&:active": {
                backgroundColor:
                  theme.colors.green[theme.primaryShade as number],
              },
              paddingLeft: "0px !important",
              fontSize: theme.fontSizes.md,
            },
            wrapper: { height: "100%" },
            input: {
              height: "100%",
              color: "white",
              paddingLeft: 16,
              fontSize: 16,
              cursor: "pointer",
            },
            rightSection: { pointerEvents: "none", width: 36, display: "flex" },
          }}
          rightSection={
            <>
              <Divider
                mr={0}
                my="sm"
                orientation="vertical"
                style={{ flexGrow: 0 }}
              />
              <ChevronDown color="white" style={{ flexGrow: 1 }} />
            </>
          }
          searchable={true}
          name="organization"
          data={organizationOptions}
          value={selectedOrganizationId}
          onChange={(organizationId) => {
            if (organizationId) setSelectedOrganizationId(organizationId);
            history.push("/");
          }}
          data-testid="organization-select"
        />
      )}

      {currentUser.isSuperAdmin && (
        <Action onClick={() => history.push("/organization/create")}>
          <Plus color="white" size={18} />
        </Action>
      )}

      <Box style={{ flexGrow: 1 }} />

      <MastheadActivityStatus />

      <Popover
        radius="4px"
        width="380px"
        position="bottom"
        withArrow
        keepMounted
        styles={{
          dropdown: {
            maxHeight: "77vh",
            overflowY: "auto",
            boxShadow: "0px 4px 5px 0px rgba(0,0,0,0.2)",
          },
        }}
        onChange={(opened) => {
          setChangeNotificationModalOpen(opened);
        }}
      >
        <Popover.Target>
          <BellIconContainer>
            <Bell id="bell-icon" color="white" />
            {Boolean(
              notificationsMetadata?.notificationsMetadata.data
                ?.unreadNotificationsCount
            ) && (
              <Badge
                px="5px"
                style={{
                  position: "absolute",
                  backgroundColor: "orange",
                  color: "white",
                  top: "5px",
                  right: "5px",
                }}
              >
                {
                  notificationsMetadata?.notificationsMetadata.data
                    ?.unreadNotificationsCount
                }
              </Badge>
            )}
          </BellIconContainer>
        </Popover.Target>
        <Popover.Dropdown
          style={{
            borderColor: "lightgrey",
          }}
        >
          <NotificationsContainer
            changeNotificationModalOpen={changeNotificationModalOpen}
            openSettingsDrawer={openSettingsDrawer}
          />
        </Popover.Dropdown>
      </Popover>

      <Box style={withDottedLine}>
        <MastheadActionIcon
          tooltipLabel="User Settings"
          onClick={gotoSettingsPage}
        >
          <UserIcon color="white" />
        </MastheadActionIcon>
      </Box>

      <a href="/login" onClick={handleSignOut}>
        <LogoutContainer>
          <LogOut size={18} />
          &nbsp;Logout
        </LogoutContainer>
      </a>

      <Drawer
        opened={isSettingsModalOpen}
        onClose={closeSettingsDrawer}
        withCloseButton
        closeOnClickOutside={false}
        closeOnEscape={false}
        title="Notification Settings"
        position="right"
        size="lg"
        overlayProps={{ opacity: 0.5, blur: 4 }}
      >
        <NotificationSettings />
      </Drawer>
    </StyledContainer>
  );
};

//
// Helpers
//

type MastheadActionIconProps = PropsWithChildren<{
  tooltipLabel: string;
  onClick: React.MouseEventHandler<HTMLButtonElement>;
}>;

const MastheadActionIcon = (props: MastheadActionIconProps) => {
  return (
    <Tooltip
      position="bottom"
      withArrow
      arrowPosition="center"
      label={props.tooltipLabel}
    >
      <ActionIcon
        w={48}
        h={48}
        radius={0}
        sx={{
          ":hover": {
            backgroundColor: "var(--color-pear-green-highlight)",
          },
        }}
        onClick={props.onClick}
        data-testid="user-settings-link"
      >
        {props.children}
      </ActionIcon>
    </Tooltip>
  );
};

const withDottedLine: React.CSSProperties = {
  borderRight: "1px dotted white",
};
