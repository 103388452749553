import { useState, useRef } from "react";
import ReactFlow, {
  Controls,
  OnNodesChange,
  applyNodeChanges,
  Edge,
  Background,
  Node,
  OnConnect,
  addEdge,
  useUpdateNodeInternals,
  useViewport,
  Viewport,
  useReactFlow,
} from "react-flow-renderer";
import isEqual from "lodash/isEqual";
import { XYCoord } from "react-dnd";
import toast from "src/libs/toast";
import {
  Dimensions,
  DragType,
  DropHandler,
  Droppable,
} from "src/components/drag-and-drop";
import { Action, FlowType, JourneyStagePositions, Question } from "src/graphql";
import {
  ActionInfo,
  nodesFromQuestionDrop,
  PearNodesData,
  isAnswerFlowNode,
  isStepFlowNode,
  StepNodeData,
  findParentOrChildNodeGroup,
  findEdgesForNodeGroup,
  findNodeById,
  findNodeByQuestionId,
  questionToAnswerNodes,
  actionInfoToActionData,
  edgeFromNodeIds,
  copyFrozen,
  findStepOrAnswerNodeGroup,
  scrollToNode,
} from "../util";
import { templateEditorNodeTypes } from "../flow-nodes";
import {
  FlowNodeRenderContext,
  NodeMeasuringContainer,
  NodeTuplesToMeasure,
  OrderedStepNodeTuple,
} from "../NodeMeasuringContainer";
import { FlowBuilderContext } from "./context";
import { AddActionModal } from "./add-action-modal/AddActionModal";
import { QuestionListPane } from "./question-list-pane";
import { StepNodeIdentifyingToast } from "./toasts";
import { FlowBuilderData } from "../hooks";
import {
  GrabbableJourneyStage,
  JourneyStageToolbar,
} from "../journey-stage-toolbar";

type FlowFlowEditorProps = {
  builderData: FlowBuilderData;
  nodes: Node<PearNodesData>[];
  edges: Edge[];
  nodesToMeasure: NodeTuplesToMeasure | null;
  flowType: FlowType;
  stagePositions: JourneyStagePositions;
  /**
   * @default false
   */
  readOnly?: boolean;
  setNodes: (nextNodes: Node<PearNodesData>[]) => void;
  setEdges: (nextEdges: Edge[]) => void;
  setNodesToMeasure: (nodes: NodeTuplesToMeasure | null) => void;
  onNodesMeasured: (nodesById: Record<string, Node<PearNodesData>>) => void;
  onChangeStagePositions: (nextStages: JourneyStagePositions) => void;
};

export const FlowEditor = ({
  builderData,
  nodes,
  edges,
  nodesToMeasure,
  flowType,
  stagePositions,
  readOnly = false,
  setNodes,
  setEdges,
  setNodesToMeasure,
  onNodesMeasured,
  onChangeStagePositions,
}: FlowFlowEditorProps) => {
  const reactFlowInstance = useReactFlow();
  const [addActionModalNode, setAddActionModalNode] =
    useState<Node<PearNodesData> | null>(null);
  const updateNodeInternals = useUpdateNodeInternals();
  const [grabbedStage, setGrabbedStage] =
    useState<GrabbableJourneyStage | null>(null);
  const isFirstRenderRef = useRef(false);
  const viewport = useViewport();

  // when nodes are dropped, they should be scheduled to render off-screen
  // to be measured & positioned
  const handleDrop: DropHandler<Question> = ({
    item: droppedQuestion,
    relativeOffset,
    containerDimensions,
  }) => {
    const coords = getPositionInViewport(
      relativeOffset,
      containerDimensions,
      viewport
    );
    const newNodes = nodesFromQuestionDrop(droppedQuestion, coords);
    setNodesToMeasure({
      tuples: [newNodes],
      isDropPlacement: true,
      context: FlowNodeRenderContext.TemplateEditor,
    });
  };

  const handleNodesChanged: OnNodesChange = (changes) => {
    const nextNodes = applyNodeChanges<PearNodesData>(changes, nodes);

    // There seems to be a bit of a race condition in ReactFlow's callback timing
    // on first render, where the start node has been placed while the rest of the
    // nodes are being measured, only on chrome based browsers, causing this callback
    // to fire *after* measure and placement, but with only the start node in the initial
    // nodes state. Not making much sense to me :(. This resolves the issue without
    // significant changes and so far as I can see, without apparent bugs -
    // perhaps it can be rethought alongside some future changes?
    if (isFirstRenderRef?.current === false) {
      isFirstRenderRef.current = true;
      return;
    }

    setNodes(nextNodes);
  };

  const handleConnect: OnConnect = (connection) => {
    // if there is a previous edge from this start node, remove
    const withoutPreviousEdge = edges.filter(
      (edge) => edge.source !== connection.source
    );

    // add new edge
    const nextEdges = addEdge({ ...connection }, withoutPreviousEdge);

    setEdges(nextEdges);
  };

  // Swaps output handles between parent stepNode and associated anserNodes
  const handleToggleMultiOutput = (targetId: string) => {
    const [nextNodes, nextEdges] = toggleOutputHandles(targetId, nodes, edges);

    setNodes(nextNodes);
    setEdges(nextEdges);

    // post-render, notify ReactFlow that these nodes need to
    // update their handle state internally
    const nodeIds = nextNodes.map((node) => node.id);
    setTimeout(() => nodeIds.forEach((id) => updateNodeInternals(id)), 1);
  };

  const handleToggleAddActionModal = (nodeId: string) => {
    const node = findNodeById(nodes, nodeId);
    setAddActionModalNode(node);
  };

  const handleAddAction = (
    targetNode: Node<PearNodesData>,
    actionInfo: ActionInfo
  ) => {
    const { ...nextNode } = targetNode;
    nextNode.data.actions = targetNode.data.actions.concat(actionInfo);

    handleUpdateNodeGroup(nextNode);
  };

  const handleDeleteAction = (nodeId: string, deleteActionInfo: ActionInfo) => {
    const { ...nextNode } = findNodeById(nodes, nodeId);
    nextNode.data.actions = nextNode.data.actions.filter(
      (existingActionInfo) => !isEqual(existingActionInfo, deleteActionInfo)
    );

    handleUpdateNodeGroup(nextNode);
  };

  // When a question is updated, if it's used in the graph,
  // find it's nodes and associated edges and update those
  // -- throws away old answer and edge nodes
  // -- creates new edge and answer nodes, copying edge and actions where answer
  //    value remained stable
  const handleUpdateQuestionNodes = (updatedQuestion: Question) => {
    const node = findNodeByQuestionId(nodes, updatedQuestion._id);
    if (!node) return;

    // find ids for node's grouping and edges
    const prevNodeGroup = findStepOrAnswerNodeGroup(node, nodes);
    const prevNodeGroupEdges = findEdgesForNodeGroup(prevNodeGroup, edges);

    // set up values used in transaction
    const prevOutputEdgeIdSet = new Set(
      prevNodeGroupEdges
        .filter((edge) => edge.target !== node.id)
        .map((edge) => edge.id)
    );
    const nextResponseSet = new Set(updatedQuestion.answerOptions);
    const [prevStepNode, ...prevAnswerNodes] = prevNodeGroup;
    const prevAnswerNodeIdSet = new Set(prevAnswerNodes.map((node) => node.id));
    const edgesToPreserveByAnswerValue: Record<string, string> = {};
    const actionsToPreserve: Action[] = [];
    const nextEdges: Edge[] = [];

    // check if step node has an edge to preserve
    // -- step node always keeps its edge, as it's likely semantically stable
    const prevStepEdge = prevNodeGroupEdges.find(
      (edge) => edge.source === prevStepNode.id
    );
    if (prevStepEdge) nextEdges.push(prevStepEdge);

    // check if answer nodes have edges or actions to preserve
    // -- only keep edge/actions if content is unchanged, can't verify semantic stability.
    //  - caveat: ordering may have changed, so create new edges where preserving to acct
    prevAnswerNodes.forEach((node) => {
      // if the response still exists unmodified:
      if (nextResponseSet.has(node.data.answerValue)) {
        // and if there was previously an edge attached:
        const edge = prevNodeGroupEdges.find((edge) => edge.source === node.id);
        if (edge) {
          actionsToPreserve.push({
            response: node.data.answerValue,
            action: node.data.actions.map(actionInfoToActionData),
          });
          edgesToPreserveByAnswerValue[node.data.answerValue] = edge.target;
        }
      }
    });

    // create our new answer nodes with our extracted actions
    const nextAnswerNodes = questionToAnswerNodes(
      updatedQuestion,
      prevStepNode.id,
      actionsToPreserve,
      prevStepNode.data.isMultiOutput
    );

    // create our (preserved) answer edges with our new answer nodes
    nextAnswerNodes.forEach((node) => {
      const preservedTargetId =
        edgesToPreserveByAnswerValue[node.data.answerValue];
      if (preservedTargetId) {
        nextEdges.push(edgeFromNodeIds(node.id, preservedTargetId));
      }
    });

    // apply any updates to our previous stepNode (reusing)
    const nextStepNode = copyFrozen(prevStepNode);
    nextStepNode.data.step.question.dataId = updatedQuestion.dataId;
    nextStepNode.data.step.question.questionTitle =
      updatedQuestion.questionTitle;
    nextStepNode.data.step.question.questionText = updatedQuestion.questionText;

    // Notify if question update causes any edges to be dropped:
    if (
      Object.values(edgesToPreserveByAnswerValue).length !==
      prevNodeGroupEdges.length
    ) {
      toast.success(
        <StepNodeIdentifyingToast
          node={nextStepNode}
          message={"Question outputs affected by Question Change: "}
        />,
        {
          duration: 10000,
        }
      );
      scrollToNode(prevStepNode, reactFlowInstance);
    }

    // finally, filter out our old answer nodes and output edges, and send the new nodes
    // off to be measured
    setNodes(nodes.filter((node) => !prevAnswerNodeIdSet.has(node.id)));
    setEdges(
      edges
        .filter((edge) => !prevOutputEdgeIdSet.has(edge.id))
        .concat(nextEdges)
    );
    setNodesToMeasure({
      tuples: [[nextStepNode, ...nextAnswerNodes]],
      context: FlowNodeRenderContext.TemplateEditor,
    });
  };

  const handleDeleteStepNode = (nodeId: string) => {
    // grab node
    const { ...targetNode } = findNodeById(nodes, nodeId);

    // find ids for node's grouping and edges
    const nodeGroup = findStepOrAnswerNodeGroup(targetNode, nodes);
    const nodeGroupEdges = findEdgesForNodeGroup(nodeGroup, edges);
    const nodeIdSet = new Set(nodeGroup.map((node) => node.id));
    const edgeIdSet = new Set(nodeGroupEdges.map((edge) => edge.id));

    // remove nodes
    const nextNodes = nodes.filter((node) => !nodeIdSet.has(node.id));
    const nextEdges = edges.filter((edge) => !edgeIdSet.has(edge.id));

    setNodes(nextNodes);
    setEdges(nextEdges);
  };

  const handleUpdateNodeGroup = (updatedNode: Node<PearNodesData>) => {
    // gather node grouping
    const nodeGroup = findParentOrChildNodeGroup(updatedNode, nodes);

    // replace updated node in node grouping
    const nodesToMeasure = nodeGroup.map((node) =>
      node.id === updatedNode.id ? updatedNode : node
    ) as OrderedStepNodeTuple;

    // send to measure
    setNodesToMeasure({
      tuples: [nodesToMeasure],
      context: FlowNodeRenderContext.TemplateEditor,
    });
  };

  return (
    <>
      {/* Flow Editor */}
      <div style={{ position: "relative", flexGrow: 1 }}>
        <Droppable onDrop={handleDrop} accept={DragType.Question}>
          <FlowBuilderContext.Provider
            value={{
              builderData,
              readOnly,
              grabbedStage,
              stagePositions,
              nodesSupportActions: flowType !== FlowType.Assessment,
              flowType,
              onDeleteAction: handleDeleteAction,
              onDeleteStepNode: handleDeleteStepNode,
              onToggleMultiOutput: handleToggleMultiOutput,
              onToggleActionModal: handleToggleAddActionModal,
              handleUpdateQuestionNodes: handleUpdateQuestionNodes,
            }}
          >
            {flowType === FlowType.Journey && (
              <JourneyStageToolbar
                viewport={viewport}
                stagePositions={stagePositions}
                onChangeStagePositions={onChangeStagePositions}
                onGrabStagePosition={setGrabbedStage}
                onDropStagePosition={() => setGrabbedStage(null)}
              />
            )}

            <ReactFlow
              nodes={nodes}
              edges={edges}
              nodeTypes={templateEditorNodeTypes}
              onConnect={handleConnect}
              onNodesChange={handleNodesChanged}
            >
              <Background />
              <Controls showInteractive={false} />
            </ReactFlow>

            {!!nodesToMeasure && (
              <NodeMeasuringContainer
                nodesToMeasure={nodesToMeasure}
                onNodesMeasured={onNodesMeasured}
              />
            )}

            <AddActionModal
              isOpen={!!addActionModalNode}
              node={addActionModalNode}
              onRequestClose={() => setAddActionModalNode(null)}
              onCreateAction={handleAddAction}
            />
          </FlowBuilderContext.Provider>
        </Droppable>
      </div>

      {!readOnly && (
        <div style={{ flexGrow: 0, flexShrink: 0 }}>
          <QuestionListPane
            onUpdateQuestion={handleUpdateQuestionNodes}
            flowType={flowType}
          />
        </div>
      )}
    </>
  );
};

// Translates a dropped question's coordinates to coordinates in ReactFlow viewport,
// taking viewport zoom and pan into account
export const getPositionInViewport = (
  offset: XYCoord,
  containerDimensions: Dimensions,
  viewport: Viewport
): XYCoord => {
  const xRatio = offset.x / containerDimensions.width;
  const yRatio = offset.y / containerDimensions.height;

  const viewportWidth = containerDimensions.width / viewport.zoom;
  const viewportHeight = containerDimensions.height / viewport.zoom;

  const x = viewportWidth * xRatio - viewport.x / viewport.zoom;
  const y = viewportHeight * yRatio - viewport.y / viewport.zoom;

  return { x, y };
};

// Swaps output handles between parent stepNode and associated anserNodes
const toggleOutputHandles = (
  targetId: string,
  nodes: Node<PearNodesData>[],
  edges: Edge[]
): [nodes: Node<PearNodesData>[], edges: Edge[]] => {
  const {
    data: { isMultiOutput },
  } = nodes.find((node) => node.id === targetId) as Node<StepNodeData>;

  // filter out existing edges for this node and/or nested answers
  const nextEdges = edges.filter((edge) => !edge.source.startsWith(targetId));

  // toggle state for node and any nested answers to update which have handles
  const nextNodes = nodes.map((node) => {
    if (isStepFlowNode(node) && node.id === targetId) {
      const next = { ...node };
      next.data = { ...node.data };
      next.data.isMultiOutput = !isMultiOutput;
      return next;
    }

    if (isAnswerFlowNode(node) && node.parentNode === targetId) {
      const next = { ...node };
      next.data = { ...node.data };
      next.data.isOutputNode = !isMultiOutput;
      return next;
    }

    return node;
  });

  return [nextNodes, nextEdges];
};
